import Colors from 'builder/styles/colors'
import { Container, IconContainer, Text } from './styles'

export enum BadgeEnum {
  green = 'green',
  orange = 'orange',
  indigo = 'indigo',
}
export interface Props {
  icon?: React.ReactNode
  text: string
  type: BadgeEnum
}

interface Value {
  textColor: string
}

const colors: Record<BadgeEnum, Value> = {
  [BadgeEnum.green]: {
    textColor: Colors.Green50,
  },
  [BadgeEnum.orange]: {
    textColor: Colors.Orange50,
  },
  [BadgeEnum.indigo]: {
    textColor: Colors.Indigo50,
  },
}

export const Badge = ({ icon, text, type }: Props) => {
  const color = colors[type]
  if (!color) {
    return null
  }
  return (
    <Container>
      <IconContainer $color={color.textColor}>{icon}</IconContainer>

      <Text $color={color.textColor}>{text}</Text>
    </Container>
  )
}
