import React, { useEffect, useRef, useState } from 'react'
import ModalOverlay from 'builder/components/ModalOverlay'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { CloseIcon, CloseIconContainer, Subtitle, Title, TitleContainer, Wrapper } from './styles'

type ModalProps = {
  onClose: () => void
  title?: string
  subtitle?: string
  width?: number
  className?: string
  padding?: string
}

export const DialogModalV2 = ({
  children,
  title,
  subtitle,
  onClose,
  width,
  className,
  padding,
}: React.PropsWithChildren<ModalProps>) => {
  const { isPhone } = useMediaQueries()
  const modalRef = useRef<HTMLDivElement>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useClickOutside(modalRef, () => mounted && onClose())

  return (
    <ModalOverlay overlayFadeDuration={350} contentSlideDuration={350} onClose={onClose}>
      <Wrapper
        $width={width || isPhone ? 343 : 544}
        className={className}
        $padding={padding}
        ref={modalRef}
      >
        <TitleContainer>
          <Title>{title}</Title>
          <CloseIconContainer onClick={onClose}>
            <CloseIcon />
          </CloseIconContainer>
        </TitleContainer>

        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </Wrapper>
    </ModalOverlay>
  )
}
