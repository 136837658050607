import { Settings } from './Settings/Settings'
import { ProfileAvatar } from './ProfileAvatar/ProfileAvatar'

import { Divider, Container } from './styles'
import { CareerProfile } from './CareerProfile/CareerProfile'

export type Props = {
  closeMenu: () => void
}

export const ProfileMobile = ({ closeMenu }: Props) => {
  return (
    <Container>
      <ProfileAvatar />
      <CareerProfile />
      <Divider />
      <Settings closeMenu={closeMenu} />
    </Container>
  )
}
