import type { Optional } from '@rio/types'
import { FetchStatuses, RENDERERS } from 'builder/modules/constants'
import { ResumeReviewScenarios } from 'builder/modules/resumeReview'

export enum ConfigScopesEnum {
  /** Frontend application essentials (country locale, service keys, logos) */
  'country' = 'country',
  /** Private area config (feature flags, links, etc) */
  'app' = 'app',
  /** Login and Sign up screens settings */
  'signUp' = 'sign-up',
}

export enum SuperAppPromotionType {
  /** For users who registered after start super app promotion feature */
  new = 'new',
  /** For users who registered before start super app promotion feature */
  old = 'old',
}

type WidgetFeatures = {
  coverLetter: boolean
  shareLinkStatistics: boolean
}

type LinksValueType = string | null
export type FeaturesValueType = boolean | string | null

export interface CountryConfig {
  returnButton?: {
    enabled: boolean
    url: string
    icon: string
    text: string
  }
  locale: string
  logo: LogoType
  freeBadgeSlug: string
  jobAlertsLimit: number
  paymentMethods: Array<{ title: string; iconUrl: string }>
}

export interface SignUpConfig {
  features: {
    superApp: boolean
    isWidget: boolean
    interviewSchool: boolean
  }
  links: {
    disclaimer: string
    tos: string
    privacyPolicy: string
    topResumePrivacyPolicy: string | null
    topResumeTos: string | null
    [name: string]: LinksValueType
  }
  socialProfile: Optional<string>
}

enum RdPromoPlanEnum {
  flat = 'flat',
  regular = 'regular',
}

type RdPromoType = {
  rdPromoPlan: RdPromoPlanEnum | Record<string, unknown>
}

type Promoted = {
  billingItems: string[]
}

export enum AutoApplyUpsellModal {
  A = 'test_a',
  B = 'test_b',
  C = 'test_c',
}

export interface AppConfig {
  features: {
    superApp: boolean
    jobSearch: boolean
    jobTracking: boolean
    careerPath: boolean
    salaryAnalyzer: boolean
    contentCard: boolean
    renderer: RENDERERS
    fallbackRenderer: RENDERERS
    resumeReviewScenario: ResumeReviewScenarios | null
    interviewSchool: boolean
    cityCountryMainDetails: boolean
    tiptapEditor: boolean
    superAppPromotionType: SuperAppPromotionType | false
    isWidget: boolean
    widgetFeatures: WidgetFeatures
    resumeOptimizerV1: boolean | string
    aiCoverLetters: boolean
    aiProfileSummary: boolean
    resumeOptimizer: boolean
    aiResumeDraft: boolean
    startWithExamples: boolean
    plansPageAbandonment: boolean
    uploadResume: boolean
    inBuilderAbandonment: boolean
    subCancellationV4: boolean
    international: boolean
    geoipCode: string
    newStartScreen: string | boolean
    downloadButtonLayout: string
    shareResume: boolean
    careerProfile: boolean
    jobHelper: boolean
    coaching: boolean
    careerAdvice: boolean
    careerCoaching: boolean
    careerExplorer: boolean
    careerPlans: boolean
    jobSearchPlan: boolean
    virtualHiringEvents: boolean
    resumeDistribution: boolean
    dashboardV2: boolean
    dashboardV3: boolean
    welcomeModal: boolean
    dashboardNavigationTabs: boolean
    resumeReview: boolean
    professionalHeadshot: boolean
    jobTrackerChromeExtension: boolean
    autoApply: boolean
    autoApplyFeatureFlag: boolean
    rdPromo: RdPromoType // TODO: create a type for this later
    engagementModal: 'control' | 'byron' | 'hitesh' | 'nath' | boolean
    stayInTouchOffer: boolean
    socialProofSubCancellation: boolean
    autoApplyInSubCancellation: boolean
    cvOnLossAversionScreen: boolean
    engagementModalV2: 'nath' | 'byron' | 'hitesh' | 'nath_colourful'
    resumeWriting: boolean
    autoApplyBubbles: boolean
    careerProfileShortVersion: boolean
    jobAlerts: {
      enabled: boolean
      group: 'control' | 'control_2' | 'daily' | 'weekly'
    }
    builderAutotailoring: boolean
    accountContactSupport: boolean
    visibleDragAndDrop: 'control' | 'test'
    discountPercentage: number
    filterOnTheTemplatesPage: boolean
    engagementModalV3: 'a' | 'b' | 'c' | 'd'
    autoAutoApplyNoFreeCredits: AutoApplyUpsellModal
  }

  links: {
    help: string
    [name: string]: LinksValueType
  }

  promoted: Promoted
}

export type AppStoreConfigs = {
  [ConfigScopesEnum.country]: CountryConfig | null
  [ConfigScopesEnum.signUp]: SignUpConfig | null
  [ConfigScopesEnum.app]: AppConfig | null
}
export type AppStoreFetchStatuses = {
  [ConfigScopesEnum.country]: FetchStatuses
  [ConfigScopesEnum.signUp]: FetchStatuses
  [ConfigScopesEnum.app]: FetchStatuses
}

type ValueOf<T> = T[keyof T]
export type ConfigTypes = NonNullable<ValueOf<AppStoreConfigs>>

type LogoType = {
  default: string
  white: string
}

export interface AppStore {
  configs: AppStoreConfigs
  fetchStatuses: AppStoreFetchStatuses
  isOnline: boolean
}
