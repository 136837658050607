import { Outlet } from 'react-router-dom'
import { useConfig } from 'builder/hooks/useConfig'
import { Navbar } from 'builder/components/Navbar'
import { Navigation } from 'builder/components/Navigation'
import { SplashScreen } from 'builder/components/SplashScreen'
import { isIFrame } from 'builder/utils/isIFrame'
import { Container, Content } from './styles'

export const DashboardLayout = () => {
  const config = useConfig()
  const hideNav = config?.features.isWidget && isIFrame()

  const showNavigationBanner = () => {
    if (config?.features.dashboardV3 && !config?.features.dashboardV2) return <></>
    if (config?.features.superApp || config?.features.international || config?.features.dashboardV2)
      return <Navigation />
    return <Navbar />
  }

  return (
    <Container>
      {!hideNav && showNavigationBanner()}
      <Content>
        <Outlet />
      </Content>

      {!hideNav && config?.features.superApp && <SplashScreen />}
    </Container>
  )
}
