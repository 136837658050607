import styled, { css } from 'styled-components'
import { TransitionStatus } from 'react-transition-group'
import { Typography } from 'builder/styles/typography'
import { Shadows } from 'builder/styles/shadows'
import { Colors } from 'builder/styles/colors'
import { Media } from 'builder/styles/media'
import { Icon24 } from 'builder/components/Icon'
import Sizes from 'builder/styles/sizes'

export const Button = styled.button<{
  $isOpen?: boolean
  $isDisabled: boolean
  $isPlaceholder: boolean
}>`
  ${Typography.Body};
  display: flex;
  align-items: center;
  gap: ${Sizes['2XS']};
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 12px 44px 12px 16px;
  border: none;
  border-radius: 8px;
  background: ${Colors.Neutral10};
  color: ${Colors.Neutral90};
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  outline: none;
  cursor: pointer;

  ${Media.Phone`
    height: 44px;
  `};

  ${props =>
    props.$isDisabled &&
    css`
      background-color: ${Colors.Neutral20};
      color: ${Colors.Neutral60};
      cursor: not-allowed;
    `};

  ${props =>
    props.$isPlaceholder &&
    css`
      color: ${Colors.Neutral40};
    `};

  ${props =>
    props.$isOpen &&
    css`
      ${Shadows.light.low};
      transition: box-shadow 0.15s ease-out;
      border-radius: 8px 8px 0 0;
    `};
`

export const Icon = styled(Icon24.Chevron)<{
  $isOpen?: boolean
  $isDisabled?: boolean
  $useWhite: boolean
}>`
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
  color: ${props => (props.$isDisabled ? Colors.Neutral40 : Colors.Blue50)};
  transform: rotate(${props => (props.$isOpen ? -90 : 90)}deg);
  transition: transform 0.15s ease-out;
  ${props => props.$useWhite && 'color: white;'}
`

export const Bar = styled.div<{
  state: TransitionStatus
  isOpen?: boolean
  hasError?: boolean
  hasWarning?: boolean
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: ${props => (props.isOpen ? 0 : '0 0 2px 2px')};
  pointer-events: none;

  background-color: ${props => {
    if (props.hasError) return Colors.Red50
    if (props.hasWarning) return Colors.Amber40
    return Colors.Blue50
  }};

  ${props =>
    ({
      entering: 'opacity: 1; transform: scaleX(1); transition: transform 0.1s ease;',
      entered: 'opacity: 1; transform: scaleX(1)',
      exiting: 'opacity: 0; transform: scaleX(1); transition: opacity 0.1s ease;',
      exited: 'opacity: 0; transform: scaleX(0.5)',
      unmounted: null,
    }[props.state])};
`
