import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Icon24 from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Sizes from 'builder/styles/sizes'
export const Popup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12000;
  transition-property: opacity;
  transition-timing-function: ease-out;
  background: rgba(15, 20, 31, 0.8);
`

export const PopupContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 12px;
  flex-direction: column;
`

export const Wrapper = styled.div<{ $width: number; $padding?: string }>`
  background-color: ${Colors.White};
  width: ${({ $width }) => $width}px;
  border-radius: 12px;
  ${Media.Phone`
    max-width: none;
    height: 100%;
    padding: 20px;

  `};

  padding: ${Sizes.L};
`
export const CloseIconContainer = styled.div`
  height: 28px;
  width: 28px;
  flex: 0 0 auto;
  background-color: ${Colors.Neutral10};
  color: ${Colors.Neutral50};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Colors.Blue50};
    background-color: ${Colors.Blue10};
  }

  ${Media.Phone`
    height: 24px;
    width: 24px;
  `}
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Sizes.XS};

  ${Media.Phone`
    margin-bottom: ${Sizes['2XS']};

  `}
`

export const Title = styled.div`
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};
`

export const CloseIcon = styled(Icon24.Close)`
  height: 20px;
  width: 20px;
`
export const Subtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`
