import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'builder/hooks/useI18n'
import {
  ActionButtonsLeft,
  CloseIcon,
  DashboardButton,
  MenuButton,
  MiddleContainer,
  Wrapper,
} from './styles'
import { Menu } from './Menu/Menu'

interface Props {
  onCloseClick: () => void
  onDashboardClick: () => void
  children?: React.ReactNode
  onHover?: (hover: boolean) => void
}

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2`

export const NavbarWithMenu = ({
  onCloseClick,
  onDashboardClick,
  children,
  onHover,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const navigate = useNavigate()
  const { i18n } = useI18n()
  const handleMouseOver = () => onHover && onHover(true)
  const handleMouseOut = () => onHover && onHover(false)

  return (
    <>
      <Wrapper
        ref={ref}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <ActionButtonsLeft>
          <MenuButton onClick={() => setShowSideMenu(prevValue => !prevValue)} />
          <DashboardButton onClick={onDashboardClick} >
            {i18n.t(`${TRANSLATION_PREFIX}.dashboard`)}
          </DashboardButton>
        </ActionButtonsLeft>

        <MiddleContainer>{children}</MiddleContainer>
        <CloseIcon onClick={onCloseClick} />
      </Wrapper>
      {showSideMenu && (
        <Menu
          onClose={() => setShowSideMenu(false)}
          onClick={(path: string) => {
            navigate(path)
          }}
        />
      )}
    </>
  )
}
