import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { actions } from 'builder/modules/resumeEditor'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import {
  ResumeUploadStep,
  PrefillResumeEvents,
  ResumeValidationStatus,
} from 'builder/modules/constants'
import { useI18n } from 'builder/hooks/useI18n'
import UploadResumeIllustration from 'images/builder/fill-resume/upload-illustration.png'
import { RESUME_MAX_SIZE, IMAGE_FILE_REGEX } from '../constants'
import { UploadingTitle, UploadIllustration, UploadIllustrationWrapper } from '../styles'

const FileUploadProgress = () => {
  const { i18n } = useI18n()
  const selectedResume = useAppSelector(
    state => state.resumeEditor.prefillResumeSection.selectedResume,
  )

  const dispatch = useDispatch()

  const validateResume = useCallback(() => {
    if (selectedResume) {
      if (selectedResume.size > RESUME_MAX_SIZE) {
        dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.failed))
        dispatch(
          actions.setResumeValidationError({
            error: i18n.t('builder.upload_resume.file_size_error'),
            errorSource: ResumeUploadStep.validation,
          }),
        )
      } else if (IMAGE_FILE_REGEX.test(selectedResume.type)) {
        dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.failed))
        dispatch(
          actions.setResumeValidationError({
            error: i18n.t('builder.upload_resume.unsupported_file_type_error'),
            errorSource: ResumeUploadStep.validation,
          }),
        )
      } else {
        trackInternalEvent(PrefillResumeEvents.completeResumeUpload)
        dispatch(actions.setResumeValidationStatus(ResumeValidationStatus.validated))
        // resets validation error if any
        dispatch(
          actions.setResumeValidationError({
            error: null,
            errorSource: null,
          }),
        )
      }
    }
  }, [dispatch, i18n, selectedResume])

  useEffect(() => {
    setTimeout(() => {
      validateResume()
    }, 2000) // delay so that upload animation is visible
  }, [validateResume])

  return (
    <>
      <UploadingTitle>{i18n.t('builder.upload_resume.uploading')}</UploadingTitle>
      <UploadIllustrationWrapper>
        <UploadIllustration src={UploadResumeIllustration} />
      </UploadIllustrationWrapper>
    </>
  )
}

export default memo(FileUploadProgress)
