export enum SliderArrowButtonType {
  right = 'right',
  left = 'left',
}

export enum SliderArrowButtonThemeEnum {
  gray = 'gray',
  blue = 'blue',
}

export type SliderArrowButtonSize = 48 | 36
