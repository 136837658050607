import { useMutation } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { baseClient } from 'builder/modules/apiClient'
export interface CompanyName {
  company: string
  link: string
  location: string
  logo: string
}

export const useCompanyNames = () => {
  const abortControllerRef = useRef(new AbortController())
  useEffect(() => {
    return () => abortControllerRef.current.abort()
  }, [])

  return useMutation(['companyNames'], async (name: string, limit = 5): Promise<CompanyName[]> => {
    const res = await baseClient.get('app/suggest/company', {
      params: {
        query: name,
        limit,
      },
      signal: abortControllerRef.current.signal,
    })
    return res.data.suggestions
  })
}
