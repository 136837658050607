import { useI18n } from 'builder/hooks/useI18n'

import { DialogModalV2 } from 'builder/components/DialogModal'
import { Container } from './styles'
import Button, { ButtonTheme } from 'builder/components/Button'

const TRANSLATION = 'builder.interview_prep_view.leaving_modal'

export const ConfirmationLeaving = ({
  onChoiceClick,
}: {
  onChoiceClick: (choice: boolean) => void
}) => {
  const { i18n } = useI18n()

  return (
    <DialogModalV2
      onClose={() => onChoiceClick(false)}
      title={i18n.t(`${TRANSLATION}.title`)}
      subtitle={i18n.t(`${TRANSLATION}.message`)}
    >
      <Container>
        <Button theme={ButtonTheme.ghost} onClick={() => onChoiceClick(true)}>
          {i18n.t(`${TRANSLATION}.leave_button`)}
        </Button>
        <Button onClick={() => onChoiceClick(false)}>{i18n.t(`${TRANSLATION}.stay_button`)}</Button>
      </Container>
    </DialogModalV2>
  )
}
