import { useRef, useEffect } from 'react'

interface Params {
  enable: boolean
  rootRef?: React.MutableRefObject<HTMLDivElement | null>
  callback: IntersectionObserverCallback
  options?: IntersectionObserverInit
}

export function useIntersectionObserver({ enable, rootRef, callback, options }: Params) {
  const targetRef = useRef<HTMLDivElement | null>(null)
  const internalCallback = useRef<IntersectionObserverCallback>()
  internalCallback.current = callback

  useEffect(() => {
    if (!enable || !internalCallback.current) {
      return
    }
    const observer = new IntersectionObserver(internalCallback.current, {
      root: rootRef?.current,
      threshold: 1,
      ...options,
    })
    const target = targetRef.current
    if (target) {
      observer.observe(target)
    }

    return () => {
      if (target) {
        observer.unobserve(target)
      }
    }
  }, [enable, targetRef, internalCallback, options])

  return { targetRef }
}
