import { useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { ArrowUp, ArrowDown, Button, Container, Icon, Title, Text } from './styles'
import WandIcon from './assets/Wand.svg'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.tip`
interface Props {
  companyName?: string
}
export const Tip = ({ companyName }: Props) => {
  const { i18n } = useI18n()
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <Button>
        <Icon src={WandIcon} />
        <Title dangerouslySetInnerHTML={{ __html: i18n.t(`${TRANSLATION_PREFIX}.title`) }} />
        <div
          onClick={() => {
            setOpen(!open)

            if (!open) {
              trackInternalEvent('click_tip', {
                label: 'interview_prep',
              })
            }
          }}
        >
          {open ? <ArrowUp /> : <ArrowDown />}
        </div>
      </Button>

      {open && (
        <Text
          dangerouslySetInnerHTML={{
            __html: i18n.t(`${TRANSLATION_PREFIX}.text`, {
              companyName: companyName || 'your company',
            }),
          }}
        />
      )}
    </Container>
  )
}
