import { useEffect, useRef, useState } from 'react'
import Spinner from 'builder/components/Spinner'
import ErrorLogger from 'builder/services/ErrorLogger'
import { InterviewAnswer } from 'builder/modules/interview/types'
import Icon24 from 'builder/components/Icon'
import { Mute } from 'builder/components/Icon/20x20'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import {
  Container,
  ControlContainer,
  PauseIcon,
  PlayPauseContainer,
  FooterContainer,
  FullTimeline,
  PlayTimeline,
  TimelineContainer,
  Time,
  CustomVideo,
  PlayIcon,
  TimelineTimeContainer,
  MuteButton,
  SpinnerContainer,
} from './styles'

interface Props {
  audioUrl?: string
  videoUrl?: string
  answer?: InterviewAnswer
  answerDurationInSeconds?: number
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const secondsLeft = Math.floor(seconds % 60)
  return `${minutes.toString()}:${secondsLeft.toString().padStart(2, '0')}`
}

type PlayingStatus = 'loading' | 'buffering' | 'stopped' | 'playing' | 'error'

export const VideoPlayer = ({ audioUrl, videoUrl, answerDurationInSeconds = 0 }: Props) => {
  const [videoStatus, setVideoStatus] = useState<PlayingStatus>('loading')

  const [progress, setProgress] = useState(0)
  const [muted, setMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const timelineRef = useRef<HTMLDivElement>(null)

  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    async function forceVideoToBeLoaded() {
      if (!videoRef.current) return
      if (audioUrl || videoUrl) {
        try {
          setVideoStatus('loading')
          videoRef.current.src = videoUrl || audioUrl || ''
          // fix bug for ios 16, force ios to download
          await videoRef?.current?.play()
          videoRef?.current?.pause()
        } catch (error) {
          ErrorLogger.log(error, { tag: 'interview_feedback_play' })
        }
      }
    }
    forceVideoToBeLoaded()
  }, [videoUrl, audioUrl])

  const startPlaying = async () => {
    try {
      await videoRef?.current?.play()
      setVideoStatus('playing')
    } catch (error) {
      ErrorLogger.log(error, { tag: 'interview_feedback_play' })
    }
  }
  const stopPlaying = () => {
    videoRef?.current?.pause()
    setVideoStatus('stopped')
  }
  const togglePlaying = () => {
    if (videoStatus === 'loading') return
    if (videoStatus === 'stopped') {
      startPlaying()
    } else {
      stopPlaying()
    }
  }

  const resetVideo = () => {
    stopPlaying()
    setProgress(0)
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
  }

  const handleNextVideo = () => {
    resetVideo()
  }

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef?.current?.currentTime || 0)
    setProgress(() => {
      if (videoRef.current && videoRef.current.currentTime > 0) {
        const percentage = (videoRef.current.currentTime / answerDurationInSeconds) * 100
        if (percentage >= 100) {
          return 100
        }
        return percentage
      }
      return 0
    })
  }

  const handleTimelineClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!timelineRef.current || !videoRef.current) {
      return
    }
    const timelineWidth = timelineRef.current.offsetWidth
    const clickPosition = event.nativeEvent.offsetX
    const seekTime = (clickPosition / timelineWidth) * answerDurationInSeconds
    videoRef.current.currentTime = seekTime
  }

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    videoRef.current.muted = muted
  }, [muted])

  const renderElementVideo = () => {
    if (videoStatus === 'error') {
      // TODO implement proper error state waiting the design
      return <Container>Error state is not implemented</Container>
    }

    if (videoStatus === 'loading') {
      return (
        <Container>
          <Skeleton width={300} height={256} />
        </Container>
      )
    }
    return (
      <>
        {videoStatus !== 'buffering' && (
          <MuteButton
            onClick={() => {
              setMuted(muted => !muted)
            }}
          >
            {videoRef.current?.muted ? <Mute /> : <Icon24.Sound />}
          </MuteButton>
        )}
        {videoStatus === 'buffering' && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
        <FooterContainer>
          <ControlContainer onClick={togglePlaying}>
            <PlayPauseContainer>
              {videoStatus === 'buffering' || videoStatus === 'playing' ? (
                <PauseIcon />
              ) : (
                <PlayIcon />
              )}
            </PlayPauseContainer>
          </ControlContainer>
          <TimelineTimeContainer>
            <TimelineContainer>
              <FullTimeline ref={timelineRef} onClick={handleTimelineClick}>
                <PlayTimeline $progress={progress} />
              </FullTimeline>
            </TimelineContainer>
            <Time>
              {formatTime(currentTime)} / {formatTime(answerDurationInSeconds)}
            </Time>
          </TimelineTimeContainer>
        </FooterContainer>
      </>
    )
  }
  return (
    <Container>
      <CustomVideo
        ref={videoRef}
        playsInline
        disableRemotePlayback
        onCanPlay={() => setVideoStatus('stopped')}
        onWaiting={() => setVideoStatus('buffering')}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleNextVideo}
        onPause={() =>
          setVideoStatus((prevStatus: PlayingStatus) =>
            prevStatus === 'error' ? 'error' : 'stopped',
          )
        }
        onError={() => setVideoStatus('error')}
        onLoadedMetadata={() => setVideoStatus('stopped')}
        style={{ display: videoStatus === 'loading' ? 'none' : 'block' }}
      />
      {renderElementVideo()}
    </Container>
  )
}
