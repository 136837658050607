/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'
import type { DebouncedFunc } from 'lodash'

export const useDebouncedCallback = <T extends (...args: any) => any>(
  callback: T,
  interval: number,
): DebouncedFunc<T> => {
  const callbackRef = useRef<T>(callback)
  callbackRef.current = callback

  return useMemo(() => {
    const wrapper = (...args: any) => callbackRef.current(...args)
    return debounce(wrapper, interval)
  }, [interval])
}
