import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  display: inline-flex;
  gap: 4px;

  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
  ${Typography.Body}
  ${FontWeights.Regular}
`
