import { Listbox } from '@headlessui/react'
import { SelectControlProps as Props, SelectValue } from '../../types'
import { DesktopOptions } from '../DesktopOptions'
import { SelectButton } from '../SelectButton'
import { List } from '../DesktopOptions/styles'

export const DesktopControl = <V extends SelectValue>({
  isDisabled,
  value,
  options,
  onChange,
  icon,
  optionsListClassname,
  buttonOnClick,
  ...rest
}: Props<V>) => {
  return (
    <Listbox disabled={isDisabled} value={value} onChange={onChange} as={List}>
      {({ open: isOpen }) => (
        <div style={{ borderRadius: '8px 8px 0 0' }}>
          <Listbox.Button
            as={SelectButton}
            isOpen={isOpen}
            isDisabled={isDisabled}
            useWhiteArrow={optionsListClassname === 'templates-filter'}
            onClick={buttonOnClick}
            icon={icon}
            {...rest}
          />
          <DesktopOptions options={options} optionsListClassname={optionsListClassname} />
        </div>
      )}
    </Listbox>
  )
}
