import { Fragment, useCallback, useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { Icon20 } from 'builder/components/Icon'
import { ButtonSize } from 'builder/components/Button'
import {
  actions as resumeEditorActions,
  PrefillProviders,
  PrefillStatuses,
  selectors as resumeEditorSelectors,
} from 'builder/modules/resumeEditor'

import {
  PrefillPopoverContainer,
  PrefillPopoverMobileOverlay,
  PrefillPopupActions,
  PrefillPopupCloseButton,
  PrefillPopupHead,
  PrefillPopupHeaderContainer,
  PrefillPopupHeadMobile,
  PrefillPopupList,
  PrefillPopupListItem,
  PrefillPopupListItemIcon,
  PrefillPopupLogo,
  PrefillPopupLogoContainer,
  PrefillPopupLogoIcon,
  PrefillPopupMobileSpinner,
  PrefillPopupProceedButton,
  PrefillPopupStatusIconContainer,
  PrefillPopupStatusSuccessIcon,
  PrefillPopupSubheadMobile,
  PrefillPopupText,
} from './styles'

interface PrefillPopoverProcessingProps {
  documentId: number
  provider: PrefillProviders
  onClose: () => void
}

const PrefillPopoverProcessing = ({ documentId, provider }: PrefillPopoverProcessingProps) => {
  const { i18n } = useI18n()
  const { isLaptop } = useMediaQueries()

  // track internal event when popup showed
  useEffectOnMount(() =>
    trackInternalEvent(`see_${provider}_prefill_status_popup`, { resume_id: documentId }),
  )

  return (
    <Fragment>
      <PrefillPopupHeaderContainer>
        {isLaptop ? (
          <Fragment>
            <PrefillPopupMobileSpinner />
            <PrefillPopupHeadMobile>
              {i18n.t(
                `builder.resume_editor.prefill.modal.${provider}.processing_head_mobile_text`,
              )}
            </PrefillPopupHeadMobile>
            {provider === PrefillProviders.topresume && (
              <PrefillPopupSubheadMobile>
                <PrefillPopupLogo service={provider} />
                <PrefillPopupHeadMobile>
                  {i18n.t(
                    `builder.resume_editor.prefill.modal.${provider}.processing_subhead_mobile`,
                  )}
                </PrefillPopupHeadMobile>
              </PrefillPopupSubheadMobile>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <PrefillPopupLogo service={provider} />
            <PrefillPopupHead>
              {i18n.t(`builder.resume_editor.prefill.modal.${provider}.processing_head`)}
            </PrefillPopupHead>
          </Fragment>
        )}
      </PrefillPopupHeaderContainer>
      <PrefillPopupList>
        <PrefillPopupListItem>
          <PrefillPopupListItemIcon />
          {i18n.t(`builder.resume_editor.prefill.modal.${provider}.processing_list_item_1`)}
        </PrefillPopupListItem>
        <PrefillPopupListItem>
          <PrefillPopupListItemIcon />
          {i18n.t(`builder.resume_editor.prefill.modal.${provider}.processing_list_item_2`)}
        </PrefillPopupListItem>
        {provider === PrefillProviders.topresume && (
          <PrefillPopupListItem>
            <PrefillPopupListItemIcon />
            {i18n.t(`builder.resume_editor.prefill.modal.${provider}.processing_list_item_3`)}
          </PrefillPopupListItem>
        )}
      </PrefillPopupList>
    </Fragment>
  )
}

interface Props {
  status?: PrefillStatuses
  provider: PrefillProviders
  onClose: () => void
}

type PrefillPopoverSuccess = Props & {
  onAutoClose: () => void
}

const PrefillPopoverSuccess = ({
  provider,
  status,
  onClose,
  onAutoClose,
}: PrefillPopoverSuccess) => {
  const { i18n } = useI18n()
  const { isLaptop } = useMediaQueries()

  useEffectOnMount(onAutoClose)

  return (
    <Fragment>
      {isLaptop && <PrefillPopupCloseButton onClick={onClose} />}
      <PrefillPopupHeaderContainer center>
        {!isLaptop ? (
          <PrefillPopupLogoContainer>
            <PrefillPopupLogo service={provider} />
            <PrefillPopupLogoIcon>
              <Icon20.Tick />
            </PrefillPopupLogoIcon>
          </PrefillPopupLogoContainer>
        ) : (
          <Fragment>
            <PrefillPopupStatusIconContainer>
              <PrefillPopupStatusSuccessIcon />
            </PrefillPopupStatusIconContainer>
          </Fragment>
        )}
        <PrefillPopupHead center>
          {status === PrefillStatuses.merged
            ? i18n.t(`builder.resume_editor.prefill.modal.${provider}.success_merged_head`)
            : i18n.t(`builder.resume_editor.prefill.modal.${provider}.success_head`)}
        </PrefillPopupHead>
      </PrefillPopupHeaderContainer>
    </Fragment>
  )
}

export const PrefillPopoverFailure = ({ provider, status, onClose }: Props) => {
  const { i18n } = useI18n()
  const { isLaptop } = useMediaQueries()

  const isMergeFailed =
    status === PrefillStatuses.mergeFailed || status === PrefillStatuses.rejectFailed

  return (
    <Fragment>
      <PrefillPopupCloseButton onClick={onClose} />
      <PrefillPopupHeaderContainer center>
        <PrefillPopupLogoContainer>
          <PrefillPopupLogo service={provider} />
          <PrefillPopupLogoIcon failure>
            <Icon20.Close />
          </PrefillPopupLogoIcon>
        </PrefillPopupLogoContainer>
        <PrefillPopupHead center={isLaptop}>
          {status === PrefillStatuses.failed &&
            i18n.t(`builder.resume_editor.prefill.modal.${provider}.failed_head`)}
          {isMergeFailed &&
            i18n.t(`builder.resume_editor.prefill.modal.${provider}.failed_merge_head`)}
        </PrefillPopupHead>
      </PrefillPopupHeaderContainer>
      <PrefillPopupText center={isLaptop}>
        {status === PrefillStatuses.failed &&
          i18n.t(`builder.resume_editor.prefill.modal.${provider}.failed_text`)}
        {isMergeFailed &&
          i18n.t(`builder.resume_editor.prefill.modal.${provider}.failed_merge_text`)}
      </PrefillPopupText>
      <PrefillPopupActions>
        <PrefillPopupProceedButton
          size={isLaptop ? ButtonSize.default : ButtonSize.small}
          onClick={onClose}
        >
          {i18n.t(`builder.resume_editor.prefill.modal.${provider}.failed_proceed`)}
        </PrefillPopupProceedButton>
      </PrefillPopupActions>
    </Fragment>
  )
}

const HIDDEN_STATUSES = [PrefillStatuses.skipped, PrefillStatuses.rejected]

const SKIPPED_OR_DONE_STATUSES = [
  PrefillStatuses.notAsked,
  PrefillStatuses.done,
  PrefillStatuses.skipped,
  PrefillStatuses.merged,
]

interface PrefillPopoverProps {
  documentId: number
}

export const PrefillPopover = ({ documentId }: PrefillPopoverProps) => {
  const dispatch = useDispatch()
  const { isLaptop } = useMediaQueries()
  const { status, provider } = useTypedSelector(resumeEditorSelectors.prefill)
  const [isSkippedOrDone, setSkippedOrDone] = useState(SKIPPED_OR_DONE_STATUSES.includes(status))
  const [isVisible, setIsVisible] = useState(!HIDDEN_STATUSES.includes(status))
  const isResumeEditor = useMatch('/resumes/:id/edit')
  const isCoverLetterEditor = useMatch('/cover-letters/:id/edit')
  const isEditor = isResumeEditor || isCoverLetterEditor

  // show different popup for editor and other pages if resume merged automatically
  const isMergedAutoInEditor = status === PrefillStatuses.mergedAuto && isEditor

  const isPrefillFailed =
    status === PrefillStatuses.failed ||
    status === PrefillStatuses.mergeFailed ||
    status === PrefillStatuses.rejectFailed

  const isPrefillDone =
    status === PrefillStatuses.done ||
    status === PrefillStatuses.merged ||
    status === PrefillStatuses.rejected ||
    isMergedAutoInEditor

  const handleClose = useCallback(() => {
    // We need to close all popups if we closed popup about fail
    // or it was successfully auto-merged and close
    if (isPrefillFailed || isMergedAutoInEditor) {
      dispatch(resumeEditorActions.setPrefillStatus(PrefillStatuses.skipped))
    }
    setIsVisible(false)
  }, [setIsVisible, isMergedAutoInEditor, dispatch, isPrefillFailed])

  // close modal by timeout for failure and success modals
  const handleTimeoutClose = useCallback(() => {
    const timeout = setTimeout(handleClose, 2000)
    return () => clearTimeout(timeout)
  }, [handleClose])

  // effect for make modal opened correctly
  useEffect(() => {
    setIsVisible(status && !HIDDEN_STATUSES.includes(status))
    if (!SKIPPED_OR_DONE_STATUSES.includes(status)) setSkippedOrDone(false)
  }, [status])

  // show modal only if it is not skipped or already done
  if (isSkippedOrDone) return null

  return (
    <Transition in={isVisible} timeout={0} appear unmountOnExit>
      {state => (
        <Fragment>
          {isLaptop && <PrefillPopoverMobileOverlay />}
          <PrefillPopoverContainer in={state} isLaptop={isLaptop}>
            {status === PrefillStatuses.scheduled && (
              <PrefillPopoverProcessing
                documentId={documentId}
                provider={provider}
                onClose={handleClose}
              />
            )}
            {isPrefillDone && (
              <PrefillPopoverSuccess
                provider={provider}
                status={status}
                onAutoClose={handleTimeoutClose}
                onClose={handleClose}
              />
            )}
            {isPrefillFailed && (
              <PrefillPopoverFailure status={status} provider={provider} onClose={handleClose} />
            )}
          </PrefillPopoverContainer>
        </Fragment>
      )}
    </Transition>
  )
}
