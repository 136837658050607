import { FeedbackVersionEnum, InterviewAnswer } from 'builder/modules/interview/types'

export const getFeedbackVersion = (
  answer: InterviewAnswer | undefined,
): FeedbackVersionEnum | undefined => {
  if (!answer || !answer.feedback || answer?.ai_job?.status === 'pending') {
    return undefined
  }
  if (typeof answer.feedback.is_structured === 'boolean') {
    return FeedbackVersionEnum.v2
  }

  return FeedbackVersionEnum.v1
}
