import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;

  background: transparent;
  z-index: 2;

  ${Media.AboveTablet`
    padding: 25px;
  `};

  ${Media.Tablet`
    padding: 16px;
  `};

  ${Media.Phone`
    padding: 12px;
  `};
`

export const CloseIcon = styled(Icon24.Close)`
  width: 40px;
  height: 40px;
  padding: 8px;
  color: ${Colors.Neutral40};
  cursor: pointer;
  flex-shrink: 0;
  z-index: 1;
`
