import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const ShowStyledHTML = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
  & b {
    color: ${Colors.Neutral100};
    ${FontWeights.Regular};
  }
`
