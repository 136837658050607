import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { behaviourType, InterviewQuestion } from 'builder/modules/interview/types'
import { CountDownOverlay } from 'builder/components/CountDownOverlay/CountDownOverlay'
import { useInterview } from 'builder/views/Interview/hooks/useInterview'
import { selectors } from 'builder/modules/interview/interviewModule'

import { PerformanceLoggerWithMainDsn as PerformanceLogger } from 'builder/services/PerformanceLogger'
import { useComponentWillMount } from 'builder/hooks/useComponentWillMount'
import { formatSecondsToMMSS } from '../../../../utils/formatTime'

import { DELAY_FOR_FEEDBACK } from '../../constants'
import { getMimeType } from '../../utils/getMimeType'
import { invalidateCacheForInterviews } from '../../utils/invalidateCacheForInterviews'
import { delay } from '../../utils/delay'
import { PAGE, VideoMicModal } from './VideoMicModal/VideoMicModal'
import { MicVideoControls } from './MicVideoControls/MicVideoControls'
import {
  MidContainer,
  Container,
  FooterContainer,
  FooterContainerItem,
  InterviewDurationText,
  InterviewDurationTime,
  NextControlButtonContainer,
  Question,
  QuestionStatementText,
  Title,
  TitleContainer,
  VideoSpeechTextContainer,
  YourAnswerTitle,
  BodyContainer,
  Top,
  Note,
} from './styles'
import { Webcam } from './Webcam/Webcam'
import { VoiceIndicator, VoiceIndicatorStatusEnum } from './VoiceIndicator/VoiceIndicator'
import { useTimer } from './hooks/useTimer'
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog'
import { TimeTickingModal } from './TimeTickingModal/TimeTickingModal'
import { EndLoading } from './EndLoading/EndLoading'
import { TimeUpDialog } from './TimeUpDialog/TimeUpDialog'
import { ActionButtons } from './ActionButtons/ActionButtons'
import { Menu } from './Menu/Menu'
import { CompleteModal } from './CompleteModal/CompleteModal'
import { CloseModal } from './CloseModal/CloseModal'
import { NextControlButton } from './ControlButton'
import { UserMediaType, useGetStream } from './hooks/useGetStream'
import { useRecordingMedia } from './hooks/useRecordingMedia'
import { useManageRecordingInterview } from './hooks/useManageRecordingInterview'
import { useAnnouncementAudioPlay } from './hooks/useAnnouncementAudioPlay'
import { StartLoading } from './StartLoading/StartLoading'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player'

export const InterviewPlayerView = () => {
  const navigate = useNavigate()
  const interviewId = useSelector(selectors.interviewId)

  const audioStream = useGetStream(UserMediaType.audio)

  const audioVideoStream = useGetStream(UserMediaType.video)

  const refQuestion = useRef<HTMLDivElement>(null)
  const { data: interview } = useInterview({ interviewId })
  const questions = interview?.questions

  const [enableConfirmationDialog, setEnableConfirmationDialog] = useState(false)
  const [userInterruptedInterview, setUserInterruptedInterview] = useState(false)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [showCompleteModal, setShowCompleteModal] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(false)

  const { i18n } = useI18n()

  const [isRecording, setIsRecording] = useState(false)

  const [showVideoCheckModal, setShowVideoCheckModal] = useState<{
    enabled: boolean
    initialPage: PAGE
  }>({
    enabled: true,
    initialPage: 'initial',
  })

  const [showCountDown, setShowCountDown] = useState(false)

  const { isPhone, isDesktop } = useMediaQueries()
  const [question, setQuestion] = useState<InterviewQuestion>()
  const [showLoading, setShowLoading] = useState<'start' | 'end' | null>('start')

  const [enabledNextQuestionButton, setEnabledNextQuestionButton] = useState(false)

  const startQuestionIndex = useMemo(() => {
    const lastUnansweredQuestion = interview?.questions.findIndex(q => !q.answer?.audio) || -1
    return lastUnansweredQuestion === -1 ? 0 : lastUnansweredQuestion
  }, [interview])

  const {
    playQuestionAnnouncement,
    loading: isAnnouncementLoading,
    speaking,
    stopAnnouncement,
  } = useAnnouncementAudioPlay(startQuestionIndex, interview)

  useEffect(() => {
    if (!interviewId) {
      navigate('/interview-preparation/dashboard')
    }
  }, [navigate])

  const { createNewAnswer, uploadBlobChunkPart, stopAnswer, stopInterview, interruptedAnswer } =
    useManageRecordingInterview()

  const recordingMediaAudio = useRecordingMedia({
    stream: audioStream.stream,
    media: 'audio',
    uploadBlobChunkPart,
  })

  const recordingMediaVideoAudio = useRecordingMedia({
    stream: audioVideoStream.stream,
    media: 'video',
    uploadBlobChunkPart,
  })

  const isLastQuestion = !!(
    question &&
    Array.isArray(questions) &&
    questions[questions.length - 1] &&
    question.id === questions[questions.length - 1].id
  )
  const disableStreams = () => {
    return Promise.allSettled([audioStream.disableStream(), audioVideoStream.disableStream()])
  }

  const finishedInterview = useCallback(async (interrupted: boolean) => {
    setShowLoading('end')

    try {
      await Promise.all([
        stopInterview(),
        delay(interrupted ? 0 : DELAY_FOR_FEEDBACK),
        disableStreams(),
      ])
      await PerformanceLogger.finish()
      trackInternalEvent('complete_interview', {
        label: 'interview_prep',
      })

      await invalidateCacheForInterviews()
      navigate(interrupted ? '/interview-preparation/dashboard' : '/interview-preparation/feedback')
    } catch (error) {
      console.error(error)
    }
  }, [])

  const index = questions?.findIndex(q => q.id === question?.id)
  const currentIndex = index === -1 ? 0 : index

  const interruptedInterview = async (questionId: number) => {
    stopAnnouncement()
    trackInternalEvent('pause_interview', {
      label: 'interview_prep',
      total_questions: interview?.questions.length,
      answered_questions: currentIndex,
    })
    setShowCloseModal(true)
    setUserInterruptedInterview(true)
    interruptedAnswer(questionId)
    finishedInterview(true)
  }

  const stopRecordings = async (questionId: number) => {
    const stopAudioPromise = recordingMediaAudio.stop(questionId)
    const stopVideoPromise = recordingMediaVideoAudio.stop(questionId)
    setIsRecording(false)

    stopAnswer(question?.id, stopAudioPromise, stopVideoPromise)
  }

  const timer = useTimer(() => question && stopRecordings(question?.id), isRecording)

  useEffect(() => {
    if (!question && interview?.questions) {
      const lastUnansweredQuestion = interview?.questions.findIndex(q => !q.answer?.audio)
      setQuestion(interview?.questions[lastUnansweredQuestion === -1 ? 0 : lastUnansweredQuestion])
    }
  }, [interview])

  const [mimeTypeForVideo, setMimeTypeVideo] = useState<string>()
  const [mimeTypeForAudio, setMimeTypeAudio] = useState<string>()
  const mimeTypeRef = useRef(false)
  useEffect(() => {
    const processingMimeType = async () => {
      if (
        audioVideoStream.stream === undefined ||
        audioStream.stream === undefined ||
        mimeTypeRef.current
      ) {
        return
      }
      mimeTypeRef.current = true
      const [mimeTypeForVideo, mineTypeForAudio] = await Promise.all([
        audioVideoStream.stream === null
          ? Promise.resolve('')
          : getMimeType(audioVideoStream.stream, 'video'),
        audioStream.stream === null
          ? Promise.resolve('')
          : getMimeType(audioStream.stream, 'audio'),
      ])
      setMimeTypeVideo(mimeTypeForVideo)
      setMimeTypeAudio(mineTypeForAudio)
    }
    processingMimeType()
  }, [audioVideoStream?.stream, audioStream?.stream])

  useEffect(() => {
    const record = async () => {
      if (
        question?.id &&
        !showVideoCheckModal.enabled &&
        !showCountDown &&
        mimeTypeForAudio !== undefined &&
        mimeTypeForVideo !== undefined
      ) {
        setEnabledNextQuestionButton(false)
        await createNewAnswer({
          questionId: question.id,
          mimeTypeForAudio,
          mimeTypeForVideo,
        })

        setEnabledNextQuestionButton(true)

        await playQuestionAnnouncement(question?.id)

        await Promise.all([
          recordingMediaAudio.start(question.id),
          recordingMediaVideoAudio.start(question.id),
        ])
        setIsRecording(true)
      }
    }
    record()
  }, [question?.id, showVideoCheckModal, showCountDown, mimeTypeForVideo, mimeTypeForAudio])

  useEffect(() => {
    audioVideoStream.askForPermission()
    audioStream.askForPermission()

    return () => {
      disableStreams()
    }
  }, [])

  const jobTitle = interview?.job_title
    ? [interview?.job_title, i18n.t(`${TRANSLATION_PREFIX}.interview`)]
    : []

  const company = interview?.company_name
    ? [i18n.t(`${TRANSLATION_PREFIX}.at_preposition`), interview?.company_name]
    : []
  const title = interview?.job_title ? [jobTitle, company].flat().join(' ') : ''

  const renderVoiceIndicator = () => (
    <>
      <YourAnswerTitle>{i18n.t(`${TRANSLATION_PREFIX}.your_answer`)}</YourAnswerTitle>
      <VoiceIndicator
        audioStream={audioStream.stream || undefined}
        isRecording={recordingMediaAudio.getStatus(question?.id) === 'recording'}
        status={
          audioStream.stream?.getAudioTracks()[0].enabled
            ? VoiceIndicatorStatusEnum.listening
            : VoiceIndicatorStatusEnum.muted
        }
        onShowHelp={() => setShowVideoCheckModal({ enabled: true, initialPage: 'help' })}
      />
    </>
  )

  const renderWebcam = () => {
    return (
      audioVideoStream.stream && (
        <Webcam
          srcObject={audioVideoStream.stream}
          isPhone={isPhone}
          isRecording={
            audioVideoStream.stream?.getVideoTracks()[0].enabled &&
            recordingMediaAudio.getStatus(question?.id) === 'recording'
          }
        />
      )
    )
  }

  const onNextQuestionClick = async () => {
    if (question) {
      stopRecordings(question.id)
      if (questions && currentIndex !== undefined) {
        if (isLastQuestion || userInterruptedInterview) {
          finishedInterview(false)
        }

        trackInternalEvent('submit_answer', {
          label: 'interview_prep',
        })

        if (!isLastQuestion) {
          setQuestion(questions[currentIndex + 1])
          setEnableConfirmationDialog(false)
        }
      }
    }
  }

  const confirmationKey = useMemo(() => {
    if (userInterruptedInterview) {
      return 'interrupted_interview'
    }

    if (isLastQuestion) {
      return 'complete_interview'
    }

    return 'next_question'
  }, [userInterruptedInterview, isLastQuestion])

  useComponentWillMount(() => {
    PerformanceLogger.listen({
      name: 'interview-player-page',
    })
  })

  useEffect(() => {
    if (!interview) {
      PerformanceLogger.operation('start-interview', { tags: { interviewId } })
    } else {
      PerformanceLogger.operationEnd('start-interview')
    }
  }, [interview])

  const location = useLocation() as unknown as {
    state?: {
      forceStartLoading?: boolean
      startPageType?: behaviourType
      companyName?: string
      isRealCompany: boolean
    }
  }
  const [forceStartLoading, setForceStartLoading] = useState(!!location.state?.forceStartLoading)

  useEffect(() => {
    if (showLoading === 'start' && interview && !isAnnouncementLoading && !forceStartLoading) {
      return setShowLoading(null)
    }
  }, [isAnnouncementLoading, interview, showLoading, forceStartLoading])

  if (showLoading === 'start') {
    return (
      <StartLoading
        forceStartLoading={Boolean(forceStartLoading)}
        startPageType={location.state?.startPageType}
        onCompletedSteps={() => setForceStartLoading(false)}
        companyName={location.state?.companyName}
      />
    )
  }

  if (showLoading === 'end') {
    return (
      <EndLoading
        userInterruptedInterview={userInterruptedInterview}
        startPageType={location.state?.startPageType}
      />
    )
  }

  return (
    <Container ref={refQuestion}>
      {timer.showTimeUpDialog && (
        <TimeUpDialog
          isLastQuestion={isLastQuestion}
          onClick={() => {
            onNextQuestionClick()
            timer.setShowTimeUpDialog(false)
          }}
        />
      )}
      {showVideoCheckModal.enabled && (
        <VideoMicModal
          initialPage={showVideoCheckModal.initialPage}
          onClose={() => {
            setShowVideoCheckModal({ enabled: false, initialPage: 'initial' })
            if (showVideoCheckModal.initialPage === 'initial') {
              setShowCountDown(true)
            }
          }}
        />
      )}
      {showCountDown && (
        <CountDownOverlay
          initialCounterValue={3}
          onCompleted={() => setShowCountDown(false)}
          onCancel={() => navigate('/interview-preparation/dashboard')}
          cancelTitle={i18n.t(`${TRANSLATION_PREFIX}.cancel`)}
        />
      )}
      {timer.showTimeTickingModal && (
        <TimeTickingModal
          isLastQuestion={isLastQuestion}
          remainingTime={formatSecondsToMMSS(timer.time)}
          onCancel={() => timer.setShowTimeTickingModal(false)}
          onConfirmation={onNextQuestionClick}
        />
      )}
      {showCompleteModal && (
        <CompleteModal
          onClose={() => setShowCompleteModal(false)}
          onComplete={onNextQuestionClick}
        />
      )}
      {showSideMenu && (
        <Menu
          onClose={() => setShowSideMenu(false)}
          onClick={(path: string) => {
            navigate(path)
          }}
        />
      )}
      {showCloseModal && (
        <CloseModal
          onClose={() => setShowCloseModal(false)}
          onConfirm={() => {
            question && interruptedInterview(question.id)
          }}
        />
      )}
      <ActionButtons
        onSideMenuClick={() => setShowSideMenu(true)}
        onCloseClick={() => setShowCloseModal(true)}
        onDashboardClick={() => setShowCloseModal(true)}
      />
      <BodyContainer>
        <Top>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>

          {!isDesktop && <VideoSpeechTextContainer>{renderWebcam()}</VideoSpeechTextContainer>}
        </Top>
        <MidContainer>
          <>
            <QuestionStatementText>
              {i18n.t(`${TRANSLATION_PREFIX}.question`)} {(currentIndex || 0) + 1}
            </QuestionStatementText>
            {question && (
              <div>
                <Question ref={refQuestion} $ref={refQuestion.current}>
                  {question?.content}
                </Question>
                {location.state?.isRealCompany && interview?.company_name && (
                  <Note>
                    {i18n.t(`${TRANSLATION_PREFIX}.real_interview_note`, {
                      companyName: interview.company_name,
                    })}
                  </Note>
                )}
              </div>
            )}
          </>

          {!isDesktop && renderVoiceIndicator()}
        </MidContainer>
        {isDesktop && (
          <VideoSpeechTextContainer>
            {renderWebcam()}
            {renderVoiceIndicator()}
          </VideoSpeechTextContainer>
        )}
      </BodyContainer>

      <NextControlButtonContainer $show={!speaking && enabledNextQuestionButton}>
        <NextControlButton
          onClick={() => {
            if (isLastQuestion) {
              setShowCompleteModal(true)
            } else {
              setEnableConfirmationDialog(true)
            }
          }}
        >
          {i18n.t(
            `${TRANSLATION_PREFIX}.${isLastQuestion ? 'complete_interview' : 'next_question'}`,
          )}
        </NextControlButton>
        {enableConfirmationDialog && (
          <ConfirmationDialog
            text={i18n.t(`${TRANSLATION_PREFIX}.confirmation_${confirmationKey}`)}
            onNoClick={() => {
              setEnableConfirmationDialog(false)
              setUserInterruptedInterview(false)
            }}
            onYesClick={onNextQuestionClick}
          />
        )}
      </NextControlButtonContainer>

      <FooterContainer>
        <FooterContainerItem>
          <InterviewDurationText>
            {!isPhone && i18n.t(`${TRANSLATION_PREFIX}.interview_duration`)}
            <InterviewDurationTime>{formatSecondsToMMSS(timer.time)}</InterviewDurationTime>
          </InterviewDurationText>
        </FooterContainerItem>
        <MicVideoControls />
        <FooterContainerItem />
      </FooterContainer>
    </Container>
  )
}
