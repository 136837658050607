import { useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import { Icon20 } from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import {
  FeedbackVersionEnum,
  Interview,
  InterviewAnswer,
  InterviewQuestion,
} from 'builder/modules/interview/types'
import { VideoPlayer } from '../VideoPlayer/VideoPlayer'
import { Badge, BadgeEnum } from '../Badge/Badge'

import { Question } from '../Question/Question'
import { Tip } from '../Tip/Tip'
import { ShowStyledHTML } from '../ShowStyledHTML/ShowStyledHTML'
import { getFeedbackVersion } from '../../utils'
import {
  BadgeContainer,
  Container,
  HeaderContainer,
  Section,
  SectionContainer,
  SectionTextContainer,
  SectionTextsContainer,
  SectionTitle,
  TextContainer,
  VideoContainer,
} from './styles'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.feedback_section`

interface Props {
  answer: InterviewAnswer | undefined
  interview: Interview | undefined
  question: InterviewQuestion | undefined
  selectedQuestionIndex: number
}

export const FeedbackSection = ({ answer, interview, question, selectedQuestionIndex }: Props) => {
  const { i18n } = useI18n()
  const { isPhone } = useMediaQueries()
  const feedbackVersion = getFeedbackVersion(answer)

  const skeletonSection = (
    <div>
      <Skeleton width={123} height={20} />
      <Skeleton width={168} height={20} style={{ marginTop: '34px' }} />
      <Skeleton width={407} height={20} style={{ marginTop: '4px' }} />
      <Skeleton width={345} height={20} style={{ marginTop: '4px' }} />
      <Skeleton width={366} height={20} style={{ marginTop: '4px' }} />
    </div>
  )

  function renderBadge(title: string, color: BadgeEnum, hasContent: boolean) {
    if (
      answer?.ai_speech_job?.status === 'success' &&
      answer?.ai_job?.status === 'success' &&
      !hasContent
    ) {
      return null
    }

    if (
      answer?.ai_speech_job?.status == null ||
      answer?.ai_speech_job?.status === 'success' ||
      answer?.ai_speech_job?.status === 'pending'
    ) {
      return (
        <BadgeContainer>
          {answer?.ai_speech_job?.status == null || answer?.ai_speech_job?.status === 'pending' ? (
            <Skeleton width={100} height={20} />
          ) : (
            <Badge icon={<Icon20.Star />} text={title} type={color} />
          )}
        </BadgeContainer>
      )
    }
    return null
  }

  function renderSection(
    jobName: 'ai_job' | 'ai_speech_job',
    title: string | null | undefined,
    text: string | null | undefined,
    enableTip = false,
  ) {
    const job = (answer || {})[jobName]

    if (job?.status == null || job?.status === 'pending') {
      return <>{skeletonSection}</>
    }

    if (job?.status === 'success' && title && text) {
      return (
        <>
          <SectionTextsContainer id={jobName}>
            <SectionTitle>{title}</SectionTitle>

            <ShowStyledHTML dangerouslySetInnerHTML={{ __html: text }} />
          </SectionTextsContainer>
          {enableTip && <Tip companyName={interview?.company_name} />}
        </>
      )
    }

    return null
  }

  const answerFromInterview = useMemo(() => {
    return interview?.questions[selectedQuestionIndex]?.answer
  }, [interview, selectedQuestionIndex])

  return (
    <Container>
      <HeaderContainer>
        {!isPhone && (
          <TextContainer>
            <Question
              question={question?.content}
              questionNumber={selectedQuestionIndex + 1}
              questionTotal={interview?.questions.length}
              enableEllipse={false}
            />
          </TextContainer>
        )}
        <VideoContainer>
          <VideoPlayer
            answerDurationInSeconds={answer?.duration}
            audioUrl={answerFromInterview?.audio}
            videoUrl={answerFromInterview?.video}
          />
        </VideoContainer>
      </HeaderContainer>

      {(feedbackVersion === undefined || feedbackVersion === FeedbackVersionEnum.v2) && (
        <>
          <SectionContainer>
            <Section>
              {renderBadge(
                i18n.t(`${TRANSLATION_PREFIX}.strengths`),
                BadgeEnum.green,
                !!(
                  answer?.feedback?.ton_of_voice_summary_headline_strengths ||
                  answer?.feedback?.strengths_headline
                ),
              )}
              <SectionTextContainer>
                {renderSection(
                  'ai_speech_job',
                  answer?.feedback?.ton_of_voice_summary_headline_strengths,
                  answer?.feedback?.ai_summary_strengths,
                )}
                {renderSection(
                  'ai_job',
                  answer?.feedback?.strengths_headline,
                  answer?.feedback?.strengths,
                )}
              </SectionTextContainer>
            </Section>
          </SectionContainer>

          <SectionContainer>
            <Section>
              {renderBadge(
                i18n.t(`${TRANSLATION_PREFIX}.areas_for_improvement`),
                BadgeEnum.orange,
                !!(
                  answer?.feedback?.ton_of_voice_summary_headline_areas_for_improvement ||
                  answer?.feedback?.areas_for_improvement_headline
                ),
              )}
              <SectionTextContainer>
                {renderSection(
                  'ai_speech_job',
                  answer?.feedback?.ton_of_voice_summary_headline_areas_for_improvement,
                  answer?.feedback?.ai_summary_areas_for_improvement,
                  false,
                )}
                {renderSection(
                  'ai_job',

                  answer?.feedback?.areas_for_improvement_headline,
                  answer?.feedback?.areas_for_improvement,
                  true,
                )}
              </SectionTextContainer>
            </Section>
          </SectionContainer>
        </>
      )}
    </Container>
  )
}
