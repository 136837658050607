import styled from 'styled-components'
import Icon24 from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { FontWeights } from 'builder/styles/typography'

export const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;

  background: ${Colors.Neutral5};
  z-index: 2;

  ${Media.AboveTablet`
    padding-top: 16px;
    padding-bottom: 16px;
  `};

  ${Media.Tablet`
    padding-top: 12px;
  `};

  ${Media.Phone`
    padding-top: 8px;
  `};
`

export const ActionButtonsLeft = styled.div`
  display: flex;
  gap: 4px;
  z-index: 1;
`
export const NavbarContainer = styled.div`
  padding: 0 12px;
  display: none;

  ${Media.AboveTablet`
      display: unset;
  `}
`

export const MenuButton = styled(Icon24.DashboardBuilder)`
  padding: 8px;
  width: 40px;
  height: 40px;

  cursor: pointer;
  ${Media.Phone`
    display: none;
  `};
`

export const DashboardButton = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  ${FontWeights.Regular};
  color: ${Colors.Neutral80};

  ${Media.Phone`
    padding: 8px 12px;
  `};
`

export const CloseIcon = styled(Icon24.Close)`
  width: 40px;
  height: 40px;
  padding: 8px;
  color: ${Colors.Neutral40};
  cursor: pointer;
  flex-shrink: 0;
  z-index: 1;
`
export const MiddleContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${Colors.Neutral5};
`
