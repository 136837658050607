/* eslint-disable no-restricted-globals */
import { useSelector } from 'react-redux'
import { selectors } from 'builder/modules/init'
import { Store } from 'builder/modules/store'
import { templateGroups } from 'builder/utils/templateGroups'
import { isResume } from 'builder/utils/getTemplates'
import { TemplateCategories } from 'builder/modules/resumeEditor'
import { TemplateGroupHeader, TemplateGroup } from './styles'
import { TemplatesList } from './TemplatesList'

type Props = {
  type: string
  selectedTemplates: string[]
  selectedCategory: `${TemplateCategories}`
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function TemplatesPreviewList(props: Props): JSX.Element {
  const { type, selectedTemplates, selectedCategory } = props

  const editorState = useSelector((state: Store) => state.generalEditor)
  const locale = useSelector(selectors.locale)
  const resume = useSelector((state: Store) => state.resumeEditor.resume)
  const coverLetter = useSelector((state: Store) => state.coverLetterEditor.coverLetter)

  const isTypeResume = isResume(type)
  const templateGroupsList = templateGroups(editorState, type, locale)
  const currentDocument = isTypeResume ? resume : coverLetter
  const isDataLoaded = currentDocument && editorState.isLoaded

  return (
    <>
      {isDataLoaded &&
        templateGroupsList.map(({ title, templates }) => (
          <TemplateGroup key={title}>
            {templateGroupsList.length > 1 && <TemplateGroupHeader>{title}</TemplateGroupHeader>}
            <TemplatesList
              templates={templates}
              type={type}
              selectedTemplates={selectedTemplates}
              selectedCategory={selectedCategory}
            />
          </TemplateGroup>
        ))}
    </>
  )
}
