import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ResumeDetails,
  selectors as resumeDistributionSelectors,
  actions as resumeDistributionActions,
} from 'builder/modules/resumeDistribution'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { ERDSteps } from '../../types'
import FormLayout from '../../components/FormLayout'
import PersonalDetailsForm from '../../components/PersonalDetailsForm'
import { JobPreferencesForm } from '../../components/JobPreferencesForm/JobPreferencesForm'

const ResumeDistributionEditView = () => {
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState<ERDSteps>(ERDSteps.FORM_STEP_1)
  const selectedResumeDetails = useTypedSelector<ResumeDetails>(
    resumeDistributionSelectors.selectedResumeDetails,
  )

  switch (activeStep) {
    case ERDSteps.FORM_STEP_1:
      return (
        <FormLayout activeStep={activeStep}>
          <PersonalDetailsForm
            resumePayload={selectedResumeDetails}
            setActiveStep={setActiveStep}
            setSelectedResumeDetails={(resumeDetails: ResumeDetails) => {
              dispatch(
                resumeDistributionActions.setPersonalDetailsForm({
                  firstName: '',
                  lastName: '',
                  email: '',
                  jobTitle: '',
                  linkedin: '',
                  fullAddress: '',
                }),
              )
              if (resumeDetails) {
                dispatch(resumeDistributionActions.setResumeDetails(resumeDetails))
              }
            }}
          />
        </FormLayout>
      )
    case ERDSteps.FORM_STEP_2:
      return (
        <FormLayout activeStep={activeStep}>
          <JobPreferencesForm setActiveStep={setActiveStep} />
        </FormLayout>
      )
    default:
      return <></>
  }
}

export default ResumeDistributionEditView
