import { Skeleton } from 'builder/Skeleton/Skeleton'
import { Classification, Container, Title, Value } from './styles'

export enum ClassificationEnum {
  positive = 'positive',
  negative = 'negative',
}
export interface Classification {
  text: string
  value: string | number
  color: string
  classification: ClassificationEnum
}

interface Props {
  title: string
  classification: Classification
}
export const Card = ({ title, classification }: Props) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>

        <Classification $classificationColor={classification.color}>
          {classification.text}
        </Classification>
      </div>
      <Value>{classification.value}</Value>
    </Container>
  )
}

export const SkeletonCard = () => (
  <Container>
    <Skeleton width={109} height={20} />
    <Skeleton width={85} height={20} style={{ marginTop: '4px' }} />
    <Skeleton width={66} height={48} style={{ marginTop: '36px' }} />
  </Container>
)
