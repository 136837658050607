import { memo, useEffect } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { ResumeUploadStep, PrefillResumeEvents } from 'builder/modules/constants'
import UploadResumeIllustration from 'images/builder/fill-resume/upload-illustration.png'
import { Div, UploadFailedIllustration, FailedTitle, ErrorMessage } from '../styles'

const FileUploadFailed = () => {
  const { i18n } = useI18n()
  const { validationError, errorSource } = useAppSelector(
    state => state.resumeEditor.prefillResumeSection,
  )

  useEffect(() => {
    if (errorSource === ResumeUploadStep.validation)
      trackInternalEvent(PrefillResumeEvents.seeResumeUploadError, { error: validationError })
    if (errorSource === ResumeUploadStep.parsing)
      trackInternalEvent(PrefillResumeEvents.seeResumeParsingError)
  }, [errorSource, validationError])

  const genericError = i18n.t('builder.upload_resume.generic_error')

  return (
    <>
      <Div>
        <UploadFailedIllustration src={UploadResumeIllustration} />
      </Div>
      <FailedTitle>{i18n.t('builder.upload_resume.upload_failed')}</FailedTitle>
      <ErrorMessage>{validationError || genericError}</ErrorMessage>
    </>
  )
}

export default memo(FileUploadFailed)
