export function formatSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  let formattedTime = ''
  if (minutes > 0) {
    formattedTime += `${minutes}m `
  }
  formattedTime += `${remainingSeconds}s`

  return formattedTime
}
