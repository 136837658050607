import * as React from 'react'
import { useDispatch } from 'react-redux'
import type { UserDocumentType } from '@rio/types'

import { useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { i18n as I18n } from 'builder/utils/i18n'
import { useEffectOnMount } from 'builder/hooks/useEffectOnMount'
import SnackBar from 'builder/components/SnackBar'
import RateModal from 'builder/components/RateModal'
import CioLimitModal from 'builder/components/CioLimitModal'
import ShareModal from 'builder/components/ShareModal'
import DocxModal from 'builder/components/DocxModal'
import CoRegModal from 'builder/components/CoRegistration/Modal'
import MergeDocumentsModal from 'builder/components/MergeDocumentsModal'
import CoverLetterModal from 'builder/components/CoverLetterModal'
import PrefillResumeModal from 'builder/components/PrefillModal'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as uiSelectors, actions as uiActions } from 'builder/modules/ui'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { selectors as careerProfileSelectors } from 'builder/modules/careerProfile'
import { useQueryParam } from 'builder/hooks/useQueryParam'
import TREditModal from 'builder/components/TREditModal'
import { useAppSelector } from 'builder/hooks/useAppSelector'
import { ResumeEditorQueryParams, PanelSessionStorageKeys } from 'builder/modules/panel'
import {
  CareerInsightsStatusModal,
  CareerInsightsPaywallModal,
} from 'builder/components/CareerInsights'
import tunerURL from 'images/modals/resume.jpg'

const MagicContainer: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const [limitDocumentType] = useQueryParam<UserDocumentType>('limit_document_type')
  const coverLetterModalId = useTypedSelector(uiSelectors.coverLetterModalId)
  const docxModalDocumentId = useTypedSelector(uiSelectors.docxModalDocumentId)
  const docxModalDocumentType = useTypedSelector(uiSelectors.docxModalDocumentType)
  const isRateModalOpen = useTypedSelector(uiSelectors.isRateModalOpen)
  const isMergeModalOpen = useTypedSelector(uiSelectors.isMergeModalOpen)
  const prefillModalDocumentId = useTypedSelector(uiSelectors.prefillModalDocumentId)
  const limitModalDocumentType = useTypedSelector(uiSelectors.limitModalType)
  const careerInsightsStatusModalType = useTypedSelector(
    careerProfileSelectors.careerInsightsStatusModalType,
  )
  const careerInsightsPaywallModalType = useTypedSelector(
    careerProfileSelectors.openedPaywallModalType,
  )
  const { ui } = useAppSelector(store => store)
  const { isTREditModalOpen, editModalTrDocId } = ui

  const [query] = useSearchParams()
  const showDocxModal = docxModalDocumentType && docxModalDocumentId

  useEffectOnMount(() => {
    if (limitDocumentType) {
      dispatch(uiActions.openLimitModal(limitDocumentType))
    }
    if (query.has(ResumeEditorQueryParams.isResumeTailor)) {
      dispatch(editorActions.setOpenOptimizerModal(true))
      dispatch(editorActions.startResumeOptimizerProcess())
    }
  })

  const handleCancel = () => {
    dispatch(uiActions.closeLimitModal())
    sessionStorage.removeItem(PanelSessionStorageKeys.ACTION_AFTER_UPGRADING_PLAN)
    limitModalDocumentType && trackInternalEvent(`close_${limitModalDocumentType}_limit_modal`)
  }
  return (
    <React.Fragment>
      {children}
      <SnackBar />
      <ShareModal />
      {showDocxModal && (
        <DocxModal documentId={docxModalDocumentId} documentType={docxModalDocumentType} />
      )}
      <CoRegModal />
      {isRateModalOpen && <RateModal />}
      {isMergeModalOpen && <MergeDocumentsModal />}
      {coverLetterModalId && <CoverLetterModal letterId={coverLetterModalId} />}
      {prefillModalDocumentId && <PrefillResumeModal documentId={prefillModalDocumentId} />}
      {limitModalDocumentType && (
        <CioLimitModal
          documentType={limitModalDocumentType}
          cancelButtom={I18n.t('builder.job_tracking.limit_modal.cancel')}
          upgradebutton={I18n.t('builder.job_tracking.limit_modal.upgrade')}
          tunerImg={tunerURL}
          onClose={() => handleCancel()}
        />
      )}
      {isTREditModalOpen && editModalTrDocId && <TREditModal id={editModalTrDocId} />}
      {careerInsightsStatusModalType && (
        <CareerInsightsStatusModal type={careerInsightsStatusModalType} />
      )}
      {careerInsightsPaywallModalType && (
        <CareerInsightsPaywallModal type={careerInsightsPaywallModalType} />
      )}
    </React.Fragment>
  )
}

export default MagicContainer
