import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
`

export const Title = styled.div`
  color: ${Colors.Neutral50};
  ${Typography.Tiny};
  ${FontWeights.Regular};
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
`

export const ProgressContainer = styled.div<{ $color: string }>`
  width: 100%;
  height: 8px;
  border-radius: 100px;

  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: ${props => props.$color};
`

export const Progress = styled.div<{ $score: number; $color: string; $left: number }>`
  position: absolute;
  overflow: hidden;
  width: ${props => props.$score}%;
  height: 100%;
  background-color: ${props => props.$color};
  top: 0;
  left: ${props => props.$left}%;
  border-radius: 100px;
`
