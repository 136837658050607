import { SuggestionSelectedEventData } from 'react-autosuggest'
import { trackInternalEvent } from '@rio/tracking'
import { TextField } from 'builder/components/TextField'
import { useI18n } from 'builder/hooks/useI18n'
import { CompanySuggestionType } from 'builder/modules/interview/types'
import { useCompanyNames } from '../../../../../hooks/useCompanyNames'
import { CustomAsyncAutosuggest, EmptyState } from './styles'

const TRANSLATION_KEY = 'builder.interview_prep_view.company_name_async_autosuggest'

interface Props {
  companyName?: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  onChangeSuggestion: (value: CompanySuggestionType) => void
  hasError?: boolean
  disabled?: boolean
  placeholder?: string
  showEmptyState?: boolean
}

export const CompanyNameAsyncAutosuggest = ({
  companyName,
  onChangeSuggestion,
  onChange,
  hasError,
  disabled,
  placeholder,
  showEmptyState = true,
}: Props) => {
  const { i18n } = useI18n()
  const { mutateAsync: getCompanyName } = useCompanyNames()
  const onSuggestionSelected = (
    _: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<CompanySuggestionType>,
  ) => {
    onChangeSuggestion(suggestion)
    trackInternalEvent('pick_up_company', {
      label: 'interview_prep',
      term: suggestion.text,
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  return (
    <CustomAsyncAutosuggest
      highlightedQuery
      value={companyName}
      onChange={handleChange}
      onSuggestionSelected={onSuggestionSelected}
      fetchItems={async (query: string) => {
        const res = await getCompanyName(query)
        return res.map(data => ({ text: data.company, id: data.company, ...data }))
      }}
      emptyStateElement={
        showEmptyState ? (
          <EmptyState>
            {i18n.t(`${TRANSLATION_KEY}.empty_state_message`)} {companyName?.toUpperCase()}
          </EmptyState>
        ) : null
      }
    >
      {inputProps => (
        <TextField
          {...inputProps}
          error={hasError}
          name="companyName"
          label={
            hasError
              ? i18n.t(`${TRANSLATION_KEY}.error_message`)
              : i18n.t(`${TRANSLATION_KEY}.label`)
          }
          placeholder={placeholder || i18n.t(`${TRANSLATION_KEY}.placeholder_v2`)}
          disabled={disabled}
        />
      )}
    </CustomAsyncAutosuggest>
  )
}
