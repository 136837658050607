import { ResumeEducation } from '@rio/types'
import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { addCardTo } from '../utils/addCardTo'
import { CheckFn } from '../types'
import { openCard } from '../utils/openCard'

const isValidCard = ({ school, description }: ResumeEducation): boolean => {
  return (
    typeof school === 'string' &&
    school.length > 0 &&
    typeof description === 'string' &&
    convertHtmlToText(description).length > 0
  )
}

export const checkEducation: CheckFn = ({ educations }) => {
  const isComplete = educations.filter(isValidCard).length > 0

  return {
    sectionId: SectionNames.educations,
    progress: isComplete ? 1 : 0,
    id: 'no_educations',
    title: i18n.t('builder.resume_tuner.tips.no_educations.title'),
    description: i18n.t('builder.resume_tuner.tips.no_educations.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_educations.subject'),
    label: i18n.t('builder.resume_tuner.issues.no_educations'),
    actionText: i18n.t('builder.resume_tuner.tips.no_educations.action_text'),
    action: () => {
      const invalidCard = educations.find(card => isValidCard(card) === false)
      invalidCard
        ? openCard(invalidCard, SectionNames.educations)
        : addCardTo(SectionNames.educations)
    },
  }
}
