import { useMemo } from 'react'
import { SelectValue, SelectControlProps as Props } from '../../types'
import { SelectButton } from '../SelectButton'
import * as Styled from './styles'

// Native selects support string values only
const stringify = (value: SelectValue) => JSON.stringify(value)
const normalize = (value: string) => JSON.parse(value)

export const MobileControl = <V extends SelectValue>({
  value,
  options,
  onChange,
  isDisabled,
  optionsListClassname,
  buttonOnClick,
  ...rest
}: Props<V>) => {
  const nativeOptions = useMemo(() => {
    return options.map(option => ({ ...option, id: stringify(option.id) }))
  }, [options])

  return (
    <div onClick={buttonOnClick}>
      <SelectButton
        isDisabled={isDisabled}
        useWhiteArrow={optionsListClassname === 'templates-filter'}
        {...rest}
      />

      <Styled.NativeSelect
        value={stringify(value)}
        onChange={e => onChange(normalize(e.target.value))}
        disabled={isDisabled}
      >
        {nativeOptions.map(({ id, name }) => (
          <option value={id} key={`${id}${name}`}>
            {name}
          </option>
        ))}
      </Styled.NativeSelect>
    </div>
  )
}
