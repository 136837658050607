import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

interface Body {
  email: string
  redirect_to?: string
}

export const useCreateMagicLink = () => {
  return useMutation(['useCreateMagicLink'], async (body: Body) => {
    const res = await baseClient.post(`/app/login/request-link`, body)
    return res.data
  })
}
