import { Component } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { sortableContainer } from 'react-sortable-hoc'
import { connect } from 'react-redux'
import {
  SectionNames,
  selectors as editorSelectors,
  actions as editorActions,
} from 'builder/modules/resumeEditor'
import { actions as coverLetterActions } from 'builder/modules/coverLetterEditor'
import { actions as uiActions } from 'builder/modules/ui'

import SortableSection from './SortableSection'
import AdditionalSections from 'builder/components/AdditionalSections'
import { generateRandomId } from 'builder/utils/generateRandomId'
import constructSectionsData, { groupSectionsBy } from './constructSectionsData'

import { SectionsListContainer } from './styles'
import ProfileSection from '../ProfileSummary/ProfileSection'
import GenerateAICVBannerNew from '../GenerateAICVBannerNew'
import GenerateAICVBanner from '../GenerateAICVBanner'
import { displaySectionPerTemplateConfig } from '../DocumentEditor/ATSResumeEditor/builderSectionsConfig'
import { withConfig } from 'builder/components/Config'
import PowerStatementSection from '../PowerStatement/PowerStatementSection'
import { trackInternalEvent } from '@rio/tracking'
import { camelToSnake } from 'builder/utils/camelToSnake'
export const DroppableSectionContainer = sortableContainer(({ children }) => <div>{children}</div>)

class NewSectionsList extends Component {
  static propTypes = {
    resume: PropTypes.object.isRequired,
    updateCard: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    addCard: PropTypes.func.isRequired,
    addCustomSection: PropTypes.func.isRequired,
    moveCard: PropTypes.func.isRequired,
    moveSection: PropTypes.func.isRequired,
    levels: PropTypes.object,
    updateSimpleField: PropTypes.func.isRequired,
    renameSection: PropTypes.func.isRequired,
    deleteSection: PropTypes.func.isRequired,
    openCard: PropTypes.func.isRequired,
    setIsClickedOnAiCVBanner: PropTypes.func.isRequired,
    openedCard: PropTypes.object,
    isAPremiumUser: PropTypes.bool,
    aiClFreeAttempts: PropTypes.number,
    currentUserDocuments: PropTypes.array,
    setShowAICoverLetterPaywall: PropTypes.func,
    openLimitModal: PropTypes.func,
    appLocale: PropTypes.string,
    config: PropTypes.object.isRequired,
  }

  state = {
    draggableId: null,
  }

  handleCardAdd = (sectionId, options) => {
    const cardId = generateRandomId()
    this.props.addCard(cardId, sectionId, { ...(options || {}), shouldOpen: true })
  }

  handleGenerateClick = () => {
    this.props.setIsClickedOnAiCVBanner(true)
  }

  handleCardToggle = (cardId, sectionId) => {
    const isOpen = this.isCardOpen(cardId, sectionId)

    this.props.openCard(isOpen ? null : { id: cardId, sectionId })
  }

  isCardOpen = (cardId, sectionId) => {
    if (this.state.isDragging || !this.props.openedCard) return false

    return this.props.openedCard.id === cardId && this.props.openedCard.sectionId === sectionId
  }

  preventSelection = e => {
    e.preventDefault()
    return false
  }

  handleSortStart = ({ node }) => {
    // Fix cursor
    document.addEventListener('selectstart', this.preventSelection)

    const { id } = node.dataset
    return this.setState({ draggableId: id })
  }

  handleSectionMove = ({ oldIndex, newIndex }) => {
    document.removeEventListener('selectstart', this.preventSelection)

    this.props.moveSection({
      offset: newIndex - oldIndex,
      sectionId: this.state.draggableId,
    })
    if (oldIndex !== newIndex) {
      trackInternalEvent('change_sections_order', {
        section: camelToSnake(this.state.draggableId),
        old_position: oldIndex + 1,
        new_position: newIndex + 1,
      })
    }

    this.setState({ draggableId: null })
  }

  checkGenerateAICVButton = (resume, config) => {
    if (!config?.features.aiCoverLetters) {
      return false
    }
    const { firstName, lastName, email, workExperiences } = resume
    if (!firstName || !lastName || !email) {
      return false
    }

    const hasWorkExperience =
      workExperiences.findIndex(
        ({ title, employer, dateFrom }) => title && employer && dateFrom,
      ) !== -1

    return hasWorkExperience
  }

  render() {
    const {
      resume,
      levels,
      addCustomSection,
      renameSection,
      deleteSection,
      moveCard,
      deleteCard,
      updateCard,
      aiClFreeAttempts,
      isAPremiumUser,
      setShowAICoverLetterPaywall,
      currentUserDocuments,
      openLimitModal,
      updateSimpleField,
      config,
    } = this.props
    const { hideSkillLevel, locale, template, skills, position } = resume
    const { draggableId } = this.state
    const { appLocale } = this.props

    const showGenerateAICVButton = this.checkGenerateAICVButton(resume, config)
    const sections = constructSectionsData(resume, appLocale)
    const filteredSections = sections.filter(section =>
      displaySectionPerTemplateConfig(resume.template, section.id),
    )
    const groups = groupSectionsBy(filteredSections, 'sortable')
    const showAIUsagePaywall = !isAPremiumUser && aiClFreeAttempts <= 0
    const currentUserCoverLetters = currentUserDocuments.filter(
      document => document.type === 'cover_letter',
    )
    const hasCoverLettersLimitReached = !isAPremiumUser && currentUserCoverLetters.length >= 1
    const showPowerStatementSection = template === 'shanghai'

    let generateCoverLetterHandler = this.handleGenerateClick

    if (hasCoverLettersLimitReached) {
      generateCoverLetterHandler = () => openLimitModal('cover_letter')
    } else if (showAIUsagePaywall) {
      generateCoverLetterHandler = setShowAICoverLetterPaywall
    }

    return (
      <SectionsListContainer>
        {showPowerStatementSection && (
          <PowerStatementSection {...{ sectionId: SectionNames.powerStatement }} />
        )}
        <ProfileSection {...{ sectionId: SectionNames.profile }} />
        {groups.map((group, groupIndex) => {
          const content = group.sections.map((section, sectionIndex) => (
            <SortableSection
              key={section.id}
              index={sectionIndex}
              isDragging={draggableId === section.sortId || draggableId === section.id}
              section={section}
              levels={levels}
              skills={skills}
              position={position}
              hideSkillLevel={hideSkillLevel}
              locale={locale}
              onRename={renameSection}
              onDelete={deleteSection}
              onAdd={this.handleCardAdd}
              isCardOpen={this.isCardOpen}
              onCardChange={updateCard}
              onCardDelete={deleteCard}
              onCardMove={moveCard}
              onCardToggle={this.handleCardToggle}
              template={resume.template}
              updateSimpleField={updateSimpleField}
            />
          ))

          if (!group.sortable) return <div key={groupIndex}>{content}</div>

          return (
            <DroppableSectionContainer
              key={groupIndex}
              updateBeforeSortStart={this.handleSortStart}
              onSortEnd={this.handleSectionMove}
              useWindowAsScrollContainer
              useDragHandle
            >
              {content}
            </DroppableSectionContainer>
          )
        })}
        {showGenerateAICVButton &&
          (config.features.resumeOptimizer ? (
            <GenerateAICVBannerNew onClickGenerate={generateCoverLetterHandler} />
          ) : (
            <GenerateAICVBanner onClickGenerate={generateCoverLetterHandler} />
          ))}
        <AdditionalSections
          sections={filteredSections}
          onAdd={sectionId =>
            this.handleCardAdd(sectionId, { scrollIntoViewport: true, shouldOpen: true })
          }
          onAddCustom={externalId => addCustomSection(externalId, { scrollIntoViewport: true })}
        />
      </SectionsListContainer>
    )
  }
}

const mapStateToProps = state => ({
  openedCard: editorSelectors.openedCard(state),
  aiClFreeAttempts: state.user.data.aiClFreeAttempts,
  isAPremiumUser: state.user.data.hasPremiumFeatures,
  currentUserDocuments: state.panel.documents,
  appLocale: state.application.configs.country.locale,
})

const mapDispatchToProps = {
  openCard: editorActions.openCard,
  setIsClickedOnAiCVBanner: editorActions.setIsClickedOnAiCVBanner,
  setShowAICoverLetterPaywall: coverLetterActions.setShowAICoverLetterPaywall,
  openLimitModal: uiActions.openLimitModal,
}

export default compose(withConfig, connect(mapStateToProps, mapDispatchToProps))(NewSectionsList)
