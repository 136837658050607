import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'
import { SliderArrowButtonThemeEnum, SliderArrowButtonSize } from './type'

interface Value {
  background: {
    default: string
    hover: string
    active: string
  }
  textColor: string
}

const colors: Record<SliderArrowButtonThemeEnum, Value> = {
  [SliderArrowButtonThemeEnum.gray]: {
    background: {
      default: Colors.Neutral15,
      hover: Colors.Neutral20,
      active: Colors.Neutral30,
    },
    textColor: Colors.Neutral50,
  },
  [SliderArrowButtonThemeEnum.blue]: {
    background: {
      default: Colors.Blue50,
      hover: Colors.Blue60,
      active: Colors.Blue70,
    },
    textColor: Colors.White,
  },
}

export const Container = styled.div<{
  $theme: SliderArrowButtonThemeEnum
  $size: SliderArrowButtonSize
  $disableBgColor: boolean
  $disable: boolean
}>`
  ${({ $theme, $size, $disableBgColor = false, $disable = false }) => {
    const color = colors[$theme]

    return css`
      ${$disableBgColor || $disable ? `` : `background-color: ${color.background.default};`}
      display: inline-flex;
      padding: 12px;
      border-radius: 100%;
      height: ${$size}px;
      width: ${$size}px;
      color: ${color.textColor};
      justify-content: center;
      align-items: center;

      ${!$disable && `cursor: pointer;`}

      & path {
        fill: currentColor;
      }

      &:hover {
        ${!$disable && `background-color: ${color.background.hover};`}
      }

      &:active {
        ${!$disable && `background-color: ${color.background.active};`}
      }

      ${Media.Phone`
        height: 40px;
        width: 40px;
      `}
    `
  }}
`
