import { Listbox } from '@headlessui/react'
import { SelectOption, SelectValue } from '../../types'
import * as Styled from './styles'

type Props<V> = {
  options: SelectOption<V>[]
  optionsListClassname?: string
}

export const DesktopOptions = <V extends SelectValue>({
  options,
  optionsListClassname,
}: Props<V>) => {
  return (
    <Listbox.Options
      as={
        optionsListClassname === 'templates-filter' ? Styled.OptionsWithRightBorder : Styled.Options
      }
    >
      <Styled.OptionsList className={optionsListClassname}>
        {options.map((option, index) => (
          <Listbox.Option as="div" key={option.id ?? 'empty'} value={option.id}>
            {({ active, selected }) => (
              <Styled.OptionContent
                isActive={active}
                isSelected={selected}
                isFirst={index === 0}
                isTemplateFilter={optionsListClassname === 'templates-filter'}
              >
                {option.name}
              </Styled.OptionContent>
            )}
          </Listbox.Option>
        ))}
      </Styled.OptionsList>
    </Listbox.Options>
  )
}
