import { useMemo } from 'react'
import { useI18n } from 'builder/hooks/useI18n'

import { behaviourType } from 'builder/modules/interview/types'
import { LoadingScreen } from '../../LoadingScreen/LoadingScreen'

export const TRANSLATION_PREFIX = 'builder.interview_prep_view.interview_player.loading_end_screen'
interface EndLoadingProps {
  userInterruptedInterview: boolean
  startPageType?: behaviourType
}
export const EndLoading = ({ userInterruptedInterview, startPageType }: EndLoadingProps) => {
  const { i18n } = useI18n()

  const texts = useMemo(() => {
    if (userInterruptedInterview) {
      return {
        title: 'stopped_interview',
        subtitle: 'stopped_interview_subtitle',
      }
    }

    if (startPageType) {
      return {
        title: 'generating_your_results',
        subtitle: 'generating_your_results_subtitle',
      }
    }

    return {
      title: 'congratulations',
      subtitle: 'congratulations_subtitle',
    }
  }, [userInterruptedInterview, startPageType])

  return (
    <div>
      <LoadingScreen
        content={{
          title: i18n.t(`${TRANSLATION_PREFIX}.${texts.title}`),
          content: i18n.t(`${TRANSLATION_PREFIX}.${texts.subtitle}`),
          customContentWidth: 500,
        }}
      />
    </div>
  )
}
