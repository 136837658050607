import styled, { css, keyframes } from 'styled-components'
import Button from 'builder/components/Button'
import Sizes from 'builder/styles/sizes'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'

const moveUp = keyframes`
  from { z-index: 1; margin: 0; opacity: 1; transform: translateY(100%); }
  to { z-index: 0; margin: 14px 14px 0 14px; opacity: 0.3; transform: translateY(0%); }
`

export const Collapse = styled.ul<{ margin: number; isCollapsed: boolean }>`
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${Sizes.XS};
  margin-bottom: ${props => props.margin && `${props.margin}px`};
  > li {
    &:not(:first-child) {
      transition: margin 0.3s ease, opacity 0.3s ease, padding 0.3s ease;
    }
  }

  ${p =>
    !p.isCollapsed &&
    css`
      li {
        &:first-child {
          z-index: 4;
        }
        &:not(:first-child) {
          animation: ${moveUp} 0.3s ease-in;
          top: 0;
          left: 0;
          max-height: 100%;
          position: absolute;
          width: -webkit-fill-available;
          * {
            opacity: 0;
          }
        }
        &:nth-child(2) {
          opacity: 0.64;
          z-index: 3;
          margin: 7px 7px 0 7px;
        }
        &:nth-child(n + 3) {
          opacity: 0.4;
          z-index: 2;
          margin: 14px 14px 0 14px;
        }
        &:nth-child(n + 4) {
          z-index: 1;
          box-shadow: none;
        }
      }
    `}
`

export const CollapseButton = styled(Button)`
  align-self: center;
  ${Typography.Caption}
  ${FontWeights.Medium}
  svg path {
    fill: none;
    stroke: ${Colors.Blue50};
  }
  margin-top: ${Sizes['2XS']};
  margin-bottom: ${Sizes['2XS']};
`
