import { createContext, useCallback } from 'react'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import { EnumerableResumeSectionType, Resume } from '@rio/types'
import { trackInternalEvent } from '@rio/tracking'
import { actions, selectors } from 'builder/modules/resumeEditor'
import { actions as renderingActions } from 'builder/modules/rendering'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { FORMATS } from 'builder/modules/constants'
import { generateRandomId } from 'builder/utils/generateRandomId'
import { matchMediaQueries } from 'builder/utils/matchMediaQueries'
import useEmptyStepIds from '../hooks/useEmptyStepIds'
import { trackStepVisit } from '../utils'
import { Card, WizardContextValue as Value } from '../types'

export const WizardContext = createContext<Value | undefined>(undefined)

export const WizardContextProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const emptyStepIds = useEmptyStepIds()
  const openedCard = useTypedSelector(selectors.openedCard)
  const { currentStep, resume } = useTypedSelector(state => ({
    currentStep: selectors.currentWizardStep(state),
    resume: selectors.resume(state),
  }))

  const updateStepState: Value['updateStepState'] = useCallback(
    payload => {
      dispatch(actions.updateWizardStep(payload))
    },
    [dispatch],
  )

  const renameSection: Value['renameSection'] = useCallback(
    ({ id, value }) => dispatch(actions.renameSection({ id, value, debounce: true })),
    [dispatch],
  )

  const isCardOpen: Value['isCardOpen'] = useCallback(
    (cardId, sectionId) =>
      !!openedCard && openedCard.id === cardId && openedCard.sectionId === sectionId,
    [openedCard],
  )

  const toggleCard: Value['toggleCard'] = useCallback(
    (cardId, sectionId) => {
      if (isCardOpen(cardId, sectionId)) {
        dispatch(actions.openCard(null))
      } else {
        dispatch(actions.openCard({ id: cardId, sectionId }))
      }
    },
    [isCardOpen, dispatch],
  )

  const addCard: Value['addCard'] = useCallback(
    (sectionId, options) => {
      const cardId = generateRandomId()
      const { scrollIntoViewport = false } = options || {}

      dispatch(
        actions.addCard({
          cardId,
          sectionName: sectionId,
          options: { scrollIntoViewport, shouldOpen: true },
        }),
      )
    },
    [dispatch],
  )

  const moveCard: Value['moveCard'] = useCallback(
    payload => dispatch(actions.moveCard(payload)),
    [dispatch],
  )

  const deleteCard: Value['deleteCard'] = useCallback(
    (cardId, sectionName, isCustom = false) => {
      dispatch(actions.deleteCard({ sectionName, cardId, isCustom }))
    },
    [dispatch],
  )

  const deleteAllCards: Value['deleteAllCards'] = useCallback(
    ({ sectionId }) => {
      if (!isArray(resume?.[sectionId as keyof Resume])) return
      // @ts-expect-error TODO fix TS error
      resume[sectionId].forEach((card: Card) => card.id && deleteCard(card.id, sectionId))
    },
    [deleteCard, resume],
  )

  const setCurrentStep: Value['setCurrentStep'] = useCallback(
    (stepId: string) => {
      if (stepId === currentStep.id) return
      // Remove empty cards when before leave the current step
      if (emptyStepIds.includes(currentStep.id)) deleteAllCards({ sectionId: currentStep.id })
      // Create an empty card if the next step doesn't have any cards.
      // Don't do that on mobiles to prevent flashing fullscreen modals.
      const section = resume?.[stepId as keyof Resume]
      const { isPhone } = matchMediaQueries()
      if (isArray(section) && isEmpty(section) && !isPhone) {
        addCard(stepId as EnumerableResumeSectionType)
      }
      // Update the step id in the application store
      dispatch(actions.setCurrentWizardStep(stepId))
      // Build steps funnel via ClickHouse
      trackStepVisit(stepId)
    },
    [currentStep.id, emptyStepIds, resume, dispatch, addCard, deleteAllCards],
  )

  const downloadPdf: Value['downloadPdf'] = useCallback(() => {
    if (resume?.id) {
      dispatch(renderingActions.download({ id: resume.id }))
      // Track in ClickHouse
      trackInternalEvent('click_download_resume_button', { format: FORMATS.pdf, source: 'wizard' })
    }
  }, [resume?.id, dispatch])

  const updateCard: Value['updateCard'] = useCallback(
    (sectionId, cardId, values, debounce, isCustom) => {
      dispatch(
        actions.updateCard({
          sectionId,
          cardId,
          values,
          debounce,
          isCustom,
        }),
      )
    },
    [dispatch],
  )

  if (!resume) return null

  const value = {
    resume,
    currentStep,
    setCurrentStep,
    updateStepState,
    updateCard,
    addCard,
    toggleCard,
    isCardOpen,
    deleteCard,
    deleteAllCards,
    moveCard,
    renameSection,
    downloadPdf,
  }

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
}
