import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'

import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import {
  selectors as renderingSelectors,
  actions as renderingActions,
} from 'builder/modules/rendering'
import { UserType } from 'builder/modules/user'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { AutoApplyUpsellModal as AutoApplyUpsellModalEnum } from 'builder/modules/init'

const MODAL_SHOWN_STORAGE_KEY = 'auto-apply-upsell-modal'

export const useAutoApplyUpsellModal = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const { features } = useFeaturesConfig()

  const wasAutoApplyModalShown = localStorage.getItem(MODAL_SHOWN_STORAGE_KEY)
  const showAutoApplyUpsellModal = useTypedSelector(renderingSelectors.showAutoApplyUpsellModal)

  const getPlanName = () => {
    return !user?.isTrialUser && user?.isPaidUser && !user?.isTopResumeUser
      ? '20_auto_appy_credits_promo'
      : '20_credits_autoapply'
  }

  const handleCloseModal = () => {
    localStorage.setItem(MODAL_SHOWN_STORAGE_KEY, 'true')
    dispatch(renderingActions.toggleAutoApplyUpsellModal(false))
  }

  const handleOpenModal = () => {
    trackInternalEvent('show_auto_apply_upsell_modal')
    dispatch(renderingActions.toggleAutoApplyUpsellModal(true))
  }

  const handlePayment = () => {
    trackInternalEvent('click_auto_apply_upsell_modal')
    goToBillingPlanPage({
      referrerPage: 'auto-apply',
      upgradeURL: '/app/billing/payment',
      onSuccessRedirect: '/app/auto-apply/start',
      plan: getPlanName(),
    })
  }

  const showModalAfterPayment = useCallback(() => {
    if (
      features?.autoApply &&
      user?.hasPremiumFeatures &&
      !user?.scopes?.autoApply &&
      !wasAutoApplyModalShown &&
      !user?.billingInfo.premiumEndsAt &&
      features?.autoAutoApplyNoFreeCredits !== AutoApplyUpsellModalEnum.A
    ) {
      trackInternalEvent('show_auto_apply_upsell_modal')
      dispatch(renderingActions.toggleAutoApplyUpsellModal(true))
    }
  }, [
    dispatch,
    user?.billingInfo.premiumEndsAt,
    user?.hasPremiumFeatures,
    user?.scopes?.autoApply,
    wasAutoApplyModalShown,
    features?.autoApply,
    features?.autoAutoApplyNoFreeCredits,
  ])

  useEffect(() => {
    showModalAfterPayment()
  }, [showAutoApplyUpsellModal, showModalAfterPayment])

  return {
    isOpen: showAutoApplyUpsellModal,
    isFreeUser: user?.billingInfo.userType === UserType.free,
    isPostPremiumUser: user?.billingInfo.userType === UserType.postPremium,
    isTrialUser: user?.isTrialUser,
    planName: getPlanName(),
    handleOpenModal,
    handleCloseModal,
    handlePayment,
  }
}
