import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Container = styled.div`
  position: absolute;
  top: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`

export const Content = styled.div`
  flex-shrink: 0;
  padding: 32px 32px 32px 0;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Colors.White};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  ${Media.Tablet`
    padding: 24px 24px 24px 0;
    padding-top: 100px;
  `}

  ${Media.Phone`
    padding: 16px 16px 16px 0;
    padding-top: 100px;
    display: flex;
    gap: unset;
  `}
`

export const ContainerPlaceholder = styled.div`
  background: linear-gradient(180deg, rgb(255 255 255 / 22%) -4%, ${Colors.White} 100%);
  margin-bottom: -120px;
`

export const Placeholder = styled.div`
  opacity: 0;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Media.Phone`
    display: flex;
    flex-direction: column;
  `}
`
export const Title = styled.div`
  white-space: break-spaces;
  ${Typography.S};
  ${FontWeights.DemiBold};
  color: ${Colors.Neutral90};

  ${Media.Tablet`
    margin-bottom: 40px;
  `}
`

export const Img = styled.img`
  width: 100%;
  height: auto;

  ${Media.Phone`
    margin-bottom: 40px;
  `}
`
export const Title2 = styled.div`
  ${Typography.Subhead};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const SectionTitle = styled.div`
  ${Typography.Caption};
  ${FontWeights.Medium};
  color: ${Colors.Neutral100};
`

export const SectionText = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral100};
`

export const TextContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
`
export const Classification = styled.div<{ $classificationColor: string }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${({ $classificationColor }) => $classificationColor};
  &::first-letter {
    text-transform: uppercase;
  }
`

export const Value = styled.div`
  ${Typography.L};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const ButtonContainer = styled.div`
  ${Media.Phone`
    display: flex;
    flex-direction: column;
  `}
`

export const CrownIcon = styled(Icon20.Crown)`
  color: ${Colors.White};
  height: 24px;
  width: 24px;
`
