import React, { useCallback, useRef, useState } from 'react'
import { ButtonSize } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { ClusteredJob, Job } from '../JobSearchResult/types'
import JobItem from '../JobItem'
import { Collapse, CollapseButton } from './styles'

interface Props {
  parentJob: ClusteredJob & { position?: number }
  activeJobId?: string
  setJobAsActive: (job: Job) => void
}

const JobGroup = ({ parentJob, activeJobId, setJobAsActive }: Props) => {
  const [isGroupOpen, setIsGroupOpen] = useState(false)
  const { isTablet } = useMediaQueries()
  const ref = useRef<HTMLUListElement | null>(null)

  const handleJobItemClick = useCallback(
    (job: Job) => {
      if (!isTablet || isGroupOpen) setJobAsActive(job)
    },
    [isGroupOpen, isTablet, setJobAsActive],
  )

  const smoothScrollIntoFirstJob = () => {
    if (ref?.current) {
      const yCoordinate = ref.current.getBoundingClientRect().top + window.scrollY - 250

      window.scrollTo({
        top: yCoordinate,
        behavior: 'smooth',
      })
    }
  }
  const handleCollapseClick = () => {
    setIsGroupOpen(false)
    smoothScrollIntoFirstJob()
  }
  if (parentJob.relatedJobs.length === 0) {
    return (
      <JobItem
        key={parentJob.id}
        isActive={parentJob.id === activeJobId}
        job={parentJob}
        onClick={() => setJobAsActive(parentJob)}
      />
    )
  }

  const jobs: Job[] = [parentJob, ...parentJob.relatedJobs]
  const margin = isGroupOpen ? 8 : jobs.length > 2 ? 32 : 16
  return (
    <Collapse
      ref={ref}
      margin={margin}
      isCollapsed={isGroupOpen}
      onClick={() => (!isGroupOpen ? setIsGroupOpen(!isGroupOpen) : null)}
    >
      {jobs.map(job => (
        <JobItem
          key={job.id}
          isActive={job.id === activeJobId}
          job={{ ...job, position: parentJob.position }}
          onClick={() => handleJobItemClick(job)}
        />
      ))}
      {isGroupOpen && (
        <CollapseButton size={ButtonSize.small} theme="secondary" onClick={handleCollapseClick}>
          <Icon24.ChevronUp />
          Collapse
        </CollapseButton>
      )}
    </Collapse>
  )
}

export default React.memo(JobGroup)
