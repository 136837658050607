import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import { Icon24 } from 'builder/components/Icon'
import Media from 'builder/styles/media'
import Typography, { FontWeights } from 'builder/styles/typography'
import { AutoApplyUpsellModal as Variant } from 'builder/modules/init'
import Spinner from 'builder/components/Spinner'
import Button from 'builder/components/Button'
import checkmark from './assets/checkmark.svg'
import spinner from './assets/spinner.png'
import whiteSpinner from './assets/white-spinner.png'

export const ModalSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  padding: 40px;

  ${Media.Tablet`
    width: 100%;
    padding: 32px 32px 24px;
  `}

  ${Media.Phone`
    width: 100%;
    padding: 24px;
  `}
`

export const ModalMediaSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 16px 16px 0;
  align-items: center;
  width: 50%;
  background-color: #f3f4f8;
  position: relative;

  ${Media.Tablet`
    width: 100%;
    height: 300px;
    border-radius: 16px;
  `}

  ${Media.Phone`
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    height: 300px;
  `}
`

export const CloseIcon = styled(Icon24.Close)`
  display: block;
  color: ${Colors.Neutral50};
  transition: color 0.3s;
  &:hover {
    color: ${Colors.Blue50};
  }
`

export const Close = styled.div`
  display: flex;
  justify-self: flex-end;
  right: 0;
  height: fit-content;
  width: fit-content;
  margin: 32px 32px 0 32px;
  background: ${Colors.Neutral5};
  border-radius: 32px;
  color: ${Colors.Neutral30};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${Colors.Blue10};
  }
  ${Media.Phone`
    margin: 20px;
  `}
`

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`

export const UpsellBadge = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${Colors.White};
  background: ${Colors.Indigo50};
  padding: 0px 8px;
  border-radius: 4px;
  width: fit-content;
`

export const UpsellHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`

export const UpsellTitle = styled.h2`
  ${Typography.L}
  ${FontWeights.DemiBold}
  color: ${Colors.Neutral90};
  width: 70%;

  ${Media.Phone`
    width: 80%;
  `}
`

export const UpsellDescription = styled.p`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};
`

export const UpsellFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 5px;

  ${Media.Tablet`
    margin: 0;
  `}

  ${Media.Phone`
    margin: 0;
    gap: 12px;
  `}
`

export const UpsellFeatureTitle = styled.h3`
  color: #303848;
  ${Typography.Tiny}
  ${FontWeights.DemiBold}
  text-transform: uppercase;
`

export const UpsellTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${Media.Tablet`
    gap: 24px;
  `}
`

export const CTACaption = styled.div`
  ${Typography.Caption}
  ${FontWeights.Regular}
  color: ${Colors.Neutral50};
  text-align: center;
  display: flex;
  justify-content: center;
`

export const BoldPrice = styled.span`
  color: ${Colors.Neutral90};
  ${Typography.Caption}
  ${FontWeights.Medium}
`

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  ${Media.Phone`
    background: ${Colors.White};
    padding: 16px 0 24px;
    position: sticky;
    bottom: 0;
  `}
`

export const ButtonContainer = styled.div`
  margin-top: 32px;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;

  ${Media.Phone`
    border-radius: 16px 16px 0px 0px;
  `}
`

export const SlashedPrice = styled.span`
  color: #92cdfc;
  text-decoration: line-through;
`

export const UpsellCardsContainer = styled.div`
  display: flex;
  gap: 8px;
`
export const UpsellCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 12px;
  padding: 16px 12px;
  text-align: center;
  background-color: ${Colors.Neutral5};
  ${Typography.Caption}
  ${FontWeights.Medium}
  flex: 1;
`

export const SubscriptionBanner = styled.div`
  border: 2px solid ${Colors.Blue50};
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  background-color: ${Colors.White};
`

export const BannerTitle = styled.div``

export const BannerInfo = styled.span`
  color: ${Colors.Neutral90};
  ${Typography.S}
  ${FontWeights.Medium}
`

export const BannerFrequency = styled.span`
  color: ${Colors.Neutral50};
  ${Typography.S}
`

export const BannerDescription = styled.div`
  color: ${Colors.Neutral90};
  margin-top: 8px;
  ${Typography.Body}
`

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  ${Media.Tablet`
    gap: 24px;
    margin-bottom: 24px;
  `}
`

export const PriceLoader = styled(Spinner)<{ type?: 'white' | 'default' }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ type }) => (type === 'white' ? whiteSpinner : spinner)});
`

export const UpgradeButton = styled(Button)`
  display: flex;
  gap: 5px;
  justify-content: center;
`

export const RootModalContainer = styled.div<{ variant: Variant }>`
  background-color: ${Colors.White};
  border-radius: 16px;
  display: flex;
  width: 992px;
  border-radius: 16px;

  ${Media.Tablet`
    flex-direction: column-reverse;
    width: 544px;
    height: unset;
  `}

  ${Media.Phone`
    height: 673px;
    width: 100%;
    border-radius: 16px 16px 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
  `}

  ${({ variant }) => {
    if (variant === Variant.C) {
      return css`
        ${Media.Tablet`
          flex-direction: column;
        `}

        ${Media.Phone`
          height: calc(100vh - 48px);
          overflow: scroll;
        `}

        ${ModalMediaSection} {
          padding: 40px 40px;

          ${Media.Tablet`
            height: 50%;
            padding: 24px 32px 32px;
          `}

          ${Media.Phone`
            height: 50%;
          `}
        }

        ${CloseButtonContainer} {
          padding: 30px;

          ${Media.Phone`
            padding: 0;
            top: 68px;
            right: 20px;
            position: fixed;
            width: unset;
            display: block;
          `}
        }

        ${Close} {
          ${Media.Phone`
            margin: 0;
          `}
        }

        ${UpsellTitle} {
          ${Media.Tablet`
            width: 100%;
          `}

          ${Media.Phone`
            width: 100%;
          `}
        }

        ${CTAContainer} {
          ${Media.Phone`
            background: linear-gradient(180deg, rgba(247, 249, 252, 0.00) 0%, #F7F9FC 10.8%);
            padding: 16px 20px 24px;
          `}
        }

        ${CTACaption} {
          color: ${Colors.Neutral90};
          ${Typography.Body};
        }

        ${Close} {
          margin: 0;
        }

        ${BoldPrice} {
          ${Typography.Body};
        }
      `
    }
  }}
`

export const UpsellFeature = styled.li<{ iconType: 'checkmark' | 'number'; index: number }>`
  color: ${Colors.Neutral80};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    right: 4px;
    top: 5px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background-image: url(${checkmark});
    background-size: contain;
    background-repeat: no-repeat;

    ${({ iconType, index }) =>
      iconType === 'number' &&
      css`
        content: '${index}';
        background-image: none;
        top: 0;
        right: 0;
        width: 28px;
        height: 28px;
        padding: 4px;
        border-radius: 50%;
        background: ${Colors.Blue10};
        color: ${Colors.Blue50};
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      `}
  }

  ${Media.Phone`
    font-size: 15px;
    line-height: 18px;
  `}
`

export const UpsellFeatures = styled.ul<{
  hasBackground: boolean
  gapSize: 'L' | 'M'
  applyPadding?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ gapSize }) => (gapSize === 'L' ? '16px' : '12px')};
  list-style: none;
  padding: ${({ applyPadding }) => (applyPadding ? '16px' : 0)};
  margin: 0px;
  background-color: ${({ hasBackground }) => hasBackground && Colors.Neutral5};
  border-radius: 8px;

  ${Media.Phone`
    gap: 12px;
  `}
`
