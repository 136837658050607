import { useConfig } from 'builder/hooks/useConfig'

export const useAutoTailoringAppConfig = () => {
  const config = useConfig()
  const isAutoTailoringEnabled = !!config?.features?.builderAutotailoring

  return {
    isAutoTailoringEnabled,
  }
}
