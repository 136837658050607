import { useI18n } from 'builder/hooks/useI18n'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useGetPlanPrice } from 'builder/hooks/useGetPlanPrice'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { AutoApplyUpsellModal as AutoApplyUpsellModalEnum } from 'builder/modules/init'

import ModalOverlay from '../ModalOverlay'
import { useAutoApplyUpsellModal } from './useAutoApplyUpsellModal'

import { AutoApplyCTA } from './elements/AutoApplyCTA'
import { UpsellFeaturesList } from './elements/UpsellFeaturesList'
import * as Styles from './styles'

import autoApplyUpsellVideo from './assets/auto-apply-animation.webm'
import bustIcon from './assets/bust.png'
import interviewIcon from './assets/interview.png'
import wandIcon from './assets/wand.png'

const TRANSLATION = 'builder.auto_apply.upsell_modal'
type TranslationKeys = {
  badge_text: string
  title: string
  description: string
  details_list: string[]
  feature_title: string
  features: string[]
  cta_text: string
  cta_caption_first: string
  cta_caption_second: string
  cta_caption_free_first: string
  cta_caption_free_second: string
  cta_caption_trial_first: string
  cta_caption_trial_second: string
  subscription_info_title: string
  subscription_info_frequency: string
  subscription_info_description: string
  upsell_cards: { card_1: string; card_2: string; card_3: string }
}

export const AutoApplyUpsellModal: React.FC = () => {
  const { i18n } = useI18n()
  const { features } = useFeaturesConfig()
  const { isPhone, isTablet } = useMediaQueries()
  const {
    isOpen,
    isFreeUser,
    isPostPremiumUser,
    isTrialUser,
    planName,
    handleCloseModal,
    handlePayment,
  } = useAutoApplyUpsellModal()
  const { recurringAmount, planAmount, isLoading } = useGetPlanPrice({
    plan: planName,
    fetchPrice: isOpen,
  })

  const getVariant = () => {
    const variant = features.autoAutoApplyNoFreeCredits
    if (variant === AutoApplyUpsellModalEnum.B) return variant
    return AutoApplyUpsellModalEnum.C
  }

  const translationKey = `${TRANSLATION}.${getVariant()}`
  const translation = i18n.t(translationKey) as unknown as TranslationKeys
  const isAnimationVariant = () => getVariant() === AutoApplyUpsellModalEnum.B
  const isPackageVariant = () => getVariant() === AutoApplyUpsellModalEnum.C

  const getCopiesBySubscription = () => {
    const copies = {
      first: translation.cta_caption_first,
      second: translation.cta_caption_second,
    }

    if (isFreeUser || isPostPremiumUser) {
      copies.first = translation.cta_caption_free_first
      copies.second = translation.cta_caption_free_second
    }

    if (isTrialUser) {
      copies.first = translation.cta_caption_trial_first
      copies.second = translation.cta_caption_trial_second
    }

    return copies
  }

  const getPriceButton = () => {
    if (isLoading) return <Styles.PriceLoader type="white" />
    if (isFreeUser || isPostPremiumUser) return recurringAmount()

    return (
      <>
        <Styles.SlashedPrice>{recurringAmount()}</Styles.SlashedPrice> {planAmount()}
      </>
    )
  }

  const getPriceCopy = () => {
    if (isLoading) return <Styles.PriceLoader />

    const { first, second } = getCopiesBySubscription()
    const isPaidUser = !(isFreeUser || isPostPremiumUser) && !isTrialUser

    return (
      <div>
        {first}{' '}
        <Styles.BoldPrice>
          {isPaidUser ? planAmount() : recurringAmount()}
          {translation.subscription_info_frequency}
        </Styles.BoldPrice>
        {second}
      </div>
    )
  }

  if (!isOpen) return null

  return (
    <ModalOverlay onClose={handleCloseModal}>
      <Styles.RootModalContainer variant={getVariant()}>
        <Styles.ModalSection>
          {isPackageVariant() && (isPhone || isTablet) && (
            <Styles.CloseButtonContainer>
              <Styles.Close onClick={handleCloseModal}>
                <Styles.CloseIcon />
              </Styles.Close>
            </Styles.CloseButtonContainer>
          )}
          <Styles.UpsellTextContainer>
            <Styles.UpsellHeaderContainer>
              <Styles.UpsellBadge>{translation.badge_text}</Styles.UpsellBadge>
              <Styles.UpsellTitle>{translation.title}</Styles.UpsellTitle>
              <Styles.UpsellDescription>{translation.description}</Styles.UpsellDescription>
            </Styles.UpsellHeaderContainer>

            {isPackageVariant() && (
              <Styles.UpsellCardsContainer>
                <Styles.UpsellCard>
                  <img width={29} height={41} src={wandIcon} />
                  <span>{translation.upsell_cards.card_1}</span>
                </Styles.UpsellCard>
                <Styles.UpsellCard>
                  <img width={43} height={40} src={bustIcon} />
                  <span>{translation.upsell_cards.card_2}</span>
                </Styles.UpsellCard>
                <Styles.UpsellCard>
                  <img width={45} height={40} src={interviewIcon} />
                  <span>{translation.upsell_cards.card_3}</span>
                </Styles.UpsellCard>
              </Styles.UpsellCardsContainer>
            )}

            <UpsellFeaturesList
              iconType={isAnimationVariant() ? 'checkmark' : 'number'}
              hasBackground={isAnimationVariant()}
              gapSize={isAnimationVariant() ? 'L' : 'M'}
              title={translation.feature_title}
              features={translation.features}
              applyPadding={isAnimationVariant()}
            />
          </Styles.UpsellTextContainer>

          {isAnimationVariant() && (
            <Styles.ButtonContainer>
              <AutoApplyCTA
                ctaText={translation.cta_text}
                ctaCaption={getPriceCopy()}
                ctaPrice={getPriceButton()}
                onClick={handlePayment}
              />
            </Styles.ButtonContainer>
          )}
        </Styles.ModalSection>
        <Styles.ModalMediaSection>
          {(isAnimationVariant() || (!isPhone && !isTablet)) && (
            <Styles.CloseButtonContainer>
              <Styles.Close onClick={handleCloseModal}>
                <Styles.CloseIcon />
              </Styles.Close>
            </Styles.CloseButtonContainer>
          )}

          {isPackageVariant() && (
            <Styles.FeaturesContainer>
              <Styles.SubscriptionBanner>
                <Styles.BannerTitle>
                  <Styles.BannerInfo>{translation.subscription_info_title}</Styles.BannerInfo>
                  <Styles.BannerFrequency>
                    {translation.subscription_info_frequency}
                  </Styles.BannerFrequency>
                </Styles.BannerTitle>
                <Styles.BannerDescription>
                  {translation.subscription_info_description}
                </Styles.BannerDescription>
              </Styles.SubscriptionBanner>

              <UpsellFeaturesList
                gapSize={isAnimationVariant() ? 'L' : 'M'}
                hasBackground={isAnimationVariant()}
                iconType="checkmark"
                features={translation.details_list}
              />
            </Styles.FeaturesContainer>
          )}

          {isPackageVariant() && !isPhone && (
            <AutoApplyCTA
              ctaText={translation.cta_text}
              ctaCaption={getPriceCopy()}
              ctaPrice={getPriceButton()}
              onClick={handlePayment}
            />
          )}

          {isAnimationVariant() && (
            <Styles.Video src={autoApplyUpsellVideo} autoPlay muted playsInline loop />
          )}
        </Styles.ModalMediaSection>
        {isPackageVariant() && isPhone && (
          <AutoApplyCTA
            ctaText={translation.cta_text}
            ctaCaption={getPriceCopy()}
            ctaPrice={getPriceButton()}
            onClick={handlePayment}
          />
        )}
      </Styles.RootModalContainer>
    </ModalOverlay>
  )
}
