import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import type { UserDocumentType } from '@rio/types'
import { useLocation } from 'react-router-dom'
import { DocumentTypes, FetchStatuses } from 'builder/modules/constants'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import {
  ActionAfterUpgradingPlan,
  PanelSessionStorageKeys,
  ResumeUpdateStatus,
  actions,
  selectors,
} from 'builder/modules/panel'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { actions as clEditorActions } from 'builder/modules/coverLetterEditor'
import {
  actions as resumeDistributionAction,
  selectors as resumeDistributionSelectors,
} from 'builder/modules/resumeDistribution'

import { useConfig } from 'builder/hooks/useConfig'
import {
  selectors as reviewSelectors,
  ResumeReviewScenarios,
  checkIfCritiqueExpired,
} from 'builder/modules/resumeReview'
import {
  selectors as critiqueSelectors,
  actions as critiqueActions,
} from 'builder/modules/resumeCritiqueReview'
import { useI18n } from 'builder/hooks/useI18n'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import DocumentsEmptyState from 'builder/components/DocumentsEmptyState'
import { ButtonSize } from 'builder/components/Button'
import Placeholder from 'builder/components/Panel/Placeholder'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { useDebouncedEffect } from 'builder/hooks/useDebouncedEffect'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { PREMIUM_TRIAL_ACTIVATED_KEY } from 'builder/modules/rendering'
import { useAutoTailoringAppConfig } from 'builder/hooks/useAutoTailoringAppConfig'
import DocumentsList from '../DocumentsList'
import ResumeReviewStatus from '../ResumeReviewStatus'
import { CreateDocumentPopup } from '../Document/CreateDocumentPopup/CreateDocumentPopup'
import * as PanelStyled from '../styles'
import CritiqueReview from '../CIOCritiqueReview/CritiqueReviewResumeSectionBanner'
import ResumeDistributionBanner from '../ResumeDistributionBanner'
import {
  DocumentsHeader,
  DocumentsTitle,
  DocumentsTabs,
  DocumentsTab,
  DocumentsHeaderButton,
  DocumentsHeaderButtonIcon,
} from './styles'

interface Props {
  type: UserDocumentType
  onTypeChange: (type: UserDocumentType, isAutoTailored: boolean) => void
  title: string
  isAutoTailored: boolean
  fromDashboard?: boolean
  newDashHeader?: boolean
}

export const DocumentsSection = ({
  type,
  onTypeChange,
  title,
  fromDashboard,
  newDashHeader,
  isAutoTailored,
}: Props) => {
  const [documentType, setDocumentType] = useState(type)
  const { i18n } = useI18n()
  const dispatch = useDispatch()
  const { isPhone } = useMediaQueries()
  const location = useLocation()
  const config = useConfig()
  const isSuperApp = config?.features.superApp
  const hostType = useWebsiteHost().getHost
  const critiques = useTypedSelector(reviewSelectors.critiques)
  const reviewScenario = useTypedSelector(reviewSelectors.scenario)
  const documentsStatus = useTypedSelector(selectors.documentsStatus)
  const tunerResumeId = useTypedSelector(critiqueSelectors.tunerResumeId)
  const list = useTypedSelector(state =>
    selectors.documentsByType(state, documentType as DocumentTypes, isAutoTailored),
  )
  const listTrDocuments = useTypedSelector(state =>
    selectors.documentsByType(state, DocumentTypes.trDocument, isAutoTailored),
  )
  const listAutoTailoredResumes = useTypedSelector(state =>
    selectors.documentsByType(state, DocumentTypes.resume, true),
  )
  const { status, resumeId } = useTypedSelector(critiqueSelectors.triggeredCritiqueStatus)
  const isCritiqueLoading = status !== null && list.some(item => item.id === resumeId)
  const resumeUpdateStatus = useTypedSelector(selectors.resumeUpdateStatus)
  const resumeDistributionCandidate = useTypedSelector(resumeDistributionSelectors.candidate)
  const [timerFinished, setTimerFinished] = useState(false)

  const features = useFeaturesConfig()
  const isInternationalization = features?.features?.dashboardV2
  const { isAutoTailoringEnabled } = useAutoTailoringAppConfig()

  const scrollToComponent = () => {
    const targetElement = document.getElementById('documents-section')
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect()
      window.scrollTo({
        top: rect.top + window.pageYOffset,
        behavior: 'smooth',
      })
    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const aiRefreshParam = queryParams.get('ai-refresh')

    if (aiRefreshParam === 'true') {
      const timer = setTimeout(() => {
        setTimerFinished(true)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [location.search])

  useEffect(() => {
    if (timerFinished) {
      scrollToComponent()
    }
  }, [timerFinished])

  const [popupOpen, setPopupOpen] = useState(false)
  const isTypeResume = documentType === 'resume'
  const containerRef = useRef<HTMLDivElement>(null)
  const hideCoverLetter =
    config?.features.isWidget && config?.features.widgetFeatures.coverLetter !== true
  const selectedIndex = useTypedSelector(selectors.activeTabId)

  const componentRef = useCallback((node: HTMLElement | null) => {
    if (node !== null && sessionStorage.getItem(PREMIUM_TRIAL_ACTIVATED_KEY)) {
      setTimeout(
        () =>
          window.scrollTo({
            top: node.offsetTop,
            behavior: 'smooth',
          }),
        800,
      )
      sessionStorage.removeItem(PREMIUM_TRIAL_ACTIVATED_KEY)
    }
  }, [])

  useEffect(() => {
    if (type === 'tr_document' && listTrDocuments.length === 0) {
      setDocumentType('resume')
    } else {
      setDocumentType(type)
    }
  }, [hostType, list.length, documentType, listTrDocuments.length, type])

  useEffect(() => {
    if (status === 'pending' || !status) {
      dispatch(critiqueActions.fetchCritiqueReviewStatus({ resumeId: tunerResumeId }))
    }
  }, [dispatch, status, tunerResumeId])

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  useDebouncedEffect(
    () => {
      setIsButtonDisabled(resumeUpdateStatus === ResumeUpdateStatus.Started)
    },
    50,
    [resumeUpdateStatus],
  )

  useEffect(() => {
    // Remove local storage values related to rich text editor
    localStorage.removeItem('SPELL_CHECKER_INCORRECT_WORDS')
    localStorage.removeItem('EDITORS_WITH_SPELL_CHECK')
  }, [])

  useEffect(() => {
    dispatch(actions.fetchDocuments())
    dispatch(clEditorActions.resetAIGenerationState())
    dispatch(editorActions.resetJobPostingAPIData())
    dispatch(resumeDistributionAction.fetchCandidate())
  }, [dispatch])

  useEffect(() => {
    if (
      isAutoTailored &&
      documentsStatus === FetchStatuses.loaded &&
      !listAutoTailoredResumes.length
    ) {
      onTypeChange(type, false)
    }
  }, [isAutoTailored, listAutoTailoredResumes.length, documentsStatus, onTypeChange, type])

  useEffect(() => {
    const actionAfterUpgradingPlan = sessionStorage.getItem(
      PanelSessionStorageKeys.ACTION_AFTER_UPGRADING_PLAN,
    ) as ActionAfterUpgradingPlan | undefined
    const documentTypeAfterUpgradingPlan = sessionStorage.getItem(
      PanelSessionStorageKeys.DOCUMENT_TYPE,
    ) as UserDocumentType | undefined

    if (actionAfterUpgradingPlan === 'createDocument') {
      onTypeChange(documentTypeAfterUpgradingPlan as UserDocumentType, isAutoTailored)
      dispatch(actions.createDocument({ type: documentTypeAfterUpgradingPlan as UserDocumentType }))
    } else if (actionAfterUpgradingPlan === 'copyDocument') {
      onTypeChange(documentTypeAfterUpgradingPlan as UserDocumentType, isAutoTailored)
      const documentToCopyId = sessionStorage.getItem(PanelSessionStorageKeys.DOCUMENT_TO_COPY_ID)
      dispatch(
        actions.copyDocument({
          id: Number(documentToCopyId),
          type: documentTypeAfterUpgradingPlan as UserDocumentType,
        }),
      )
    }

    sessionStorage.removeItem(PanelSessionStorageKeys.ACTION_AFTER_UPGRADING_PLAN)
    sessionStorage.removeItem(PanelSessionStorageKeys.DOCUMENT_TYPE)
    sessionStorage.removeItem(PanelSessionStorageKeys.DOCUMENT_TO_COPY_ID)
  }, [dispatch, onTypeChange, isAutoTailored])

  const handleCreate = useCallback(() => {
    dispatch(actions.createDocument({ type: documentType }))
    if (documentType !== 'tr_document') trackInternalEvent(`create_${documentType}`)
  }, [dispatch, documentType])

  const handleClose = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setPopupOpen(false)
    },
    [setPopupOpen],
  )

  const showPopup = isPhone && popupOpen && list.length > 0
  useClickOutside(containerRef, showPopup ? handleClose : () => {})

  const isCritiqueExpired = useMemo(() => {
    if (!critiques[0]) return false

    return checkIfCritiqueExpired(critiques[0].createdAt)
  }, [critiques])

  if (documentsStatus !== FetchStatuses.loaded) return <Placeholder />

  const documentTabsData = [
    ...Object.values(DocumentTypes)
      .filter(t => t !== DocumentTypes.coverLetter || !hideCoverLetter)
      .map(t => ({
        documentType: t,
        isAutoTailored: false,
      })),
    ...(isAutoTailoringEnabled && listAutoTailoredResumes.length
      ? [
          {
            documentType: DocumentTypes.resume,
            isAutoTailored: true,
          },
        ]
      : []),
  ]

  if (documentTabsData.length === 1) {
    title = i18n.t('builder.navigation.items.documents.only_resume_title')
  }

  const handleButtonClick = () => {
    if (isTypeResume && isPhone && list.length > 0) {
      setPopupOpen(prevState => !prevState)
    } else {
      handleCreate()
    }
  }

  if (showPopup) {
    trackInternalEvent('click_new_resume_card')
  }

  const tabs = documentTabsData.map(tab => {
    const key = `${tab.isAutoTailored ? 'auto_tailored_' : ''}${tab.documentType}`

    if (tab.documentType !== 'tr_document' || listTrDocuments.length > 0) {
      return (
        <DocumentsTab
          key={key}
          isActive={tab.documentType === documentType && tab.isAutoTailored === isAutoTailored}
          onClick={() => onTypeChange(tab.documentType, tab.isAutoTailored)}
        >
          {i18n.t(`builder.dashboard.${key}s`)}
        </DocumentsTab>
      )
    } else {
      return <Fragment key={key} />
    }
  })

  return (
    <PanelStyled.Section selectedIndex={selectedIndex} isPhone={isPhone} ref={componentRef}>
      <DocumentsHeader isNewDashHeader={newDashHeader}>
        <DocumentsTitle isInternationalization={isInternationalization} id="documents-section">
          {title}
        </DocumentsTitle>
        <DocumentsTabs>{tabs}</DocumentsTabs>
        {status === 'pending' &&
          !(reviewScenario === ResumeReviewScenarios.embedded && critiques.length > 0) &&
          !resumeDistributionCandidate && <ResumeDistributionBanner />}
        {isSuperApp
          ? isCritiqueLoading && documentType === 'resume' && <CritiqueReview isReviewPage={true} />
          : reviewScenario === ResumeReviewScenarios.embedded &&
            critiques.length > 0 &&
            !isCritiqueExpired && <ResumeReviewStatus critique={critiques[0]} />}
        {documentType !== 'tr_document' && !isAutoTailored && (
          <DocumentsHeaderButton
            size={isPhone ? ButtonSize.default : ButtonSize.small}
            onClick={handleButtonClick}
            isDisabled={isButtonDisabled}
          >
            <DocumentsHeaderButtonIcon />
            {i18n.t(`builder.dashboard.${isPhone ? `create_new_${documentType}` : 'create_new'}`)}
          </DocumentsHeaderButton>
        )}
        {showPopup && documentType !== 'tr_document' && (
          <CreateDocumentPopup type="resume" ref={containerRef} />
        )}
      </DocumentsHeader>

      {list.length > 0 ? (
        <DocumentsList
          key={documentType}
          type={documentType}
          documents={list}
          critiqueReviewLoaderVisibility={isCritiqueLoading}
          fromDashboard={fromDashboard}
          isAutoTailored={isAutoTailored}
        />
      ) : (
        <DocumentsEmptyState
          type={documentType}
          onClick={handleCreate}
          isButtonDisabled={isButtonDisabled}
          isAutoTailored={isAutoTailored}
        />
      )}
    </PanelStyled.Section>
  )
}
