import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div`
  padding: 32px;

  background: ${Colors.White};
  border-radius: 26px;

  gap: 64px;
  display: flex;
  flex-direction: column;

  ${Media.Tablet`
    padding: 24px;
  `}

  ${Media.Phone`
    padding: 16px;
    gap: 48px;
  `}
`
export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  gap: 64px;

  ${Media.Phone`
    gap: 48px;
  `}
`
export const HeaderContainer = styled.div`
  display: flex;
  gap: 24px;
`

export const VideoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Section = styled.div`
  background: ${Colors.White};
  display: flex;
  flex: 1;
  width: 100%;
  ${Media.Phone`
    flex-direction: column;
  `}
`

export const BadgeContainer = styled.div`
  flex: 1;

  ${Media.Phone`
    margin-bottom: 24px;
  `}
`

export const SectionTextContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  flex: 1;

  ${Media.Phone`
    gap: 16px;
  `}
`

export const SectionTextsContainer = styled.div``

export const SectionTitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Medium};
  color: ${Colors.Neutral100};
`

export const SectionSubtitle = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
`
