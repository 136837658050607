import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Button from '../Button'
import Spinner from '../Spinner'
import spinner from './assets/spinner.png'

export const BillingFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
`

export const PlanListContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`

export const TopBadgeTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: -10px;
`

export const TopBadgeText = styled.div`
  display: flex;
  padding: var(--3XS, 4px) var(--S, 16px);
  justify-content: center;
  align-items: center;
  border-radius: var(--2XS, 8px);
  background: var(--green-10, #e7f4ed);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: var(--green-50, #339d5d);
  font-family: 'TT Commons';
`

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0.8em;
  border: 2px solid ${Colors.Blue50};
  padding: 1em;
`

export const PlanPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
`

export const PlanName = styled.h3`
  font-size: 1em;
  font-weight: 600;
`

export const PlanPrice = styled.h3`
  font-size: 1em;
  font-weight: 600;
`

export const PlanPeriod = styled.span`
  font-size: 0.8em;
  color: ${Colors.Neutral50};
`

export const Disclaimer = styled.span`
  color: ${Colors.Neutral50};
  font-size: 0.8em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
`

export const WeAcceptLabel = styled.div`
  color: var(--neutral-50, #828ba2);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-width: 71px;
`

export const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 12px 0;
  align-items: baseline;
`

export const PaymentMethodIconsContainer = styled.div`
  grid-gap: var(--XS, 12px);
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  img {
    max-height: 24px;
  }
`

export const CTAPrimary = styled(Button)`
  width: 100%;
`

export const PriceLoader = styled(Spinner)`
  width: 24px;
  height: 24px;
  background-image: url(${spinner});
`
