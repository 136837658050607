import { useRef, useState } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import { Interview, InterviewAnswerFeedback } from 'builder/modules/interview/types'
import { useGetLowestPrice } from 'builder/views/Interview/hooks/useGetLowestPrice'
import { ShowStyledHTML } from '../ShowStyledHTML/ShowStyledHTML'
import { Container, Body, Title, HeaderContainer } from './styles'
import { Paywall } from './components/Paywall/Paywall'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.ideal_answer_section`

interface Props {
  feedback: InterviewAnswerFeedback | undefined
  interview: Interview | undefined
  enablePaywall: boolean
}

export const IdealAnswerSection = ({ feedback, interview, enablePaywall }: Props) => {
  const { i18n } = useI18n()

  const [paywallHeight, setPaywallHeight] = useState<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const isLoading = !feedback?.ideal_answer
  const user = useUser()
  const isFreePlan = !user?.hasPremiumFeatures
  const { isPhone } = useMediaQueries()
  const { data: price } = useGetLowestPrice()
  return (
    <Container $height={paywallHeight} ref={containerRef}>
      <HeaderContainer>
        {isLoading ? (
          <Skeleton width={209} height={20} />
        ) : (
          <Title $hasPaywall={enablePaywall}>{i18n.t(`${TRANSLATION_PREFIX}.ideal_answer`)}</Title>
        )}
        {!isPhone && (
          <>
            {isLoading || !interview ? (
              <Skeleton width={331} height={20} />
            ) : (
              <Body $hasPaywall={enablePaywall}>
                {i18n.t(`${TRANSLATION_PREFIX}.future_employees_says`, {
                  companyName: interview.company_name || '',
                })}
              </Body>
            )}
          </>
        )}
      </HeaderContainer>
      <Body>
        {isLoading ? (
          <div>
            <Skeleton width={781} height={20} />
            <Skeleton width={781} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={576} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={807} height={20} style={{ marginTop: '28px' }} />
            <Skeleton width={807} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={807} height={20} style={{ marginTop: '4px' }} />
            <Skeleton width={782} height={20} style={{ marginTop: '4px' }} />
          </div>
        ) : (
          <ShowStyledHTML dangerouslySetInnerHTML={{ __html: feedback.ideal_answer }} />
        )}
      </Body>
      {enablePaywall && !isLoading && isFreePlan && (
        <Paywall
          feedback={feedback}
          interview={interview}
          onReady={height => height && setPaywallHeight(height)}
          price={price}
        />
      )}
    </Container>
  )
}
