import { memo } from 'react'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import {
  Container,
  Header,
  Company,
  Position,
  Metas,
  HeaderContainer,
  MetasAutoApply,
} from './styles'

export const JobDetailsSkeleton = memo(() => (
  <Container>
    <HeaderContainer>
      <Skeleton width={48} height={48} />
      <Header>
        <Company>
          <Skeleton width={200} height={24} />
        </Company>
        <Position>
          <Skeleton width={300} height={24} />
        </Position>
      </Header>
    </HeaderContainer>
    <Metas>
      <Skeleton width={129} height={20} />
      <Skeleton width={129} height={20} />
      <Skeleton width={129} height={20} />
    </Metas>
    <MetasAutoApply>
      <Skeleton width={200} height={40} />
      <Skeleton width={200} height={40} />
    </MetasAutoApply>

    <Skeleton height={500} />
  </Container>
))
