import { useEffect, useRef, useState } from 'react'
import { trackInternalEvent } from '@rio/tracking'
import { useI18n } from 'builder/hooks/useI18n'
import Button, { ButtonTheme } from 'builder/components/Button'
import { goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { InterviewAnswerFeedback, Interview } from 'builder/modules/interview/types'
import { IdealAnswerSection } from '../../IdealAnswerSection'
import {
  ButtonContainer,
  Container,
  ContainerPlaceholder,
  Content,
  CrownIcon,
  Img,
  LeftContainer,
  Placeholder,
  RightContainer,
  SectionText,
  SectionTitle,
  TextContainer,
  Title,
  Title2,
} from './styles'
import CardSrc from './cards.png'
export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.paywall`
interface Props {
  feedback: InterviewAnswerFeedback | undefined
  interview: Interview | undefined
  onReady: (height: number | undefined) => void
  price: string | undefined
}

export const Paywall = ({ feedback, interview, onReady, price }: Props) => {
  const { i18n } = useI18n()
  const contentRef = useRef<HTMLDivElement | null>(null)
  const { isPhone, isAboveTablet, isTablet } = useMediaQueries()
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      onReady(contentRef.current?.scrollHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  useEffect(() => {
    if (!contentRef.current) {
      return
    }
    onReady(contentRef.current.scrollHeight)
  }, [feedback?.ideal_answer, imageLoaded])
  return (
    <Container ref={contentRef}>
      <ContainerPlaceholder>
        <Placeholder>
          <IdealAnswerSection feedback={feedback} interview={interview} enablePaywall={false} />
        </Placeholder>
      </ContainerPlaceholder>
      <Content>
        <LeftContainer>
          {((isPhone && isTablet) || isAboveTablet) && (
            <Title>{i18n.t(`${TRANSLATION_PREFIX}.title`)}</Title>
          )}
          <Img alt="cards" onLoad={handleImageLoad} onError={handleImageLoad} src={CardSrc} />
        </LeftContainer>

        <RightContainer>
          <div>
            {!isPhone && isTablet && <Title>{i18n.t(`${TRANSLATION_PREFIX}.title`)}</Title>}
            <Title2>{i18n.t(`${TRANSLATION_PREFIX}.title2`)}</Title2>
            <TextContainer>
              <div>
                <SectionTitle>{i18n.t(`${TRANSLATION_PREFIX}.section1`)}</SectionTitle>
                <SectionText>{i18n.t(`${TRANSLATION_PREFIX}.section1_text`)}</SectionText>
              </div>

              <div>
                <SectionTitle>{i18n.t(`${TRANSLATION_PREFIX}.section2`)}</SectionTitle>
                <SectionText>{i18n.t(`${TRANSLATION_PREFIX}.section2_text`)}</SectionText>
              </div>

              <div>
                <SectionTitle>{i18n.t(`${TRANSLATION_PREFIX}.section3`)}</SectionTitle>
                <SectionText>{i18n.t(`${TRANSLATION_PREFIX}.section3_text`)}</SectionText>
              </div>
            </TextContainer>
          </div>
          <ButtonContainer>
            <Button
              theme={ButtonTheme.alternative}
              onClick={() => {
                goToBillingPlanPage({
                  referrerPage: 'interviewPrep',
                  onSuccessRedirect: window.location.pathname,
                })
                trackInternalEvent('click_upgrade_paywall', {
                  label: 'interview_prep',
                  parameter: 'feedback',
                })
              }}
            >
              <CrownIcon />
              {price
                ? i18n.t(`${TRANSLATION_PREFIX}.unlock_button_with_price`, { price })
                : i18n.t(`${TRANSLATION_PREFIX}.unlock_button`)}
            </Button>
          </ButtonContainer>
        </RightContainer>
      </Content>
    </Container>
  )
}
