export const Unicorn = () => (
  <svg fill="none" height="40" width="40" xmlns="http://www.w3.org/2000/svg">
    <g stroke="white" strokeLinejoin="round" strokeWidth="2">
      <path d="m8 32h-5s-.5-14 5.2-20 8.3-6.5 13.8-7l2 5" />
      <path
        d="m25 9.5 8.521-7.1a.2.2 0 0 1 .322.203l-2.843 10.897m0 0 6 9.5c-1 2.5-4 5-6 5-3.572 0-5.672-3-9-3-2 0-3.5-2-4-4"
        strokeLinecap="round"
      />
      <path d="m12.5 15.5c-4.5 5.5-4.5 19.5-4.5 19.5h18s-1.5-3.5-2-9" strokeLinecap="round" />
    </g>
    <path
      clipRule="evenodd"
      d="m25 9.5c-1.5 1-3 .5-3 .5s-3.5-3-6-6c-2 1-5 6 0 9"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="m25 9.5c-1.5 1-3 .5-3 .5s-3.5-3-6-6c-2 1-5 6 0 9m11 6.082c0-1-.923-1.666-1.5-2-.577-.333-1.5-.5-2.5 0"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)
