import Colors from 'builder/styles/colors'
import { Classification, ClassificationEnum } from './components/CardSection/components/Card/Card'

const positiveColor = Colors.Green40
const negativeColor = Colors.Orange50

// TODO make hardcoded string comes from the translations
export function parseToneOfVoiceValue(toneOfVoice?: string): Classification {
  switch (toneOfVoice) {
    case 'confident':
    case 'positive':
    case 'interested':
    case 'happy':
    case 'friendly':
      return {
        text: toneOfVoice,
        value: '10/10',
        color: positiveColor,
        classification: ClassificationEnum.positive,
      }
    case 'unconfident':
    case 'negative':
    case 'uninterested':
    case 'unhappy':
    case 'unfriendly':
    case 'insecure':
    case 'detached':
      return {
        text: toneOfVoice,
        value: '2/10',
        color: negativeColor,
        classification: ClassificationEnum.negative,
      }
    default:
      return {
        text: 'negative',
        value: '2/10',
        color: negativeColor,
        classification: ClassificationEnum.negative,
      }
  }
}

export function parseWordPerMinute(wordPerMinute = 0): Classification {
  if (wordPerMinute >= 160) {
    return {
      text: 'Fast',
      value: wordPerMinute,
      color: positiveColor,
      classification: ClassificationEnum.positive,
    }
  }

  if (wordPerMinute < 160 && wordPerMinute >= 120) {
    return {
      text: 'Normal',
      value: wordPerMinute,
      color: positiveColor,
      classification: ClassificationEnum.positive,
    }
  }

  return {
    text: 'Slow',
    value: wordPerMinute,
    color: negativeColor,
    classification: ClassificationEnum.negative,
  }
}

export function parseFillerWords(fillerWords = 5): Classification {
  if (fillerWords === 0) {
    return {
      text: 'Very few',
      value: fillerWords,
      color: positiveColor,
      classification: ClassificationEnum.positive,
    }
  }

  if (fillerWords <= 10) {
    return {
      text: 'Normal',
      value: fillerWords,
      color: positiveColor,
      classification: ClassificationEnum.positive,
    }
  }
  // TODO check this value
  return {
    text: 'Frequent',
    value: fillerWords,
    color: negativeColor,
    classification: ClassificationEnum.negative,
  }
}

export function parsePauses(pauses = 0): Classification {
  if (pauses === 0) {
    return {
      text: 'Very few',
      value: pauses,
      color: positiveColor,
      classification: ClassificationEnum.positive,
    }
  }

  if (pauses <= 5) {
    return {
      text: 'Normal',
      value: pauses,
      color: positiveColor,
      classification: ClassificationEnum.positive,
    }
  }

  return {
    text: 'Frequent',
    value: pauses,
    color: negativeColor,
    classification: ClassificationEnum.negative,
  }
}

export function parsePercentageTitle(score: string) {
  switch (score) {
    case 'perfect':
      return '90'
    case 'good':
      return '65'
    case 'average':
      return '45'
    default:
      console.error('score without value setup', { score })
      return '35'
  }
}
