import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 32px;
  gap: 24px;
  border-radius: 20px;
  background: ${Colors.White};

  ${Media.Tablet`
    padding: 24px;
  `}

  ${Media.Phone`
    flex-direction: column;
    padding: 16px;
    gap: 48px;
  `}
`

export const Title = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LinearProgressContainer = styled.div`
  margin-top: 70px;
  display: flex;

  ${Media.Phone`
    margin-top: 48px;
  `}
`
export const Duration = styled.div`
  ${Typography.L};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`

export const PromisingStart = styled.div`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${Colors.Green40};
`
