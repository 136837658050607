import { ResumeWorkExperience } from '@rio/types'
import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import { clampNumber } from 'builder/utils/clampNumber'
import { convertHtmlToText } from 'builder/utils/convertHtmlToText'
import { openCard } from '../utils/openCard'
import { CheckFn } from '../types'

const isValidCard = ({ description }: ResumeWorkExperience): boolean => {
  return typeof description === 'string' && convertHtmlToText(description).length > 0
}

export const checkWork: CheckFn = ({ workExperiences: cards }) => {
  // Find cards having a description
  const validCards = cards.filter(isValidCard)

  // The section is valid if all work experience cards have description.
  // But we do not force students to create work experience cards (no cards is also valid).
  const progress = cards.length ? clampNumber(validCards.length / cards.length) : 1

  // Format validation error
  return {
    sectionId: SectionNames.workExperiences,
    progress,
    id: 'no_work_description',
    title: i18n.t('builder.resume_tuner.tips.no_work_description.title'),
    description: i18n.t('builder.resume_tuner.tips.no_work_description.description'),
    subject: i18n.t('builder.resume_tuner.tips.no_work_description.subject'),
    counter: `${validCards.length}/${cards.length}`,
    label: i18n.t('builder.resume_tuner.issues.no_work_description', {
      valid: validCards.length,
      total: cards.length,
    }),
    action: () => {
      const invalidCard = cards.find(card => isValidCard(card) === false)
      if (invalidCard) openCard(invalidCard, SectionNames.workExperiences)
    },
    actionText: i18n.t('builder.resume_tuner.tips.no_work_description.action_text'),
  }
}
