import { CTACaption, CTAContainer, UpgradeButton } from '../styles'

type Props = {
  ctaText: string
  ctaCaption: string | React.ReactElement
  ctaPrice?: string | React.ReactElement
  onClick?: () => void
}

export const AutoApplyCTA: React.FC<Props> = ({ onClick, ctaText, ctaCaption, ctaPrice }) => {
  return (
    <CTAContainer>
      <UpgradeButton onClick={onClick}>
        {ctaText} {ctaPrice}
      </UpgradeButton>
      <CTACaption>{ctaCaption}</CTACaption>
    </CTAContainer>
  )
}
