import { useMemo } from 'react'
import { stringifyUrl } from 'query-string'
import { useLocation } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'

import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useGetPlanPrice } from 'builder/hooks/useGetPlanPrice'

import { BillingForm } from '../Billing/BillingForm'
import { calculateDaysDifference } from '../Navigation/components/PremiumTracker/utils'
import dashedClockIcon from './assets/dashedClockIcon.svg'
import interviewIcon from './assets/interviewIcon.svg'
import usersIcon from './assets/usersIcon.svg'
import {
  Close,
  CloseButtonContainer,
  CloseIcon,
  ModalSection,
  Overlay,
  RootModalContainer,
  UpsellFeature,
  UpsellFeatures,
  UpsellFeaturesContainer,
  UpsellHeaderContainer,
  UpsellTitle,
} from './styles'
import { useResumeDistributionUpsellModal } from './useResumeDistributionUpsellModal'

export const ResumeDistributionUpsellModal = () => {
  const user = useUser()
  const { i18n } = useI18n()
  const location = useLocation()
  const { isOpen, handleModalClose } = useResumeDistributionUpsellModal()

  const daysLeftPremium = useMemo(() => {
    return user?.billingInfo?.premiumEndsAt
      ? calculateDaysDifference(user?.billingInfo?.premiumEndsAt) + 1
      : 0
  }, [user?.billingInfo.premiumEndsAt])

  const getPlanName = () => {
    return !user?.isTrialUser && user?.isPaidUser && !user?.isTopResumeUser
      ? '20_auto_appy_credits_promo'
      : '20_credits_autoapply'
  }

  const { recurringAmount, planAmount, isLoading } = useGetPlanPrice({
    plan: getPlanName(),
    fetchPrice: isOpen,
  })

  const redirectToPayments = () => {
    if (isLoading) return
    trackInternalEvent('auto_apply_plan_credits_exceeded')
    trackInternalEvent('show_upsell_payment_page')

    const isFromDashboard = location.pathname === '/'
    const path = isFromDashboard
      ? '/app'
      : '/app/job-search?view=recommendation&show_credit_modal=true'

    const plansPageUrl = stringifyUrl({
      url: '/app/billing/payment',
      query: { plan: getPlanName(), on_success_redirect: path },
    })

    window.location.assign(plansPageUrl)
  }

  if (!isOpen) return null

  return (
    <Overlay>
      <RootModalContainer>
        <ModalSection>
          <CloseButtonContainer>
            <Close onClick={handleModalClose}>
              <CloseIcon />
            </Close>
          </CloseButtonContainer>

          <UpsellHeaderContainer>
            <UpsellTitle>{i18n.t('builder.resume_distribution.upsell_modal.title')}</UpsellTitle>
          </UpsellHeaderContainer>

          <UpsellFeaturesContainer>
            <UpsellFeatures>
              <UpsellFeature iconUrl="/assets/media/Featured405bea8f5181edad4e9d.svg">
                {i18n.t('builder.resume_distribution.upsell_modal.features.job_applications')}
              </UpsellFeature>

              <UpsellFeature iconUrl="/assets/media/jobc49a418ea0ce514a2fd8.svg">
                {i18n.t('builder.resume_distribution.upsell_modal.features.weekly_jobs_shortlist')}
              </UpsellFeature>

              <UpsellFeature iconUrl={usersIcon}>
                {i18n.t('builder.resume_distribution.upsell_modal.features.send_resume')}
              </UpsellFeature>

              <UpsellFeature iconUrl={interviewIcon}>
                {i18n.t('builder.resume_distribution.upsell_modal.features.interview_preparation')}
              </UpsellFeature>

              {daysLeftPremium > 0 && (
                <UpsellFeature iconUrl={dashedClockIcon}>
                  {i18n.t('builder.resume_distribution.upsell_modal.features.do_not_lose_access', {
                    count: daysLeftPremium,
                  })}
                </UpsellFeature>
              )}
            </UpsellFeatures>
          </UpsellFeaturesContainer>

          <BillingForm
            plans={[
              {
                planPrice: user?.scopes.chargebeeCustomer ? planAmount() : recurringAmount(),
                planName: i18n.t('builder.resume_distribution.upsell_modal.plan.title'),
                planPeriod: i18n.t('builder.resume_distribution.upsell_modal.plan.period'),
                isLoading,
              },
            ]}
            topBadgeText={i18n.t(
              `builder.resume_distribution.upsell_modal.top_green_badge${
                user?.billingInfo?.onTrial ? '_trial' : ''
              }`,
            )}
            shouldShowTopBadge={user?.isPaidUser || false}
            onCTAClick={redirectToPayments}
            ctaLabel={i18n.t('builder.resume_distribution.upsell_modal.cta_label')}
            disclaimer={i18n.t('builder.resume_distribution.upsell_modal.disclaimer')}
          />
        </ModalSection>
      </RootModalContainer>
    </Overlay>
  )
}
