import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import Media from 'builder/styles/media'

export const Container = styled.div<{ $height: number | null }>`
  padding: 32px;

  background: ${Colors.White};
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  gap: 100px;

  ${({ $height }) => $height && `height: ${$height}px;`}

  ${Media.Tablet`
    padding: 24px;

  `}

  ${Media.Phone`
    padding: 16px;
    gap: 24px;
  `}
`

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const Title = styled.div<{ $hasPaywall: boolean }>`
  color: ${Colors.Indigo50};
  ${FontWeights.Regular};
  ${Typography.Body}
  ${({ $hasPaywall }) => $hasPaywall && `z-index: 2;`}
`

export const Body = styled.div<{ $hasPaywall?: boolean }>`
  color: ${Colors.Neutral60};
  ${FontWeights.Regular};
  ${Typography.Body}
  ${({ $hasPaywall }) => $hasPaywall && `z-index: 2;`}
`
