import { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { SectionNames, selectors, actions } from 'builder/modules/resumeEditor'
import { SectionTitleBadgeProps } from 'builder/components/SectionTitle'
import { AiKeywordsToggler } from '../AiKeywordsToggler'
import { useKeywords } from '../../hooks/useKeywords'

export const WorkExperienceKeywordsToggler: FC<SectionTitleBadgeProps> = props => {
  const { isKeywordsAvailable } = useKeywords()
  const workExperiences = useTypedSelector(selectors.resume)?.workExperiences
  const openedCard = useTypedSelector(selectors.openedCard)
  const openedSectionId = openedCard?.sectionId
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    if (!workExperiences || !workExperiences.length) {
      dispatch(
        actions.addCard({
          sectionName: SectionNames.workExperiences,
          options: { shouldOpen: true },
        }),
      )
      return
    }

    const isWorkExperienceCardOpened = openedSectionId === SectionNames.workExperiences

    if (!isWorkExperienceCardOpened) {
      dispatch(
        actions.openCard({
          id: workExperiences[0].id,
          sectionId: SectionNames.workExperiences,
        }),
      )
    }
  }, [workExperiences, openedSectionId, dispatch])

  return isKeywordsAvailable ? (
    <AiKeywordsToggler section={SectionNames.workExperiences} onClick={onClick} {...props} />
  ) : null
}
