import {
  PlanContainer,
  PlanName,
  PlanPeriod,
  PlanPrice,
  PlanPriceContainer,
  PriceLoader,
} from './styles'

export type BillingItemData = {
  planName: string
  planPrice: string
  planPeriod: string
  isLoading?: boolean
}

type Props = {
  item: BillingItemData
}

export const BillingItem = ({ item }: Props) => {
  const { planName, planPrice, planPeriod, isLoading } = item
  return (
    <PlanContainer>
      <PlanName>{planName}</PlanName>
      <PlanPriceContainer>
        {isLoading ? (
          <PriceLoader />
        ) : (
          <>
            <PlanPrice>{planPrice}</PlanPrice>
            <PlanPeriod>/{planPeriod}</PlanPeriod>
          </>
        )}
      </PlanPriceContainer>
    </PlanContainer>
  )
}
