import { Skeleton } from 'builder/Skeleton/Skeleton'
import { DesktopContainer, MobileTabletContainer, Space, Top } from './styles'

interface Props {
  isMobileTable?: boolean
}
export const SkeletonCard = ({ isMobileTable }: Props) => {
  const Container = isMobileTable ? MobileTabletContainer : DesktopContainer

  return (
    <Container>
      <Top>
        <Skeleton width={32} height={32} />

        <Skeleton width={103} height={20} />
      </Top>
      <Space />
      <Skeleton width={246} height={24} />
      <Space />
      <Skeleton width={170} height={22} />
      <Space />
    </Container>
  )
}
