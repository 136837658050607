import { useMutation } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

interface Body {
  email: string
  firstName?: string
  lastName?: string
}

export interface ResponseCreateAccount {
  success: boolean
  errorCode?: string
}

export const useCreateAccount = () => {
  return useMutation(['useCreateAccount'], async (body: Body): Promise<ResponseCreateAccount> => {
    const res = await baseClient.post(`/app/account`, body)
    return res.data
  })
}
