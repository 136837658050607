import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import { AsyncAutosuggest } from 'builder/components/AsyncAutosuggest'
import { CompanySuggestionType } from 'builder/modules/interview/types'

export const EmptyState = styled.div`
  padding: 6px 16px;
  cursor: pointer;
  color: ${Colors.Neutral90};
  background-color: ${Colors.Neutral5};
`

export const CustomAsyncAutosuggest = styled(AsyncAutosuggest<CompanySuggestionType>)`
  z-index: 1;
`
