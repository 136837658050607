import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Resume } from 'packages/types'
import { trackInternalEvent } from '@rio/tracking'

import { BriefExpertDoc, BriefResume } from 'builder/modules/panel'
import { i18n } from 'builder/utils/i18n'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'

import {
  selectors as resumeDistributionSelectors,
  actions as resumeDistributionActions,
  ResumeDetails,
} from 'builder/modules/resumeDistribution'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import useWebsiteHost from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { DocumentTypes } from 'builder/modules/constants'
import { ERDSteps } from '../../types'
import { PersonalDetails } from '../Forms/PersonalDetails/PersonalDetails'
import { useTrDocParse } from './hooks/useTrDocsParse'

import { Container, Title, Text, FormContainer, SimpleFormContainer } from './styles'
import { ResumeFilePreview } from './ResumePreview/ResumeFilePreview'
import { ResumeBuilderPreview } from './ResumePreview/ResumeBuilderPreview'
import { useEmailCanBeUsed } from './hooks/useEmailIsInUse'

interface IProps {
  resumePayload: ResumeDetails
  setActiveStep: (step: ERDSteps) => void
  setSelectedResumeDetails: (data: ResumeDetails) => void
}

const PersonalDetailsForm = ({
  resumePayload,
  setActiveStep,
  setSelectedResumeDetails,
}: IProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getHost } = useWebsiteHost()
  const { features } = useFeaturesConfig()

  const { mutateAsync, isLoading } = useTrDocParse()
  const {
    mutateAsync: isEmailInUse,
    data: emailCanBeUsed,
    isLoading: isEmailValidationPending,
  } = useEmailCanBeUsed()

  const resumeDetailOnId = useTypedSelector<Resume | null>(
    resumeDistributionSelectors.formResumeFetchDetails,
  )
  const isParsing = useTypedSelector<boolean>(resumeDistributionSelectors.isParsing)
  const formState = useTypedSelector(resumeDistributionSelectors.personalDetailsFields)
  const isEdit = useTypedSelector(resumeDistributionSelectors.isEdit)
  const candidate = useTypedSelector(resumeDistributionSelectors.candidate)

  const form = useForm({
    mode: 'onChange',
    defaultValues: formState,
  })
  const { errors } = form.formState

  const setResumeDetailsToForm = useCallback(
    async (data: Resume) => {
      dispatch(
        resumeDistributionActions.setPersonalDetailsForm({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          jobTitle: data?.jobTitle,
          linkedin: data?.linkedin,
          fullAddress: data?.address,
        }),
      )

      form.setValue('firstName', data?.firstName || '')
      form.setValue('lastName', data?.lastName || '')
      form.setValue('jobTitle', data?.jobTitle || '')
      form.setValue('email', data?.email || '')
      form.setValue('linkedin', data?.linkedin || '')
      form.setValue('fullAddress', data?.address || '')

    },
    [form, dispatch],
  )

  const resetFormState = () => {
    dispatch(
      resumeDistributionActions.setPersonalDetailsForm({
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: '',
        linkedin: '',
        fullAddress: '',
        edit_file_name: '',
      }),
    )
  }

  const parseTrDocuments = useCallback(
    async (documentId: number) => {
      const data = await mutateAsync(documentId)
      const payload = {
        ...data,
        jobTitle: data?.workExperiences?.[0]?.title,
      }
      dispatch(resumeDistributionActions.setPopulationFormDetailsForResume(payload))
    },
    [dispatch, mutateAsync],
  )

  const setEmailInUseErrorMessage = () => {
    const message = i18n.t(`builder.resume_distribution.landing.form_personal_detail.email_in_use`)
    form.setError('email', { message })
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedResume = event.target.files?.[0]

    if (selectedResume) {
      resetFormState()
      setSelectedResumeDetails(selectedResume)
      // Logic to call parse API for uploaded Resume
      dispatch(resumeDistributionActions.parseResumeForPrefill(selectedResume))
    }
  }

  const handleBuilderResumeChange = (data: BriefResume | BriefExpertDoc) => {
    if (data?.id && data?.type === DocumentTypes.trDocument) {
      parseTrDocuments(data.id)
    } else {
      dispatch(resumeDistributionActions.fetchPopulateFormDetailsForResume({ resumeId: data?.id }))
    }

    setSelectedResumeDetails(data)
  }

  const onNextStep = async () => {
    if (Object.keys(errors)?.length) return

    const email = form.getValues('email')
    if (email && candidate?.email !== email) {
      const canBeUsed = await isEmailInUse(email)
      if (!canBeUsed) {
        return setEmailInUseErrorMessage()
      }
    }

    dispatch(resumeDistributionActions.setPersonalDetailsForm(form.getValues()))
    setActiveStep(ERDSteps.FORM_STEP_2)
  }

  const onBackClick = () => {
    form.reset()
    resetFormState()
    dispatch(resumeDistributionActions.setPopulationFormDetailsForResume(null))
    dispatch(resumeDistributionActions.setResumeDetails(null))
    navigate('/resume-distribution')
  }

  useEffect(() => {
    trackInternalEvent('rd_personal_information', { website_host: getHost })
  }, [getHost])

  useEffect(() => {
    if (resumeDetailOnId && !formState?.firstName) {
      setResumeDetailsToForm(resumeDetailOnId)
    } else {
      form.setValue('firstName', formState?.firstName || '')
      form.setValue('lastName', formState?.lastName || '')
      form.setValue('jobTitle', formState?.jobTitle || '')
      form.setValue('email', formState?.email || '')
      form.setValue('linkedin', formState?.linkedin || '')
    }
  }, [resumeDetailOnId, setResumeDetailsToForm])

  useEffect(() => {
    if (resumePayload && !(resumePayload instanceof File)) {
      const resumeId = resumePayload?.id
      if (resumeId && resumePayload?.type === DocumentTypes.trDocument) {
        parseTrDocuments(resumeId)
      } else {
        dispatch(resumeDistributionActions.fetchPopulateFormDetailsForResume({ resumeId }))
      }
    }
  }, [dispatch, parseTrDocuments, resumePayload])

  return (
    <>
      <Container isNewDash={features.superApp || features.international}>
        <Title>{i18n.t(`builder.resume_distribution.landing.form_personal_detail.title`)}</Title>
        <Text>{i18n.t(`builder.resume_distribution.landing.form_personal_detail.text`)}</Text>

        {!resumePayload || resumePayload instanceof File ? (
          <ResumeFilePreview
            editFileName={isEdit && formState?.edit_file_name ? formState?.edit_file_name : null}
            resumePayload={resumePayload}
            isParserLoading={isLoading || isParsing}
            handleFileInputChange={handleFileInputChange}
            handleBuilderResumeChange={handleBuilderResumeChange}
          />
        ) : (
          <ResumeBuilderPreview
            resumePayload={resumePayload}
            isParserLoading={isLoading || isParsing}
            handleFileInputChange={handleFileInputChange}
            handleBuilderResumeChange={handleBuilderResumeChange}
          />
        )}

        <FormContainer>
          <SimpleFormContainer
            isNewDash={features.superApp || features.international}
            submitButtonText={i18n.t(
              `builder.resume_distribution.landing.form_personal_detail.next`,
            )}
            isPending={isEmailValidationPending}
            onBackClick={onBackClick}
            onSubmit={form.handleSubmit(onNextStep)}
          >
            <PersonalDetails
              isParsing={isLoading || isParsing}
              form={form}
              emailIsInUse={!emailCanBeUsed}
            />
          </SimpleFormContainer>
        </FormContainer>
      </Container>
    </>
  )
}

export default PersonalDetailsForm
