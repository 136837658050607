import {
  memo,
  Fragment,
  useState,
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'
import { trackInternalEvent } from '@rio/tracking'
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react'
import { useI18n } from 'builder/hooks/useI18n'
import { reflow } from 'builder/utils/reflow'
import { SPACING } from 'builder/modules/constants'
import Icon from 'builder/components/Icon'
import ColorSelect from 'builder/components/ColorSelect'
import { useConfig } from 'builder/hooks/useConfig'
import { baseClient } from 'builder/modules/apiClient'
import { TEMPLATE_DATA } from 'builder/components/DocumentCustomizationModal/DocumentCustomizationModal'
import ColorPickerPanel from './ColorPickerPanel'

import {
  Overlay,
  Sheet,
  Title,
  ColorsField,
  Templates,
  Template,
  Spacing,
  SpacingLimit,
  Slider,
  SliderHandle,
  TemplateCategory,
  Free,
  TemplateContainer,
} from './styles'
import { useSelector } from 'react-redux'
import { filterGreekTemplates } from 'builder/utils/filterGreekTemplates'
import { ATS_TEMPLATES, JAPANESE_TEMPLATES } from '../Helper/constants'

const TemplateSettingsSheet = ({
  type,
  onClose,
  templates,
  selectedTemplate,
  onTemplateChange,
  colors,
  selectedColor,
  onColorChange,
  spacing,
  isSpacingSupported,
  onSpacingChange,
}) => {
  const { i18n } = useI18n()
  const [isColorPickerOpen, setColorPickerOpen] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const overlay = useRef()
  const templatesGallery = useRef()
  const locale = useSelector(state => state.application.configs.country.locale)
  const [selectedCategory, setSelectedCategory] = useState('all')
  const categoriesGallery = useRef()
  const config = useConfig()
  const [templateSwiperKey, setTemplateSwiperKey] = useState(0)
  const [templateCategories, setTemplateCategories] = useState({})
  const [templateCategoriesIndexes, setTemplateCategoriesIndexes] = useState({})
  const [categorySwiperKey, setCategorySwiperKey] = useState(0)

  useEffect(() => {
    baseClient.get('templates/categories').then(response =>
      setTemplateCategories({
        ...response.data.grouped_categories,
        free: { template_names: [] },
      }),
    )
  }, [])

  useEffect(() => {
    const categoriesIndexes = Object.keys(templateCategories)
      .filter(category => category in TEMPLATE_DATA)
      .reduce((indexes, categoryId, index) => {
        indexes[index] = categoryId
        return indexes
      }, {})
    setTemplateCategoriesIndexes({ ...categoriesIndexes })
  }, [templateCategories])

  useEffect(() => {
    setCategorySwiperKey(Math.random() * 1000000)
  }, [templateCategoriesIndexes])

  //  Added the function to filter the template list while locale is greek
  const templateList = useMemo(() => {
    const hideTemplate = []

    if (locale !== 'ja-JP') {
      hideTemplate.push(...JAPANESE_TEMPLATES)
    } else {
      // TODO: remove this condition after fixing ATS templates
      hideTemplate.push(...ATS_TEMPLATES)
    }

    // TODO after Greek templates release: Remove the condition
    return (type === 'resume' ? templates : filterGreekTemplates(templates, locale))?.filter(
      template => !hideTemplate?.includes(template?.id),
    )
  }, [locale, templates, type])

  const filteredTemplates = useMemo(() => {
    if (!selectedCategory || selectedCategory === 'all') {
      return templateList
    }

    if (selectedCategory === 'free') {
      return templateList.filter(template => !template.premium)
    }

    if (!templateCategories[selectedCategory].template_names.length) {
      return templateList
    }

    return templateList.filter(template =>
      templateCategories[selectedCategory].template_names.includes(template.id),
    )
  }, [templateList, selectedCategory, templateCategories])

  useEffect(() => {
    setTemplateSwiperKey(Math.random() * 1000000)
  }, [filteredTemplates])

  useLayoutEffect(() => {
    reflow(overlay.current)
    setVisible(true)
  }, [])

  const handleColorPickerOpen = useCallback(() => setColorPickerOpen(true), [])

  const handleColorPickerClose = useCallback(() => setColorPickerOpen(false), [])

  const handleColorPickerCancel = useCallback(
    color => {
      onColorChange(color)
      setColorPickerOpen(false)
    },
    [onColorChange],
  )

  const handleOverlayClick = useCallback(
    event => onClose && event.target === overlay.current && onClose(),
    [onClose],
  )

  const handleTemplateChange = useCallback(() => {
    if (!templatesGallery.current) return
    const { activeIndex = 0 } = templatesGallery.current.swiper
    const { id } = filteredTemplates[activeIndex]
    if (onTemplateChange) onTemplateChange(id)
  }, [filteredTemplates, onTemplateChange])

  const handleCategoryChange = useCallback(() => {
    if (!categoriesGallery.current) return
    const { activeIndex = 0 } = categoriesGallery.current.swiper
    const id = templateCategoriesIndexes[activeIndex]
    setSelectedCategory(id)
    trackInternalEvent('choose_the_templates_group', { type: id })
  }, [templateCategoriesIndexes])

  useEffect(() => {
    if (!templatesGallery.current) return
    const { activeIndex = 0 } = templatesGallery.current.swiper
    const { id } = filteredTemplates[activeIndex]
    onTemplateChange(id)
  }, [selectedCategory, filteredTemplates, onTemplateChange])

  const initialSlide = useMemo(() => {
    return findIndex(filteredTemplates, { id: selectedTemplate })
  }, [selectedTemplate, filteredTemplates])

  return (
    <Overlay ref={overlay} onClick={handleOverlayClick}>
      <Sheet isVisible={isVisible}>
        {!isColorPickerOpen && (
          <Fragment>
            <Title>{i18n.t('builder.resume_editor.template_select')}</Title>
            {config?.features?.filterOnTheTemplatesPage &&
              !!Object.keys(templateCategories).length &&
              type === 'resume' && (
                <Templates>
                  <ReactSwiper
                    key={categorySwiperKey}
                    ref={categoriesGallery}
                    initialSlide={0}
                    slidesPerView="auto"
                    spaceBetween={28}
                    centeredSlides={true}
                    slideToClickedSlide={true}
                    onSlideChange={handleCategoryChange}
                  >
                    {Object.keys(templateCategories)
                      .map(category => TEMPLATE_DATA[category])
                      .filter(category => category)
                      .map(template => (
                        <SwiperSlide key={template.id}>
                          <TemplateCategory>{template.name}</TemplateCategory>
                        </SwiperSlide>
                      ))}
                  </ReactSwiper>
                </Templates>
              )}
            <Templates>
              <ReactSwiper
                key={templateSwiperKey}
                ref={templatesGallery}
                initialSlide={initialSlide}
                slidesPerView="auto"
                spaceBetween={28}
                centeredSlides={true}
                slideToClickedSlide={true}
                onSlideChange={handleTemplateChange}
              >
                {filteredTemplates.map(template => (
                  <SwiperSlide key={template.id}>
                    <TemplateContainer>
                      <Template>{template.name}</Template>
                      {config?.features?.filterOnTheTemplatesPage &&
                        type === 'resume' &&
                        !template.premium && <Free>FREE</Free>}
                    </TemplateContainer>
                  </SwiperSlide>
                ))}
              </ReactSwiper>
            </Templates>

            <Title>{i18n.t('builder.resume_editor.color_select')}</Title>
            <ColorsField>
              <ColorSelect
                size={42}
                selected={selectedColor}
                options={colors}
                onSelect={onColorChange}
                onAdd={handleColorPickerOpen}
              />
            </ColorsField>

            {type === 'resume' && (
              <Fragment>
                <Title>{i18n.t('builder.resume_editor.spacing')}</Title>
                <Spacing>
                  <SpacingLimit>50%</SpacingLimit>
                  <Slider
                    disabled={!isSpacingSupported}
                    defaultValue={0}
                    value={spacing}
                    step={SPACING.step}
                    min={SPACING.min}
                    max={SPACING.max}
                    handle={Handle}
                    onChange={onSpacingChange}
                    dots
                  />
                  <SpacingLimit>150%</SpacingLimit>
                </Spacing>
              </Fragment>
            )}
          </Fragment>
        )}
        {isColorPickerOpen && (
          <ColorPickerPanel
            selected={selectedColor}
            onSelect={onColorChange}
            onSubmit={handleColorPickerClose}
            onCancel={handleColorPickerCancel}
          />
        )}
      </Sheet>
    </Overlay>
  )
}

TemplateSettingsSheet.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,

  templates: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.string,
  onTemplateChange: PropTypes.func,

  colors: PropTypes.array,
  selectedColor: PropTypes.string,
  onColorChange: PropTypes.func,

  spacing: PropTypes.number,
  isSpacingSupported: PropTypes.bool,
  onSpacingChange: PropTypes.func,
}

export default memo(TemplateSettingsSheet)

const Handle = ({ value, dragging, index, ...handleProps }) => (
  <SliderHandle value={value} {...handleProps}>
    {handleProps.disabled && <Icon.Lock />}
  </SliderHandle>
)

Handle.propTypes = {
  value: PropTypes.any,
  dragging: PropTypes.any,
  index: PropTypes.number,
  disabled: PropTypes.bool,
}
