import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useI18n } from 'builder/hooks/useI18n'
import { useUser } from 'builder/hooks/useUser'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useAutoApplyUpsellModal } from 'builder/components/AutoApplyUpsellModal/useAutoApplyUpsellModal'
import { Step } from '../Step'
import {
  EllipseThree,
  EllipseTwo,
  EllipsisContainer,
  EllipseOne,
  JobPostImage,
  JobPostImageGradient,
  RectangleGradientOne,
  RectangleGradientTwo,
  AutoApplyButton,
  AutoApplyButtonIcon,
  ApplyButton,
  ApplyButtonIcon,
  Container,
  ImageContainer,
} from './styles'
import JOB_POST from './assets/job-post.svg'
import JOB_POST_PHONE from './assets/job-post-phone.svg'

interface Props {
  isCompleted: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const HelpYouStep = ({ isCompleted, isOpen, setIsOpen }: Props) => {
  const user = useUser()
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { isPhone } = useMediaQueries()
  const { features } = useFeaturesConfig()
  const { handleOpenModal } = useAutoApplyUpsellModal()

  const [showHover, setShowHover] = useState(false)

  const handleOnClick = () => {
    if (features?.autoApply && !user?.scopes.autoApply) {
      handleOpenModal()
    } else {
      navigate('/auto-apply/start')
    }
  }

  return (
    <Step
      title={i18n.t('builder.dashboard_v2.steps.help_you.title')}
      subtitle={i18n.t('builder.dashboard_v2.steps.help_you.subtitle')}
      isCompleted={isCompleted}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleOnClick={handleOnClick}
      buttonText={i18n.t('builder.dashboard_v2.steps.help_you.button_text')}
      useSecondaryTheme
      analyticsEventsParam="apply_for_me"
      Images={
        <Container>
          <ImageContainer>
            <JobPostImage src={isPhone ? JOB_POST_PHONE : JOB_POST} />
            <JobPostImageGradient className={showHover ? 'hover' : ''} />
            <RectangleGradientOne className={showHover ? 'hover' : ''} />
            <RectangleGradientTwo className={showHover ? 'hover' : ''} />
            <AutoApplyButton>
              <AutoApplyButtonIcon />
              {i18n.t('builder.dashboard_v2.steps.help_you.auto_apply')}
            </AutoApplyButton>
            <ApplyButton theme="secondary">
              <ApplyButtonIcon />
              {i18n.t('builder.dashboard_v2.steps.help_you.apply')}
            </ApplyButton>
          </ImageContainer>
          <EllipsisContainer>
            <EllipseOne className={showHover ? 'hover' : ''} />
            <EllipseTwo className={showHover ? 'hover' : ''} />
            <EllipseThree className={showHover ? 'hover' : ''} />
          </EllipsisContainer>
        </Container>
      }
      setShowHover={setShowHover}
    />
  )
}
