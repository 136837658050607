import { useCallback, useEffect, useState } from 'react'
import * as React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import type { UserDocumentType } from '@rio/types'
import { typeToSlug } from 'builder/modules/panel'
import { DocumentTypes } from 'builder/modules/constants'
import useWebsiteHost, {
  cioDomainList,
} from 'builder/components/CIO-Dasboard-2.0/hooks/useWebsiteHost'
import { Dashboard } from './components/Dashboard'

type Props = {
  isDocumentsPage?: boolean
  defaultType?: DocumentTypes
  defaultIsAutoTailored?: boolean
}

export const PanelView: React.FC<Props> = ({
  defaultType,
  defaultIsAutoTailored = false,
  ...props
}) => {
  const { getHost } = useWebsiteHost()
  const setDefaultDocType = () => {
    if (defaultType) return defaultType

    return cioDomainList.includes(getHost) || getHost.includes('topresume')
      ? DocumentTypes.trDocument
      : DocumentTypes.resume
  }

  const [documentType, setDocumentType] = useState(setDefaultDocType())
  const [isAutoTailored, setIsAutoTailored] = useState(defaultIsAutoTailored)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const isAutoTailoredParam = searchParams.get('autotailored') as 'true' | null
  const openedTabParam = searchParams.get('tab') as DocumentTypes | null
  const isDocumentsPage = props.isDocumentsPage

  useEffect(() => {
    setDocumentType(documentType)
  }, [location.pathname, documentType])

  useEffect(() => {
    if (!openedTabParam) return
    setDocumentType(openedTabParam)
  }, [openedTabParam])

  useEffect(() => {
    if (!isDocumentsPage) {
      setIsAutoTailored(isAutoTailoredParam === 'true')
    }
  }, [isAutoTailoredParam, isDocumentsPage])

  const handleDocumentTypeChange = useCallback(
    (type: UserDocumentType, isAutoTailored: boolean) => {
      try {
        if (isDocumentsPage) {
          const newSlug = typeToSlug(type, isAutoTailored)
          navigate(`/${newSlug}`, { replace: true, state: { preventScrollToTop: true } })
        } else {
          const newSearchParams = new URLSearchParams(location.search)
          newSearchParams.set('tab', type)

          if (isAutoTailored) {
            newSearchParams.set('autotailored', 'true')
          } else {
            newSearchParams.delete('autotailored')
          }

          setSearchParams(newSearchParams)
        }

        setDocumentType(type as DocumentTypes)
        setIsAutoTailored(isAutoTailored)
      } catch (e) {
        console.error('error', e)
      }
    },
    [isDocumentsPage, location.search, navigate, setSearchParams],
  )

  useEffect(() => {
    trackInternalEvent('visit_dashboard')
  }, [])

  return (
    <Dashboard
      {...props}
      documentType={documentType}
      onDocumentTypeChange={handleDocumentTypeChange}
      isDocumentsPage={isDocumentsPage}
      isAutoTailored={isAutoTailored}
    />
  )
}
