import { useQuery } from '@tanstack/react-query'
import { baseClient } from 'builder/modules/apiClient'

export const useGetLowestPrice = () => {
  return useQuery(['useGetLowestPrice'], async (): Promise<string> => {
    const response = await baseClient.get<{ minPlanPrice: string }>(
      `/app/payment-plans/min-plan-price`,
    )
    return response.data.minPlanPrice
  })
}
