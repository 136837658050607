import styled from 'styled-components'
import Typography, { FontWeights } from 'builder/styles/typography'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  height: 184px;
  flex-shrink: 0;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.Phone`
    height: 148px;
    padding: 16px;
    gap: 1px;
    background: ${Colors.White};
    border-radius: 20px; 

    &:nth-child(1) {
      border-radius: 20px 0px 20px 20px;
    }

    &:nth-child(2) {
      border-radius: 0px 20px 20px 20px;
    }
  `}
`

export const Title = styled.div`
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral50};
`

export const Classification = styled.div<{ $classificationColor: string }>`
  ${Typography.Body};
  ${FontWeights.Regular};
  color: ${({ $classificationColor }) => $classificationColor};
  &::first-letter {
    text-transform: uppercase;
  }
`

export const Value = styled.div`
  ${Typography.L};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};
`
