import { Outlet } from 'react-router-dom'
import VideoSrc from 'builder/views/Interview/assets/interview-start-page-video.mp4'
import { Container, Right, Left, VideoWrapper, StyledVideo, Content, Row, Wrapper } from './styles'
import { Navbar } from './Navbar/Navbar'

export const Start = () => {
  return (
    <Wrapper>
      <Navbar currentStep={1} />
      <Container>
        <Content>
          <Row>
            <Left>
              <Outlet />
            </Left>
            <Right>
              <VideoWrapper>
                <StyledVideo src={VideoSrc} key={0} autoPlay muted loop playsInline />
              </VideoWrapper>
            </Right>
          </Row>
        </Content>
      </Container>
    </Wrapper>
  )
}
