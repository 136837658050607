import { useNavigate } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import Button, { ButtonTheme } from 'builder/components/Button'
import Icon24 from 'builder/components/Icon'
import { useI18n } from 'builder/hooks/useI18n'
import { useCreateInterviewFromInterview } from 'builder/views/Interview/hooks/useCreateInterviewFromInterview'
import { Question } from '../Question/Question'
import { ButtonContainer, Container } from './styles'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.last_section`

interface Props {
  questionNumber: number
  question?: string
  questionTotal?: number
  interviewId?: number
}

export const LastSection = ({ questionNumber, question, questionTotal, interviewId }: Props) => {
  const { i18n } = useI18n()
  const createInterviewFromSameQuestions = useCreateInterviewFromInterview()
  const navigate = useNavigate()

  const newInterviewWithSameQuestion = async () => {
    if (interviewId === undefined) {
      return
    }
    await createInterviewFromSameQuestions.mutateAsync(interviewId)

    trackInternalEvent('try_again', {
      label: 'interview_prep',
    })
    navigate('/interview-preparation/interview-player')
  }
  return (
    <Container>
      <Question
        enableEllipse={false}
        questionNumber={questionNumber}
        question={question}
        questionTotal={questionTotal || 0}
      />
      {interviewId && (
        <ButtonContainer>
          <Button theme={ButtonTheme.ghost} onClick={newInterviewWithSameQuestion}>
            <Icon24.UpdateIcon /> {i18n.t(`${TRANSLATION_PREFIX}.try_again`)}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}
