import styled from 'styled-components'
import Sizes from 'builder/styles/sizes'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import { Icon20 } from 'builder/components/Icon'
import Media from 'builder/styles/media'

export const Container = styled.div`
  padding: ${Sizes.XS} ${Sizes.S};
  border-radius: ${Sizes['3XS']};
  background: ${Colors.Neutral5};
`

export const Icon = styled.img`
  width: 24px;
  height: 24px;

  ${Media.Phone`
    display: none;
  `}
`
export const Button = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  gap: ${Sizes.XS};
`
export const Title = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  gap: 4px;

  border-radius: ${Sizes['3XS']};
  background: ${Colors.Neutral5};

  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral90};

  & b {
    ${FontWeights.Medium};
  }
`

export const ArrowDown = styled(Icon20.ChevronDown)`
  cursor: pointer;
  color: ${Colors.Neutral50};
  width: 24px;
  height: 24px;
`

export const ArrowUp = styled(Icon20.ChevronDown)`
  cursor: pointer;
  color: ${Colors.Neutral50};
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
`

export const Text = styled.div`
  padding: 32px 20px 20px 20px;
  ${Typography.Caption};
  ${FontWeights.Regular};
  color: ${Colors.Neutral60};
  & h1 {
    ${Typography.Caption};
    ${FontWeights.Medium};
    color: ${Colors.Neutral100};

    padding-top: 16px;
  }

  & h1:first-of-type {
    padding-top: 0;
  }
`
