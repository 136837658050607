import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${Colors.White};
  z-index: 1;

  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
`
export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 439px;
  height: 439px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const PageTitle = styled.h2`
  ${Typography.M};
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};
`

export const JobTitle = styled.p`
  ${Typography.M};
  color: ${Colors.Neutral90};
  ${FontWeights.Medium};

  & strong {
    color: ${Colors.Neutral50};
    ${FontWeights.Medium};
  }
  & img {
    width: 32px;
    height: 32px;
  }
`
