import styled from 'styled-components'
import { Icon20 } from 'builder/components/Icon'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 16px;
  overflow: hidden;
`

export const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.White};
  height: 20px;
`

export const MuteButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background-color: ${Colors.White};
  color: ${Colors.Neutral50};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;

  & svg {
    width: 12.53px;
    height: 12.53px;
  }

  &:hover {
    background-color: ${Colors.Blue10};
    color: ${Colors.Blue50};
  }
`

export const CustomVideo = styled.video`
  max-width: 412px;
  /* hide chrome cast icon from the video tag */
  &::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`

export const PlayIcon = styled(Icon20.Play)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`

export const PauseIcon = styled(Icon20.Pause)`
  height: 30px;
  width: 30px;
  cursor: pointer;
`

export const PlayPauseContainer = styled.div``

export const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterContainer = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.White};
  position: absolute;
  padding: 0 13px;
  bottom: 15.43px;
  width: 100%;
  display: flex;
`

export const TimelineTimeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const TimelineContainer = styled.div`
  display: flex;
  width: 100%;
  height: 4px;
`
export const FullTimeline = styled.div`
  width: 100%;
  border-radius: var(--4XS, 2px);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  cursor: pointer;
`

export const PlayTimeline = styled.div<{ $progress: number }>`
  width: ${props => props.$progress}%;
  height: 4px;
  border-radius: 2px 0px 0px 2px;
  border-right: 2px solid ${Colors.White};
  background: rgba(255, 255, 255, 0.5);
`

export const Time = styled.div`
  ${Typography.Tiny};
  ${FontWeights.Regular};
  color: ${Colors.White};
  margin-left: 20px;
  white-space: nowrap;
  flex-shrink: 0;
  width: 67px;
`
