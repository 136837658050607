import { useI18n } from 'builder/hooks/useI18n'
import { InterviewAnswerFeedback } from 'builder/modules/interview/types'
import {
  parseFillerWords,
  parsePauses,
  parseToneOfVoiceValue,
  parseWordPerMinute,
} from '../../parse'
import { Card, SkeletonCard } from './components/Card/Card'
import { Container } from './styles'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2.card_section`

interface Props {
  feedback: InterviewAnswerFeedback | undefined
}

export const CardSection = ({ feedback }: Props) => {
  const { i18n } = useI18n()

  const renderCards = () => {
    if (!feedback) {
      return (
        <>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </>
      )
    }

    return (
      <>
        <Card
          title={i18n.t(`${TRANSLATION_PREFIX}.ton_of_voice`)}
          classification={parseToneOfVoiceValue(feedback?.ton_of_voice)}
        />
        <Card
          title={i18n.t(`${TRANSLATION_PREFIX}.words_per_minute`)}
          classification={parseWordPerMinute(feedback?.words_per_minute)}
        />
        <Card
          title={i18n.t(`${TRANSLATION_PREFIX}.filler_words`)}
          classification={parseFillerWords(feedback?.filler_words)}
        />
        <Card
          title={i18n.t(`${TRANSLATION_PREFIX}.pauses`)}
          classification={parsePauses((feedback?.pauses || []).length)}
        />
      </>
    )
  }

  return <Container id="CardSection">{renderCards()}</Container>
}
