import { useLocation } from 'react-router-dom'

import { useResumeDistribution } from 'builder/views/ResumeDistributionView/hooks/useResumeDistribution'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { selectors as userSelectors, UserType } from 'builder/modules/user'
import { selectors as initSelectors } from 'builder/modules/init'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { useI18n } from 'builder/hooks/useI18n'
import { useChromeExtension } from 'builder/hooks/useChromeExtension'

import Colors from 'builder/styles/colors'
import { Icon20 } from 'builder/components/Icon'
import { EXTENSION_URL } from 'builder/modules/jobTracking'
import { ChromeHoverPromote } from 'builder/components/JobTracking/ChromeHoverPromote/ChromeHoverPromote'

import { ISideMenuItems, ISubMenu, Position } from '../types'
import { Label, SubMenuIcon } from '../components/SideMenu/styles'
import { useOtherSubMenu } from './useOtherSubMenu'
import { useDocumentSubMenu } from './useDocumentSubMenu'
import { useJobSearchSubMenu } from './useJobSearchSubMenu'

const TRANSLATION = 'builder.side_menu'

export const useMenuItems = (isMobile?: boolean) => {
  const { i18n } = useI18n()
  const location = useLocation()
  const feat = useFeaturesConfig()
  const { othersSubMenu } = useOtherSubMenu()
  const { documentSubMenu } = useDocumentSubMenu()
  const { jobSearchSubMenu } = useJobSearchSubMenu()
  const { isInstalled, canInstallExtension, getBrowserIcon } = useChromeExtension()
  const { isUserRdPromotedWithoutPlan, showRDUpsellPromoModal } = useResumeDistribution()
  const userType = useTypedSelector(userSelectors.postPremium)
  const returnButtonData = useTypedSelector(initSelectors.returnButton)

  const isPostPremiumActive = userType === UserType.postPremium
  const showRdUpsellModal = isUserRdPromotedWithoutPlan && !isPostPremiumActive
  const PremiumIcon = isPostPremiumActive && (
    <Icon20.Crown width={24} height={24} color={Colors.Indigo70} />
  )

  const isActiveSubMenu = (subMenu: ISubMenu[]) => {
    return subMenu.map(item => item.to).includes(`${location.pathname}${location.search}`)
  }

  const isActiveNestedMenu = (pathname: string) => {
    const PATH_NAME_LENGTH = 3
    // Ignore test for pathname no longer than 3 characters
    if (pathname.length < PATH_NAME_LENGTH) return false
    return location.pathname.includes(pathname)
  }

  const menuItems: ISideMenuItems[] = [
    {
      to: '/',
      enabled: !isMobile,
      text: i18n.t(`${TRANSLATION}.dashboard`),
      startDecoration: <Icon20.Dashboard />,
      startDecorationActive: <Icon20.DashboardActive />,
      eventParam: 'dashboard',
    },
    {
      to: '#',
      text: i18n.t(`${TRANSLATION}.documents`),
      enabled: true,
      subMenu: documentSubMenu.filter(item => item.enabled),
      active: isActiveSubMenu(documentSubMenu),
      endDecoration: <SubMenuIcon />,
      startDecoration: <Icon20.Documents />,
      startDecorationActive: <Icon20.DocumentsActive />,
      eventParam: 'documents',
    },
    {
      id: 'recommendations',
      to: '/job-search?view=recommendation',
      text: i18n.t(`${TRANSLATION}.recommended_jobs`),
      enabled: feat.features?.jobSearch,
      active: isActiveNestedMenu('/job-search'),
      startDecoration: <Icon20.Jobs />,
      startDecorationActive: <Icon20.JobsActive />,
      eventParam: 'job_search',
    },
    {
      id: 'job-tracking-menu',
      to: '/job-tracking',
      text: i18n.t(`${TRANSLATION}.job_tracker`),
      enabled: feat.features?.jobTracking,
      isPostPremium: isPostPremiumActive,
      endDecoration: isPostPremiumActive && PremiumIcon,
      startDecoration: <Icon20.JobTracker />,
      startDecorationActive: <Icon20.JobTrackerActive />,
      eventParam: 'job_tracking',
    },
    {
      to: '/interview-preparation/dashboard',
      text: i18n.t(`${TRANSLATION}.interview_prep`),
      enabled: feat.features?.interviewSchool,
      active: isActiveNestedMenu('/interview-preparation'),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      startDecoration: <Icon20.InterviewPrep />,
      startDecorationActive: <Icon20.InterviewPrepActive />,
      eventParam: 'interview_prep',
    },
    {
      to: '/offer-analyzer',
      text: i18n.t(`${TRANSLATION}.salary_analyzer`),
      enabled: feat.features?.salaryAnalyzer,
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      startDecoration: <Icon20.SalaryAnalyzer />,
      startDecorationActive: <Icon20.SalaryAnalyzerActive />,
      eventParam: 'salary_analyzer',
    },
    {
      id: 'resume_distribution_menu',
      to: '/resume-distribution',
      text: i18n.t(`${TRANSLATION}.resume_distribution`),
      enabled: feat?.showResumeDistribution(),
      active: isActiveNestedMenu('/resume-distribution'),
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon || <Label>{i18n.t(`${TRANSLATION}.new_label`)}</Label>,
      startDecoration: <Icon20.ResumeDistribution />,
      startDecorationActive: <Icon20.ResumeDistributionActive />,
      eventParam: 'resume_distribution',
      // onClick method override 'to' property and navigation should handle on click
      onClick: showRdUpsellModal ? showRDUpsellPromoModal : undefined,
    },
    {
      to: '/my-events',
      text: i18n.t(`${TRANSLATION}.virtual_hiring_event`),
      enabled: feat.features?.virtualHiringEvents,
      isPostPremium: isPostPremiumActive,
      endDecoration: PremiumIcon,
      startDecoration: <Icon20.VirtualEvents />,
      startDecorationActive: <Icon20.VirtualEventsActive />,
      eventParam: 'virtual_hiring_events',
    },
    {
      to: '#',
      text: i18n.t(`${TRANSLATION}.job_search_method`),
      enabled: feat.features?.jobSearchPlan,
      subMenu: jobSearchSubMenu,
      active: isActiveSubMenu(jobSearchSubMenu),
      endDecoration: <SubMenuIcon />,
      startDecoration: <Icon20.JobSearch />,
      startDecorationActive: <Icon20.JobSearchActive />,
      eventParam: 'job_search_strategy',
    },
    {
      to: '/career-coaching',
      text: i18n.t(`${TRANSLATION}.coaching`),
      enabled: feat.features?.careerCoaching,
      startDecoration: <Icon20.Coaching />,
      startDecorationActive: <Icon20.CoachingActive />,
      eventParam: 'career_coaching',
    },
    {
      to: '#',
      text: i18n.t(`${TRANSLATION}.others`),
      enabled: !!othersSubMenu.filter(item => item.enabled)?.length,
      subMenu: othersSubMenu.filter(item => item.enabled),
      active: isActiveSubMenu(othersSubMenu),
      endDecoration: <SubMenuIcon />,
      startDecoration: <Icon20.More />,
      startDecorationActive: <Icon20.MoreActive />,
      eventParam: 'other',
    },
    {
      to: '#',
      href: returnButtonData?.url || '#',
      text: returnButtonData?.text || i18n.t(`${TRANSLATION}.return_button_text`),
      startDecoration: <img src={returnButtonData?.icon} />,
      startDecorationActive: <img src={returnButtonData?.icon} />,
      position: 'end' as Position,
      enabled: returnButtonData?.enabled,
      eventParam: 'partner_redirect',
    },
    {
      to: '#',
      id: 'side-menu-chrome-extenstion',
      href: EXTENSION_URL,
      text: i18n.t(`${TRANSLATION}.chrome_extension`),
      enabled: !isInstalled && canInstallExtension && feat.features?.autoApply && !isMobile,
      position: returnButtonData?.enabled ? 'start' : ('end' as Position),
      startDecoration: getBrowserIcon(),
      startDecorationActive: getBrowserIcon(),
      eventParam: 'chrome_extension',
      popover: <ChromeHoverPromote />,
    },
  ].filter(item => item.enabled)

  return {
    isActiveSubMenu,
    menuItems: menuItems,
    isActiveNestedMenu,
  }
}
