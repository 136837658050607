export const eduDegreeOptions = [
  { id: 1, name: "Bachelor's Degree", key: "Bachelor's degree" },
  { id: 2, name: "Master's Degree", key: "Master's degree" },
  { id: 3, name: 'Other', key: 'Other' },
]

export enum DegreeType {
  Bachelor = 'Bachelor',
  Master = 'Bachelor',
  HighSchool = 'HighSchool',
  College = 'College',
  Associate = 'Associate',
  Doctoral = 'Doctoral',
}

export const eduDegreeOptionsForOfferAnalyzer = [
  { id: 1, name: 'High School Diploma', key: DegreeType.HighSchool },
  { id: 2, name: 'College, No Degree', key: DegreeType.College },
  { id: 3, name: "Associate's Degree", key: DegreeType.Associate },
  { id: 4, name: "Bachelor's Degree", key: DegreeType.Bachelor },
  { id: 5, name: "Master's Degree", key: DegreeType.Master },
  { id: 6, name: 'Doctoral Degree', key: DegreeType.Doctoral },
]

export function getDegreeNameById(id: number) {
  const degree = eduDegreeOptionsForOfferAnalyzer.find(degree => degree.id === id)
  return degree ? degree.name : ''
}

export function getDegreeIdByName(name: string) {
  const degree = eduDegreeOptionsForOfferAnalyzer.find(degree => degree.name === name)
  return degree ? degree.id : -1
}

export function getDegreeKeyByName(name: string) {
  const degree = eduDegreeOptionsForOfferAnalyzer.find(degree => degree.name === name)
  return degree ? degree.key : ''
}
