import styled, { css } from 'styled-components'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Container = styled.div`
  min-width: 0;
  border-radius: 26px;
`
export const QuestionLabel = styled.div`
  color: ${Colors.Neutral50};
  ${FontWeights.Regular};
  ${Typography.Caption};
`

export const QuestionText = styled.div<{ $enableEllipse: boolean }>`
  color: ${Colors.Neutral100};
  ${FontWeights.Regular};
  ${Typography.Body};

  ${({ $enableEllipse }) =>
    $enableEllipse &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
`
