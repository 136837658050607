import { useRef } from 'react'
import { useI18n } from 'builder/hooks/useI18n'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import { Skeleton } from 'builder/Skeleton/Skeleton'
import { Container, QuestionText, QuestionLabel } from './styles'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedbackV2`

interface Props {
  questionNumber: number
  question: string | undefined
  questionTotal: number | undefined
  enableEllipse: boolean
  onClick?: () => void
  onClickOutside?: () => void
}

export const Question = ({
  questionNumber,
  question,
  questionTotal,
  enableEllipse,
  onClick,
  onClickOutside,
}: Props) => {
  const { i18n } = useI18n()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const initFunc = () => {}
  useClickOutside(containerRef, onClickOutside || initFunc)
  if (!question || !questionTotal) {
    return (
      <Container>
        <Skeleton width={283} height={47} />
        <Skeleton width={130} height={47} style={{ marginTop: 5 }} />
      </Container>
    )
  }
  return (
    <Container ref={containerRef} onClick={onClick}>
      <QuestionLabel>
        {i18n.t(`${TRANSLATION_PREFIX}.question`)}&nbsp;
        {questionNumber}&nbsp;
        {questionTotal > 1 && (
          <>
            {i18n.t(`${TRANSLATION_PREFIX}.of_preposition`)}&nbsp;{questionTotal}
          </>
        )}
      </QuestionLabel>
      <QuestionText $enableEllipse={enableEllipse}>{question}</QuestionText>
    </Container>
  )
}
