import { useMemo } from 'react'
import { Optional } from 'packages/types'
import { CIO_JOB_SEARCH_URL, CIO_OFFER_ANALYZER_URL } from 'builder/modules/constants'
import { i18n as I18n } from 'builder/utils/i18n'
import { useConfig } from 'builder/hooks/useConfig'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors } from 'builder/modules/init'
import JobOfferImage from '../../../../../app/assets/images/subscription_cancellation/promotion_jobOffered.png'
import DiscountImage from '../../../../../app/assets/images/subscription_cancellation/promotion_discount.png'
import DreamJobImage from '../../../../../app/assets/images/subscription_cancellation/promotion_dreamJob.png'
import { Cards, PromotionTopic } from '../common/types'

const usePromotionSectionValues = (
  activeCard: Optional<Cards>,
  feedbackSubmitted: boolean,
  isACIORegisteredUser: boolean,
  successfulSubmit: boolean,
  discountErrorText: string | null,
  discountSubsCancelStatus?: boolean,
  priceAfterDiscount?: number,
) => {
  const config = useConfig()
  const isSubscriptionV4Enabled = config?.features?.subCancellationV4 || false
  const locale = useTypedSelector(selectors.locale)
  const discountPercentage = config?.features?.discountPercentage || 50

  const promotionButtonText = useMemo(() => {
    switch (activeCard) {
      case Cards.JobOffer:
        if (locale === 'nl-NL') {
          return I18n.t(
            'builder.subscription_cancellation.promotion_section.job_offer.button_text_nl',
          )
        }

        if (isSubscriptionV4Enabled) {
          return I18n.t(
            'builder.subscription_cancellation.promotion_section.job_offer.button_text_v4',
          )
        } else {
          return isACIORegisteredUser
            ? I18n.t(
                'builder.subscription_cancellation.promotion_section.job_offer.button_text.compare',
              )
            : I18n.t(
                'builder.subscription_cancellation.promotion_section.job_offer.button_text.get_started',
              )
        }
      case Cards.Discount:
        return I18n.t('builder.subscription_cancellation.promotion_section.discount.button_text')
      case Cards.FeatureOrProblem:
        return I18n.t('builder.subscription_cancellation.promotion_section.feature.button_text')
      case Cards.DreamJob:
        if (isSubscriptionV4Enabled) {
          return I18n.t(
            'builder.subscription_cancellation.promotion_section.dream_job.button_text_v4',
          )
        } else {
          return isACIORegisteredUser
            ? I18n.t(
                'builder.subscription_cancellation.promotion_section.dream_job.button_text.explore',
              )
            : I18n.t(
                'builder.subscription_cancellation.promotion_section.dream_job.button_text.get_started',
              )
        }
      case Cards.DontNeedPromos:
        return [
          I18n.t(
            'builder.subscription_cancellation.promotion_section.dont_need_promos.links.recommend_jobs',
          ),
          I18n.t(
            'builder.subscription_cancellation.promotion_section.dont_need_promos.links.compare_to_peers',
          ),
          I18n.t(
            'builder.subscription_cancellation.promotion_section.dont_need_promos.links.ace_job_interview',
          ),
        ]
      case Cards.OtherReasons:
        return I18n.t('builder.subscription_cancellation.promotion_section.other.button_text')
      default:
        return ''
    }
  }, [activeCard, isACIORegisteredUser, isSubscriptionV4Enabled, locale])

  const promotionDescription = useMemo(() => {
    switch (activeCard) {
      case Cards.JobOffer:
        if (locale === 'nl-NL') {
          return (
            <>
              {I18n.t(
                'builder.subscription_cancellation.promotion_section.job_offer.description_nl',
              )}
            </>
          )
        }

        if (isSubscriptionV4Enabled) {
          return (
            <>
              {I18n.t(
                'builder.subscription_cancellation.promotion_section.job_offer.description_v4',
              )}
            </>
          )
        } else {
          return (
            <>
              {I18n.t(
                'builder.subscription_cancellation.promotion_section.job_offer.description_line_one',
              )}
              <a href={CIO_OFFER_ANALYZER_URL}>{' ' + 'Career.io'}</a>
              {' ' +
                I18n.t(
                  'builder.subscription_cancellation.promotion_section.job_offer.description_line_two',
                )}
            </>
          )
        }
      case Cards.Discount:
        return (
          <>
            {priceAfterDiscount
              ? I18n.t(
                  'builder.subscription_cancellation.promotion_section.discount.description_with_price',
                  {
                    price_after_discount: priceAfterDiscount.toFixed(2),
                    defaultValue: I18n.t(
                      'builder.subscription_cancellation.promotion_section.discount.description',
                    ),
                    discount_percentage: discountPercentage,
                  },
                )
              : I18n.t('builder.subscription_cancellation.promotion_section.discount.description', {
                  discount_percentage: discountPercentage,
                })}
          </>
        )
      case Cards.FeatureOrProblem:
        return (
          <>{I18n.t('builder.subscription_cancellation.promotion_section.feature.description')}</>
        )
      case Cards.DreamJob:
        if (isSubscriptionV4Enabled) {
          return (
            <>
              {I18n.t(
                'builder.subscription_cancellation.promotion_section.dream_job.description_v4',
              )}
            </>
          )
        } else {
          return (
            <>
              {I18n.t(
                'builder.subscription_cancellation.promotion_section.dream_job.description_line_one',
              )}
              <a href={CIO_JOB_SEARCH_URL}>{' ' + 'Career.io'}</a>
              {' ' +
                I18n.t(
                  'builder.subscription_cancellation.promotion_section.dream_job.description_line_two',
                )}
            </>
          )
        }
      case Cards.DontNeedPromos:
        return [
          I18n.t(
            'builder.subscription_cancellation.promotion_section.dont_need_promos.links_description.recommend_jobs',
          ),
          I18n.t(
            'builder.subscription_cancellation.promotion_section.dont_need_promos.links_description.compare_to_peers',
          ),
          I18n.t(
            'builder.subscription_cancellation.promotion_section.dont_need_promos.links_description.ace_job_interview',
          ),
        ]
      case Cards.OtherReasons:
        return (
          <>{I18n.t('builder.subscription_cancellation.promotion_section.other.description')}</>
        )
      default:
        return <></>
    }
  }, [activeCard, priceAfterDiscount, isSubscriptionV4Enabled, locale])

  const promotionImage = useMemo(() => {
    switch (activeCard) {
      case Cards.JobOffer:
        return JobOfferImage
      case Cards.DreamJob:
        return DreamJobImage
      case Cards.Discount:
        return DiscountImage
      default:
        return ''
    }
  }, [activeCard])

  const promotionTopic: PromotionTopic = useMemo(() => {
    switch (activeCard) {
      case Cards.FeatureOrProblem:
        return feedbackSubmitted
          ? successfulSubmit
            ? 'submitContent'
            : 'errorSubmit'
          : Cards.FeatureOrProblem
      case Cards.OtherReasons:
        return feedbackSubmitted
          ? successfulSubmit
            ? 'submitContent'
            : 'errorSubmit'
          : Cards.OtherReasons
      default:
        return activeCard as Cards
    }
  }, [activeCard, feedbackSubmitted, successfulSubmit])

  const promotionTitle = useMemo(() => {
    switch (activeCard) {
      case Cards.JobOffer:
        if (locale === 'nl-NL') {
          return I18n.t('builder.subscription_cancellation.promotion_section.job_offer.title_nl')
        }

        return isSubscriptionV4Enabled
          ? I18n.t('builder.subscription_cancellation.promotion_section.job_offer.title_v4')
          : I18n.t('builder.subscription_cancellation.promotion_section.job_offer.title')
      case Cards.Discount:
        return discountSubsCancelStatus ||
          (discountErrorText && discountErrorText === 'Already Discounted')
          ? I18n.t('builder.subscription_cancellation.promotion_section.discount.title.thanks', {
              discount_percentage: discountPercentage,
            })
          : I18n.t('builder.subscription_cancellation.promotion_section.discount.title.get', {
              discount_percentage: discountPercentage,
            })
      case Cards.FeatureOrProblem:
        return feedbackSubmitted
          ? successfulSubmit
            ? I18n.t('builder.subscription_cancellation.promotion_section.feature.title.thanks')
            : I18n.t('builder.subscription_cancellation.promotion_section.feature.title.error')
          : I18n.t('builder.subscription_cancellation.promotion_section.feature.title.details')
      case Cards.DreamJob:
        return isSubscriptionV4Enabled
          ? I18n.t('builder.subscription_cancellation.promotion_section.dream_job.title_v4')
          : I18n.t('builder.subscription_cancellation.promotion_section.dream_job.title')
      case Cards.DontNeedPromos:
        return I18n.t('builder.subscription_cancellation.promotion_section.dont_need_promos.title')
      case Cards.OtherReasons:
        return feedbackSubmitted
          ? successfulSubmit
            ? I18n.t('builder.subscription_cancellation.promotion_section.other.title.thanks')
            : I18n.t('builder.subscription_cancellation.promotion_section.other.title.error')
          : I18n.t('builder.subscription_cancellation.promotion_section.other.title.reasons')
      default:
        return ''
    }
  }, [
    activeCard,
    discountErrorText,
    discountSubsCancelStatus,
    feedbackSubmitted,
    successfulSubmit,
    isSubscriptionV4Enabled,
    locale,
  ])

  const textAreaPlaceholder = useMemo(() => {
    return activeCard === Cards.FeatureOrProblem
      ? I18n.t('builder.subscription_cancellation.promotion_section.feature.placeholder')
      : I18n.t('builder.subscription_cancellation.promotion_section.placeholder')
  }, [activeCard])

  const promotionTitleTopic: PromotionTopic = useMemo(() => {
    return feedbackSubmitted ? (successfulSubmit ? promotionTopic : 'errorSubmit') : promotionTopic
  }, [promotionTopic, feedbackSubmitted, successfulSubmit])

  return {
    promotionButtonText,
    promotionDescription,
    promotionImage,
    promotionTopic,
    promotionTitle,
    promotionTitleTopic,
    textAreaPlaceholder,
  }
}

export default usePromotionSectionValues
