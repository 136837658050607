import { ResumeSkill } from '@rio/types'
import { i18n } from 'builder/utils/i18n'
import { SectionNames } from 'builder/modules/resumeEditor'
import { clampNumber } from 'builder/utils/clampNumber'
import { addCardTo } from '../utils/addCardTo'
import { openCard } from '../utils/openCard'
import { CheckFn } from '../types'

const MIN = 3

const isValidCard = ({ skill }: ResumeSkill): boolean => {
  return typeof skill === 'string' && skill.length > 0
}

export const checkSkills: CheckFn = ({ skills }) => {
  const validSkills = skills.filter(isValidCard)

  return {
    sectionId: SectionNames.skills,
    progress: clampNumber(validSkills.length / MIN),
    id: 'not_enough_skills',
    title: i18n.t('builder.resume_tuner.tips.not_enough_skills.title', { min: MIN }),
    description: i18n.t('builder.resume_tuner.tips.not_enough_skills.description'),
    subject: i18n.t('builder.resume_tuner.tips.not_enough_skills.subject'),
    counter: `${validSkills.length}/${MIN}`,
    label: i18n.t('builder.resume_tuner.issues.not_enough_skills', { min: MIN }),
    actionText: i18n.t('builder.resume_tuner.tips.not_enough_skills.action_text'),
    action: () => {
      const invalidCard = skills.find(card => isValidCard(card) === false)
      invalidCard ? openCard(invalidCard, SectionNames.skills) : addCardTo(SectionNames.skills)
    },
  }
}
