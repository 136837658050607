import styled from 'styled-components'
import { Typography } from 'builder/styles/typography'
import { Colors } from 'builder/styles/colors'

export const Wrapper = styled.div`
  position: relative;
  ${Typography.Body};
  border-radius: 8px;
`

export const Label = styled.label`
  ${Typography.Caption};
  color: ${Colors.Neutral50};
  display: block;
  margin-bottom: 6px;
`
