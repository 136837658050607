import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { trackInternalEvent } from '@rio/tracking'
import { InterviewBehaviour, behaviourType } from 'builder/modules/interview/types'
import { useI18n } from 'builder/hooks/useI18n'
import { InterviewStartPage } from './InterviewStartPage'

const TRANSLATION = 'builder.interview_prep_view.start_screen'

export interface Params {
  behaviour?: behaviourType
}

export const InterviewStartView = () => {
  const { behaviour = InterviewBehaviour.NERVOUS }: Params = useParams()
  const navigate = useNavigate()

  const { i18n } = useI18n()

  useEffect(() => {
    async function userIsLogged() {
      const response = await fetch('/api/app/account', {
        cache: 'no-store',
      })
      const body = await response.json()
      if (response.status === 200 && body.user.currentJobTitleId) {
        navigate('/interview-preparation/dashboard')
      }
    }

    userIsLogged()
  }, [])

  useEffect(() => {
    trackInternalEvent('visit_test_interview', {
      label: 'interview_prep',
      parameter: behaviour,
    })
  }, [])

  switch (behaviour) {
    // company/industry/ambitious
    case InterviewBehaviour.AMBITIOUS:
      return (
        <InterviewStartPage
          titleLabel={i18n.t(`${TRANSLATION}.ambitious.title`)}
          subtitleLabel={i18n.t(`${TRANSLATION}.ambitious.subtitle`)}
          confirmButtonLabel={i18n.t(`${TRANSLATION}.create_interview`)}
          behaviour={behaviour}
        />
      )

    // tough q/confident
    case InterviewBehaviour.CONFIDENT:
      return (
        <InterviewStartPage
          titleLabel={i18n.t(`${TRANSLATION}.confident.title`)}
          subtitleLabel={i18n.t(`${TRANSLATION}.confident.subtitle`)}
          confirmButtonLabel={i18n.t(`${TRANSLATION}.create_interview`)}
          behaviour={behaviour}
        />
      )

    // anxiety/nervous/easy/Stress Relief
    case InterviewBehaviour.NERVOUS:
    default:
      return (
        <InterviewStartPage
          titleLabel={i18n.t(`${TRANSLATION}.nervous.title`)}
          subtitleLabel={i18n.t(`${TRANSLATION}.nervous.subtitle`)}
          confirmButtonLabel={i18n.t(`${TRANSLATION}.create_interview`)}
          behaviour={behaviour}
        />
      )
  }
}
