import React, { useEffect, useRef, useState } from 'react'
import { useMediaQueries } from 'builder/hooks/useMediaQueries'
import { useClickOutside } from 'builder/hooks/useClickOutside'
import {
  CloseIcon,
  CloseIconContainer,
  Popup,
  PopupContent,
  Subtitle,
  Title,
  TitleContainer,
  Wrapper,
} from './styles'

type ModalProps = {
  onClose: () => void
  title?: string
  subtitle?: string
  width?: number
  className?: string
  padding?: string
}

export const ScrollabelDialogModalV2 = ({
  children,
  title,
  subtitle,
  onClose,
  width,
  className,
  padding,
}: React.PropsWithChildren<ModalProps>) => {
  const { isPhone } = useMediaQueries()
  const modalRef = useRef<HTMLDivElement>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  useClickOutside(modalRef, () => mounted && onClose())

  return (
    <Popup>
      <PopupContent>
        <Wrapper
          $width={width || isPhone ? 343 : 544}
          className={className}
          $padding={padding}
          ref={modalRef}
        >
          <TitleContainer>
            <Title>{title}</Title>
            <CloseIconContainer onClick={onClose}>
              <CloseIcon />
            </CloseIconContainer>
          </TitleContainer>

          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {children}
        </Wrapper>
      </PopupContent>
    </Popup>
  )
}
