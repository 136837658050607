import {
  UpsellFeature,
  UpsellFeatures,
  UpsellFeaturesContainer,
  UpsellFeatureTitle,
} from '../styles'

type Props = {
  features: string[]
  iconType: 'checkmark' | 'number'
  hasBackground: boolean
  gapSize: 'L' | 'M'
  title?: string
  applyPadding?: boolean
}

export const UpsellFeaturesList: React.FC<Props> = ({
  title,
  features,
  iconType,
  hasBackground,
  gapSize,
  applyPadding,
}) => {
  return (
    <UpsellFeaturesContainer>
      {title && <UpsellFeatureTitle>{title}</UpsellFeatureTitle>}
      <UpsellFeatures gapSize={gapSize} hasBackground={hasBackground} applyPadding={applyPadding}>
        {features?.map((feature, i) => (
          <UpsellFeature index={i + 1} iconType={iconType} key={feature}>
            {feature}
          </UpsellFeature>
        ))}
      </UpsellFeatures>
    </UpsellFeaturesContainer>
  )
}
