import { useNavigate } from 'react-router-dom'

import { useI18n } from 'builder/hooks/useI18n'
import { useConfig } from 'builder/hooks/useConfig'
import { authService } from 'builder/services/AuthService'

import { Props } from '../ProfileMobile'
import { FaqIcon, Link, TextContainer, Links, LogOutIcon, SettingsIcon } from './styles'

export const Settings = ({ closeMenu }: Props) => {
  const { i18n } = useI18n()
  const config = useConfig()
  const navigate = useNavigate()

  const navigateToAccount = () => {
    closeMenu()
    navigate('/account')
  }

  return (
    <Links>
      <Link as="button" onClick={navigateToAccount}>
        <TextContainer>
          <SettingsIcon />
          {i18n.t('builder.navbar.account_settings')}
        </TextContainer>
      </Link>

      {config?.links.updates && (
        <Link href={config.links.updates} target="_blank" rel="noreferrer noopener">
          {i18n.t('builder.navbar.updates')}
        </Link>
      )}

      {config?.links.help && (
        <Link href={config.links.help} target="_blank" rel="noreferrer noopener">
          <TextContainer>
            <FaqIcon />
            {i18n.t('builder.navbar.faq')}
          </TextContainer>
        </Link>
      )}

      {/* TODO: Change logic during the migration to JWT-auth */}
      <Link as="button" onClick={() => authService.logout()}>
        <TextContainer>
          <LogOutIcon />
          {i18n.t('builder.navbar.log_out')}
        </TextContainer>
      </Link>
    </Links>
  )
}
