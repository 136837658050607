import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from 'builder/modules/store'
import { DEFAULT_FILTERS } from 'builder/components/FindJob/JobFilters/constants'
import { ConfigScopesEnum } from '../init'
import {
  Alert,
  AutoApplyBubbles,
  JobSearchNotificationType,
  JobSearchStore,
  SearchForm,
  SelectedJobForAutoApply,
} from './types'

const jobAlertsFeatureGroup = (state: Store) =>
  state.application.configs[ConfigScopesEnum.app]?.features.jobAlerts

export const selectors = {
  searchForm: (state: Store) => state.jobSearch.searchForm,
  autoApplyBubbles: (state: Store) => state.jobSearch.autoApplyBubbles,
  runAutoApplyBubbles: (state: Store) => state.jobSearch.autoApplyBubbles.running,
  alert: (state: Store) => state.jobSearch.alert,
  notification: (state: Store) => state.jobSearch.notification,
  enableJobAlertFeature: (state: Store) => !!jobAlertsFeatureGroup(state)?.enabled,
  alertFeatureGroup: (state: Store) => jobAlertsFeatureGroup(state)?.group,
}

const initialState: JobSearchStore = {
  searchForm: {
    term: '',
    selectedLocation: undefined,
    filters: DEFAULT_FILTERS,
  },
  autoApplyBubbles: {
    userStartedAutoApplyBubbles: false,
    running: false,
    showAutoApplyGuideButton: false,
    stepIndex: 0,
    showFirstPage: true,
    initialized: false,
    selectedJob: {
      data: null,
      tab: null,
      done: false,
    },
  },
  notification: undefined,
  alert: { type: undefined },
}

export const { reducer, actions } = createSlice({
  name: 'jobSearch',
  initialState,
  reducers: {
    setSearchForm: (draft, action: PayloadAction<SearchForm>) => {
      draft.searchForm = action.payload
    },
    setSelectAutoApplyBubbleJob: (draft, action: PayloadAction<SelectedJobForAutoApply>) => {
      draft.autoApplyBubbles.selectedJob = action.payload
    },
    setAutoApplyBubble: (draft, action: PayloadAction<Partial<AutoApplyBubbles>>) => {
      draft.autoApplyBubbles = {
        ...draft.autoApplyBubbles,
        ...action.payload,
      }
    },
    closeFirstPageApplyBubble: draft => {
      draft.autoApplyBubbles.showFirstPage = false
    },
    goNextAutoApplyStep: draft => {
      draft.autoApplyBubbles.stepIndex = draft.autoApplyBubbles.stepIndex + 1
    },
    setRunAutoApplyBubble: (draft, action: PayloadAction<{ running: boolean }>) => {
      draft.autoApplyBubbles.running = action.payload.running
    },
    setAlertType: (draft, action: PayloadAction<Alert>) => {
      draft.alert.type = action.payload.type
      draft.alert.editCustomAlert = action.payload.editCustomAlert
    },
    setNotification: (draft, action: PayloadAction<JobSearchNotificationType | undefined>) => {
      draft.notification = action.payload
    },
  },
})
