import Icon24 from 'builder/components/Icon'
import { Container } from './styles'
import { SliderArrowButtonThemeEnum, SliderArrowButtonType, SliderArrowButtonSize } from './type'

export const TRANSLATION_PREFIX = `builder.interview_prep_view.feedback.summary_question`

interface Props {
  theme: SliderArrowButtonThemeEnum
  type: SliderArrowButtonType
  size: SliderArrowButtonSize
  disable?: boolean
  onClick: () => void
  disableBgColor?: boolean
}
export const SliderArrowButton = ({
  theme,
  type,
  size,
  disable = false,
  onClick,
  disableBgColor = false,
}: Props) => {
  return (
    <Container
      $disable={disable}
      $disableBgColor={disableBgColor}
      $theme={theme}
      $size={size}
      onClick={() => !disable && onClick()}
    >
      {type === SliderArrowButtonType.left && !disable && <Icon24.ChevronLeft />}
      {type === SliderArrowButtonType.right && !disable && <Icon24.ChevronRight />}
    </Container>
  )
}
