import styled from 'styled-components'
import ReactSlider from 'rc-slider'
import Colors from 'builder/styles/colors'
import Typography, { FontWeights } from 'builder/styles/typography'

export const Overlay = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-tap-highlight-color: transparent;
`

export const Sheet = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.Neutral100};
  color: ${Colors.White};
  text-align: center;
  padding: 20px 20px 24px;
  transition: transform 0.2s, opacity 0.2s;
  border-bottom: 0 solid ${Colors.Neutral100};
  border-bottom-width: env(safe-area-inset-bottom);
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: translateY(${props => (props.isVisible ? '0%' : '100%')});
`

export const Title = styled.div`
  ${Typography.Caps};
  font-weight: 600;
  margin-bottom: 8px;
`

export const ColorsField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Templates = styled.div`
  margin-bottom: 20px;

  .swiper-container {
    position: relative;
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    position: relative;
    transition-property: transform;
  }
`

export const TemplateContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const Template = styled.div`
  position: relative;
  flex-shrink: 0;
  color: ${Colors.Neutral70};
  white-space: nowrap;
  transition: color 0.2s;

  .swiper-slide-active & {
    color: ${Colors.White};
  }

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -14px;
    right: -14px;
  }
`

export const Free = styled.span`
  background-color: ${Colors.Green40};
  border-radius: 2px;
  padding: 2px 4px;
  color: white;
  font-size: 14px;
  ${FontWeights.DemiBold};
  letter-spacing: 0.3px;
`

export const TemplateCategory = styled.div`
  background-color: ${Colors.Neutral10};
  opacity: 0.4;
  border-radius: 2px;
  padding: 4px 6px;
  position: relative;
  flex-shrink: 0;
  color: ${Colors.Neutral50};
  white-space: nowrap;
  transition: color 0.2s;
  ${FontWeights.DemiBold};

  .swiper-slide-active & {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: -14px;
    right: -14px;
  }
`

export const Spacing = styled.div`
  display: flex;
  align-items: center;
`

export const SpacingLimit = styled.div`
  ${Typography.Caption};
  min-width: 38px;

  &:first-child {
    text-align: right;
  }

  &:last-child {
    text-align: left;
  }
`

const handleSize = 24
const handleExtraSpace = 16

export const Slider = styled(ReactSlider)`
  position: relative;
  flex-grow: 1;
  margin: 0 ${handleSize / 2 + 8}px;
  height: ${handleSize}px;

  & .rc-slider-rail {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    background-color: ${props => (props.disabled ? Colors.Neutral90 : Colors.Neutral70)};
  }

  & .rc-slider-dot {
    position: absolute;
    top: 50%;
    width: 2px;
    height: 8px;
    margin: -4px 0 0 -1px;
    background-color: ${props => (props.disabled ? Colors.Neutral90 : Colors.Neutral70)};
  }
`

export const SliderHandle = styled(ReactSlider.Handle)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  width: ${handleSize}px;
  height: ${handleSize}px;
  margin-left: ${-handleSize / 2}px;

  border-radius: 50%;
  background-color: ${props => (props.disabled ? Colors.Neutral70 : Colors.White)};
  outline: none;
  touch-action: pan-x;
  cursor: grab;

  /* Extend draggable area */
  &:before {
    content: '';
    position: absolute;
    top: ${-handleExtraSpace}px;
    left: ${-handleExtraSpace}px;
    right: ${-handleExtraSpace}px;
    bottom: ${-handleExtraSpace}px;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`

export const ColorPickerPanelContainer = styled.div`
  width: 250px;
  margin: 0 auto;
`

export const PanelActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const PanelAction = styled.button`
  margin: 0;
  padding: 4px 12px;
  border: none;
  background: none;
  font-weight: ${props => (props.primary ? 600 : 'normal')};
`
