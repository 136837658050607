import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { selectors as panelSelectors } from 'builder/modules/panel'
import { actions as editorActions } from 'builder/modules/resumeEditor'
import { actions as userActions } from 'builder/modules/user'
import Colors from 'builder/styles/colors'
import { TextAreaField } from 'builder/components/TextField'
import { MouseClickEvent, SnackbarTypes, actions as uiActions } from 'builder/modules/ui'
import { i18n as I18n } from 'builder/utils/i18n'
import { selectors } from 'builder/modules/init'
import { useConfig } from 'builder/hooks/useConfig'

import { useResumeTailoringBanner } from '../../DocumentEditor/ResumeTailoringBanner/useResumeTailoringBanner'
import Collapse from '../Collapse'
import { FLOW_IDS } from '../constants'
import DONT_LIKE_SERVICE_BUG_DISCOUNT_LOGO from '../assets/images/dont_like_service_bug_discount.svg'
import DONT_LIKE_SERVICE_BUG_DISCOUNT_LOGO_NL from '../assets/images/dont_like_service_bug_discount_nl.svg'
import DONT_LIKE_SERVICE_LIMITED_DISCOUNT_LOGO from '../assets/images/dont_like_service_limited_discount.svg'
import DONT_LIKE_SERVICE_AI from '../assets/images/dont_like_service_ai.svg'
import DONT_LIKE_SERVICE_MATCH from '../assets/images/dont_like_service_match.svg'
import DONT_LIKE_SERVICE_EXAMPLES from '../assets/images/dont_like_service_examples.svg'
import { CollapseWithItemsContainer, PromotionContainerColumn } from '../styles'
import ActionItem from '../ActionItem'

import {
  BackToBuilderButton,
  BugSectionContainer,
  BugSectionDiscountContainer,
  BugSectionDiscountContainerNl,
  BugSectionDiscountDescription,
  BugSectionDiscountImage,
  BugSectionDiscountImageNl,
  BugSectionDiscountLeft,
  BugSectionDiscountTitle,
  BugSectionDiscountTitleNl,
  DiscountButton,
  LimitedSectionDiscountContainer,
  LimitedSectionDiscountDescription,
  LimitedSectionDiscountLeft,
  LimitedSectionDiscountTitle,
  SubmitButton,
  TextAreaWrapper,
} from './styles'

type Props = {
  handleClose: () => void
  hasUserVisitedPlans: boolean
  email?: string
  fullName?: string
}

const ACTIONS = {
  notHelpful: 'NOT_HELPFUL',
  foundBug: 'FOUND_A_BUG',
  tooExpensive: 'TOO_EXPENSIVE',
}

const BUG_STEPS = {
  form: 'FORM',
  discount: 'DISCOUNT',
}

const DontLikeService: React.FC<Props> = ({
  handleClose,
  hasUserVisitedPlans,
  email,
  fullName,
}: Props) => {
  const [activeAction, setActiveAction] = useState<string | undefined>(undefined)
  const [bugText, setBugText] = useState('')
  const [bugStep, setBugStep] = useState(BUG_STEPS.form)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const documentsList = useTypedSelector(panelSelectors.documents)
  const { onBannerClick: openTaloring } = useResumeTailoringBanner('builder')
  const locale = useTypedSelector(selectors.locale)
  const config = useConfig()
  const discountPercentage = config?.features?.discountPercentage || 50

  const handleBugChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setBugText(e.target.value)

  const handleBugSubmit = async (e: MouseClickEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (bugText.trim().length !== 0) {
      trackInternalEvent('promo_acceptance', { promo: 'report_bug', where: 'in_app_abandonment' })
      dispatch(
        userActions.submitFeedbackForSubscriptionCancellation({
          email: email ?? '',
          name: fullName ?? '',
          feedback: bugText,
          plan: 'free',
          subject: 'abandonment resume modal',
        }),
      )
      setBugText('')
      setBugStep(BUG_STEPS.discount)
    } else {
      dispatch(
        uiActions.setSnackBarOpen({
          status: true,
          type: SnackbarTypes.failure,
          text: I18n.t('builder.in_app_abandonment.dont_like_service.not_entered_feedback'),
        }),
      )
      setTimeout(() => {
        dispatch(uiActions.closeSnackBar())
      }, 3000)
    }
  }

  const handleActiveAction = (action: string) => {
    setActiveAction((prevAction: string | undefined) => {
      if (prevAction && prevAction === action) {
        return undefined
      }

      return action
    })
  }

  const startWithAiOnClick = () => {
    trackInternalEvent('promo_acceptance', { promo: 'try_AI', where: 'in_app_abandonment' })
    navigate(`/resumes/${documentsList[0].id}/edit?showAIResumeDraft=true`)
    dispatch(editorActions.resetAIResumeData())
    dispatch(editorActions.setShowAIResumeModal(true))
    handleClose()
  }

  const showExamples = () => {
    trackInternalEvent('promo_acceptance', { promo: 'Examples', where: 'in_app_abandonment' })
    dispatch(editorActions.fetchResumeExamples())
    handleClose()
  }

  const handleGetDiscount = () => {
    trackInternalEvent('promo_acceptance', { promo: 'discount', where: 'in_app_abandonment' })
    dispatch(userActions.getAbandonedDiscount({ discountPercentage }))
    handleClose()
  }

  const openTailoringPopup = () => {
    trackInternalEvent('promo_acceptance', { promo: 'Tailoring', where: 'in_app_abandonment' })
    openTaloring()
    handleClose()
  }

  return (
    <PromotionContainerColumn activeCardId={FLOW_IDS.dontLikeTheService}>
      <Collapse
        title={I18n.t('builder.in_app_abandonment.dont_like_service.not_helpful.title')}
        showContent={activeAction === ACTIONS.notHelpful}
        handleActiveAction={() => handleActiveAction(ACTIONS.notHelpful)}
      >
        <CollapseWithItemsContainer>
          <ActionItem
            title={I18n.t('builder.in_app_abandonment.dont_like_service.not_helpful.ai.title')}
            buttonOnClick={startWithAiOnClick}
            buttonText={I18n.t(
              'builder.in_app_abandonment.dont_like_service.not_helpful.ai.button_text',
            )}
            logoSrc={DONT_LIKE_SERVICE_AI}
            backgroundColor={Colors.Indigo10}
          />
          <ActionItem
            title={I18n.t('builder.in_app_abandonment.dont_like_service.not_helpful.match.title')}
            buttonText={I18n.t(
              'builder.in_app_abandonment.dont_like_service.not_helpful.match.button_text',
            )}
            logoSrc={DONT_LIKE_SERVICE_MATCH}
            backgroundColor={Colors.Indigo10}
            buttonOnClick={openTailoringPopup}
          />
          <ActionItem
            title={I18n.t('builder.in_app_abandonment.dont_like_service.not_helpful.explore.title')}
            buttonText={I18n.t(
              'builder.in_app_abandonment.dont_like_service.not_helpful.explore.button_text',
            )}
            buttonOnClick={showExamples}
            logoSrc={DONT_LIKE_SERVICE_EXAMPLES}
          />
        </CollapseWithItemsContainer>
      </Collapse>
      <Collapse
        title={I18n.t('builder.in_app_abandonment.dont_like_service.bug.title')}
        showContent={activeAction === ACTIONS.foundBug}
        handleActiveAction={() => handleActiveAction(ACTIONS.foundBug)}
      >
        <BugSectionContainer>
          {bugStep === BUG_STEPS.form && (
            <>
              <TextAreaWrapper>
                <TextAreaField
                  className="textAreaField"
                  placeholder={I18n.t(
                    'builder.in_app_abandonment.dont_like_service.bug.placeholder',
                  )}
                  value={bugText}
                  onChange={handleBugChange}
                  onClick={e => e.stopPropagation()}
                />
              </TextAreaWrapper>
              <SubmitButton onClick={handleBugSubmit}>
                {I18n.t('builder.in_app_abandonment.dont_like_service.bug.button_text')}
              </SubmitButton>
            </>
          )}
          {bugStep === BUG_STEPS.discount &&
            (locale !== 'nl-NL' ? (
              <BugSectionDiscountContainer>
                <BugSectionDiscountLeft>
                  <BugSectionDiscountTitle>
                    {I18n.t('builder.in_app_abandonment.dont_like_service.bug.discount.title')}
                  </BugSectionDiscountTitle>
                  <BugSectionDiscountDescription>
                    {I18n.t(
                      'builder.in_app_abandonment.dont_like_service.bug.discount.description',
                      { discount_percentage: discountPercentage },
                    )}
                  </BugSectionDiscountDescription>
                  <DiscountButton onClick={handleGetDiscount}>
                    {I18n.t(
                      'builder.in_app_abandonment.dont_like_service.bug.discount.button_text',
                    )}
                  </DiscountButton>
                </BugSectionDiscountLeft>
                <BugSectionDiscountImage src={DONT_LIKE_SERVICE_BUG_DISCOUNT_LOGO} />
              </BugSectionDiscountContainer>
            ) : (
              <BugSectionDiscountContainerNl>
                <BugSectionDiscountImageNl src={DONT_LIKE_SERVICE_BUG_DISCOUNT_LOGO_NL} />
                <BugSectionDiscountTitleNl>
                  {I18n.t('builder.in_app_abandonment.dont_like_service.bug.discount.title')}
                </BugSectionDiscountTitleNl>
                <BackToBuilderButton onClick={handleClose}>
                  {I18n.t(
                    'builder.in_app_abandonment.dont_like_service.bug.discount.button_text_nl',
                  )}
                </BackToBuilderButton>
              </BugSectionDiscountContainerNl>
            ))}
        </BugSectionContainer>
      </Collapse>
      {hasUserVisitedPlans && locale !== 'nl-NL' && (
        <Collapse
          title={I18n.t('builder.in_app_abandonment.dont_like_service.expensive.title')}
          showContent={activeAction === ACTIONS.tooExpensive}
          handleActiveAction={() => handleActiveAction(ACTIONS.tooExpensive)}
        >
          <LimitedSectionDiscountContainer>
            <LimitedSectionDiscountLeft>
              <LimitedSectionDiscountTitle>
                {I18n.t('builder.in_app_abandonment.dont_like_service.expensive.subtitle')}
              </LimitedSectionDiscountTitle>
              <LimitedSectionDiscountDescription>
                {I18n.t('builder.in_app_abandonment.dont_like_service.expensive.description', {
                  discount_percentage: discountPercentage,
                })}
              </LimitedSectionDiscountDescription>
              <DiscountButton onClick={handleGetDiscount}>
                {I18n.t('builder.in_app_abandonment.dont_like_service.expensive.button_text')}
              </DiscountButton>
            </LimitedSectionDiscountLeft>
            <BugSectionDiscountImage src={DONT_LIKE_SERVICE_LIMITED_DISCOUNT_LOGO} />
          </LimitedSectionDiscountContainer>
        </Collapse>
      )}
    </PromotionContainerColumn>
  )
}

export default DontLikeService
