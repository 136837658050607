import { createRef, Fragment, PureComponent, RefObject } from 'react'
import throttle from 'lodash/throttle'
import startCase from 'lodash/startCase'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { trackMarketingEvent, trackInternalEvent, EventCode } from '@rio/tracking'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { i18n as I18n } from 'builder/utils/i18n'
import { A4_FACTOR, FORMATS } from 'builder/modules/constants'
import { actions as uiActions } from 'builder/modules/ui'
import {
  actions as coRegActions,
  selectors as coRegSelectors,
} from 'builder/modules/coRegistration'
import Preview from 'builder/components/Preview'
import { ActionsButtonBadge } from 'builder/components/PreviewPanel/components/DownloadMenuLayout/styles'
import { withMediaQueries } from 'builder/components/MediaQueries'
import { withConfig } from 'builder/components/Config'
import EscapeHandler from 'builder/components/EscapeHandler'
import Icon, { Icon32 } from 'builder/components/Icon'
import DocumentActionMenu from 'builder/components/DocumentActionMenu'
import CoRegPopup, { CoRegPopupPositions } from 'builder/components/PreviewPanel/CoRegPopup'
import { Store } from 'builder/modules/store'
import { getUpdatedTemplateAndFormat } from 'builder/utils/getTemplates'
import { baseClient } from 'builder/modules/apiClient'
import { TemplateCategories } from 'builder/modules/resumeEditor'
import { TemplatesPreviewList } from '../TemplatesList/TemplatesPreviewList'
import templateHeightFactorSelector from '../Helper/utils/templateHeightFactorSelector'
import { shouldOpenPlansPageAbandonment } from '../AbandonmentModal/utils'
import { Icon40 } from '../Icon/Icon'
import SpacingControl from './SpacingControl'
import Colors from './Colors'
import {
  Container,
  Toolbar,
  ToolbarLeft,
  ToolbarCenter,
  ToolbarRight,
  Updating,
  UpdatingIcon,
  UpdatingText,
  Divider,
  Back,
  BackIcon,
  BackText,
  Pagination,
  PaginationPrev,
  PaginationNext,
  PaginationCounter,
  Sidebar,
  SidebarInner,
  Main,
  Content,
  ContentInner,
  PreviewBox,
  Actions,
  ActionsButton,
  DownloadButton,
  CustomSelect,
} from './styles'
import { CurrentTemplate, DocumentPreviewModalProps } from './Constants'

const PREVIEW_WIDTH = 928

export const TEMPLATE_DATA: Record<
  `${TemplateCategories}`,
  { name: string; icon: JSX.Element; id: string }
> = {
  [TemplateCategories.all]: {
    name: 'All templates',
    icon: <Icon32.Templates width={24} height={24} />,
    id: TemplateCategories.all,
  },
  [TemplateCategories.simple]: {
    name: 'Simple',
    icon: <Icon.DoubleStars width={24} height={24} />,
    id: TemplateCategories.simple,
  },
  [TemplateCategories.ats]: {
    name: 'ATS',
    icon: <Icon.ArrowTarget width={24} height={24} color="#5C6373" />,
    id: TemplateCategories.ats,
  },
  [TemplateCategories.creative]: {
    name: 'Creative',
    icon: <Icon40.Unicorn width={24} height={24} color="#5C6373" />,
    id: TemplateCategories.creative,
  },
  [TemplateCategories.modern]: {
    name: 'Modern',
    icon: <Icon.HandModern width={24} height={24} />,
    id: TemplateCategories.modern,
  },
  [TemplateCategories.professional]: {
    name: 'Professional',
    icon: <Icon.WorkBag width={24} height={24} color="white" />,
    id: TemplateCategories.professional,
  },
  [TemplateCategories.free]: {
    name: 'Free',
    icon: <Icon.Deal width={24} height={24} />,
    id: TemplateCategories.free,
  },
}

class DocumentPreviewModal extends PureComponent<DocumentPreviewModalProps> {
  sidebarRef: RefObject<HTMLDivElement>
  contentRef: RefObject<HTMLDivElement>
  preview: RefObject<{
    previousPage: () => void
    resize: (width: number) => void
    nextPage: () => void
  }>

  previewBoxRef: HTMLDivElement | null

  state = {
    isActionMenuOpen: false,
    isColorPickerOpen: false,
    currentPage: 1,
    totalPagesNumber: 1,
    width: PREVIEW_WIDTH,
    height: PREVIEW_WIDTH / A4_FACTOR,
    templateCategories: {},
    selectedCategory: 'all',
  } as {
    [x: string]: any
    templateCategories:
      | Record<string, never>
      | Record<`${TemplateCategories}`, { template_names: [] }>
  }

  constructor(props: DocumentPreviewModalProps) {
    super(props)
    this.sidebarRef = createRef()
    this.contentRef = createRef()
    this.preview = createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handlePreviewResize)
    this.handlePreviewResize()
    if (this.contentRef.current) disableBodyScroll(this.contentRef.current)

    baseClient
      .get<{ grouped_categories: Record<`${TemplateCategories}`, { template_names: [] }> }>(
        'templates/categories',
      )
      .then(response =>
        this.setState({
          templateCategories: { ...response.data.grouped_categories, free: { template_names: [] } },
        }),
      )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlePreviewResize)
    if (this.contentRef.current) enableBodyScroll(this.contentRef.current)
  }

  handleCombineClick = () => {
    const { type, openMergeModal, currentDocument } = this.props
    const documentKey = type === 'resume' ? 'resumeId' : 'coverLetterId'

    openMergeModal({ [documentKey]: currentDocument.id })
    trackInternalEvent('open_documents_merging_modal')
  }

  handleDownloadDocument = ({ format = FORMATS.pdf }) => {
    const {
      currentDocument,
      type,
      onDownload,
      registeredAt,
      config,
      openPlansPageAbandonmentModal,
      showAbandonmentFlows,
    } = this.props
    if (
      window.location.pathname.includes('/resumes') &&
      registeredAt &&
      shouldOpenPlansPageAbandonment(
        registeredAt,
        config.features.plansPageAbandonment || false,
        showAbandonmentFlows,
      )
    ) {
      trackInternalEvent('show_abandonment_popup', { source: 'dashboard', type: 'plans' })
      openPlansPageAbandonmentModal()
      return
    }
    const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
    const { updatedTemplate, updatedFormat } = getUpdatedTemplateAndFormat(
      showOneDownloadButton,
      type,
      currentDocument.template as string,
      format,
    )

    onDownload({
      id: currentDocument.id,
      type,
      format: updatedFormat,
      source: 'builder_full_screen_preview',
      template: updatedTemplate,
    })

    // Track in Google Analytics
    trackMarketingEvent('Preview Modal', `Download ${startCase(type)}`, {
      eventLabel: updatedFormat,
    })
    // Track in ClickHouse
    trackInternalEvent(`click_download_${type}_button` as EventCode, {
      format: updatedFormat,
      source: `full_screen_${type}_preview`,
      template: updatedTemplate,
    })
  }

  handlePreviewResize = throttle(() => {
    if (!this.previewBoxRef) return
    const previewWidth = this.previewBoxRef.offsetWidth
    const width = Math.min(previewWidth, PREVIEW_WIDTH)
    const height =
      Math.min(previewWidth, PREVIEW_WIDTH) /
      templateHeightFactorSelector(this.props?.currentDocument)
    this.setState({ width, height })

    if (!this.preview.current) return
    this.preview.current?.resize(width)
  }, 50)

  handleColorSelect = (color: string) => {
    this.props.updateSimpleField('color', color, false)
  }

  handleSpacingChange = (spacing: number) => {
    // Use custom debounce interval (350 ms) instead of the default one (1,000 ms)
    // to display the new version of the resume faster and prevent extra requests
    this.props.updateSimpleField('spacing', spacing, 350)
  }

  handlePaginationStateChange = (state: { currentPage: number; totalPagesCount: number }) => {
    this.setState({ currentPage: state.currentPage + 1, totalPagesNumber: state.totalPagesCount })
  }

  handlePreviousPageClick = () => {
    if (!this.preview.current) return
    this.preview.current?.previousPage()
    trackInternalEvent(`change_${this.props.type}_preview_page` as EventCode)
  }

  handleNextPageClick = () => {
    if (!this.preview.current) return
    this.preview.current?.nextPage()
    trackInternalEvent(`change_${this.props.type}_preview_page` as EventCode)
  }

  handleShareClick = () => {
    trackInternalEvent('click_share_button', { source: `full_screen_${this.props.type}_preview` })
  }

  handleActionMenuOpen = () => {
    this.setState({ isActionMenuOpen: true })
    trackInternalEvent('open_document_action_menu', {
      source: `full_screen_${this.props.type}_preview`,
    })
  }

  handleActionMenuClose = () => {
    this.setState({ isActionMenuOpen: false })
  }

  get isResume() {
    return this.props.type === 'resume'
  }

  get templates() {
    const { editorState } = this.props
    return this.isResume ? editorState.resumeTemplates : editorState.coverLetterTemplates
  }

  get currentTemplate() {
    const { currentDocument } = this.props

    return this.templates.find(template => template.id === currentDocument.template)
  }

  get colorSettings() {
    const { currentDocument } = this.props
    const currentTemplate = this.currentTemplate as unknown as CurrentTemplate
    const templateSettings = currentTemplate?.settings

    let selected = currentDocument ? currentDocument.color : null

    if (!selected && templateSettings?.color) selected = templateSettings?.color.default

    const options = templateSettings?.color ? templateSettings?.color.values : []

    return { selected, options, supportsCustomColor: currentTemplate?.supportsCustomColor }
  }

  render() {
    const { currentPage, totalPagesNumber, height, width } = this.state
    const {
      currentDocument,
      editorState,
      type,
      ui,
      isDownloading,
      onClose,
      isReadyToCoReg,
      isCoRegPopupOpen,
      toggleCoRegPopup,
      openCoReg,
      config,
    } = this.props
    const isDataLoaded = currentDocument && editorState.isLoaded
    const isUpdating = this.props.isSyncing || this.props.isPreviewGenerating
    const showOneDownloadButton = !!(config?.features.downloadButtonLayout === 'one_button')
    // Show the download resume option only for resume and the one button layout
    const showUpdatedMenuLayout = this.isResume && showOneDownloadButton

    const hasPreviousPage = currentPage > 1
    const hasNextPage = currentPage < totalPagesNumber

    return (
      <Container>
        <EscapeHandler onPress={onClose} />

        <Toolbar>
          <ToolbarLeft>
            <Back onClick={onClose}>
              <BackIcon />
              <BackText>{I18n.t('builder.resume_editor.back_to_editor')}</BackText>
            </Back>
          </ToolbarLeft>

          {isDataLoaded && (
            <ToolbarCenter>
              {this.isResume && (
                <Fragment>
                  <SpacingControl
                    isSupported={this?.currentTemplate?.supportsSpacing}
                    value={currentDocument.spacing}
                    isCustomizationModal
                    onChange={this.handleSpacingChange}
                  />
                  <Divider />
                </Fragment>
              )}
              <Colors onSelect={this.handleColorSelect} {...this.colorSettings} />
            </ToolbarCenter>
          )}

          <ToolbarRight>
            <Updating isVisible={isUpdating}>
              <UpdatingIcon />
              <UpdatingText>{I18n.t('builder.resume_editor.updating')}</UpdatingText>
            </Updating>
            <>
              <DownloadButton
                onClick={
                  showUpdatedMenuLayout ? this.handleActionMenuOpen : this.handleDownloadDocument
                }
                isDisabled={isDownloading}
              >
                {I18n.t(
                  `builder.resume_editor.${
                    showUpdatedMenuLayout ? 'download_document_menu.button_text' : 'download'
                  }`,
                )}
              </DownloadButton>
              <Actions>
                {!showUpdatedMenuLayout && (
                  <ActionsButton onClick={this.handleActionMenuOpen} isDisabled={isDownloading}>
                    <Icon.More />
                  </ActionsButton>
                )}

                <ActionsButtonBadge
                  isVisible={isReadyToCoReg && !this.state.isActionMenuOpen && !isCoRegPopupOpen}
                />

                {this.state.isActionMenuOpen && (
                  <DocumentActionMenu
                    position="bottom"
                    documentType={type}
                    documentId={currentDocument.id}
                    onClose={this.handleActionMenuClose}
                    onDownload={this.handleDownloadDocument}
                    onShare={this.handleShareClick}
                  />
                )}

                {isCoRegPopupOpen && (
                  <CoRegPopup
                    position={CoRegPopupPositions.bottom}
                    onClick={() => openCoReg({ resumeId: currentDocument.id, source: 'pop_up' })}
                    onClose={() => toggleCoRegPopup(false)}
                  />
                )}
              </Actions>
            </>
          </ToolbarRight>
        </Toolbar>

        <Main>
          <Sidebar>
            <SidebarInner ref={this.contentRef}>
              {config?.features?.filterOnTheTemplatesPage &&
                this.isResume &&
                !!Object.keys(this.state.templateCategories).length && (
                  <CustomSelect
                    selected={this.state.selectedCategory}
                    onSelect={value => {
                      trackInternalEvent('choose_the_templates_group', { type: value })
                      this.setState({ selectedCategory: value })
                    }}
                    options={Object.keys(this.state.templateCategories)
                      .map(category => TEMPLATE_DATA[category as `${TemplateCategories}`])
                      .filter(category => category)}
                    optionsListClassname="templates-filter"
                    buttonOnClick={() => trackInternalEvent('click_on_filter')}
                  />
                )}
              <TemplatesPreviewList
                type={this.props.type}
                selectedTemplates={
                  this.state.templateCategories[
                    this.state.selectedCategory as `${TemplateCategories}`
                  ]?.template_names || []
                }
                selectedCategory={this.state.selectedCategory}
              />
            </SidebarInner>
          </Sidebar>

          <Content>
            <ContentInner ref={this.contentRef}>
              <PreviewBox ref={ref => (this.previewBoxRef = ref)}>
                {isDataLoaded && (
                  <Preview
                    {...{ height, width, type }}
                    document={currentDocument}
                    documentId={currentDocument.id}
                    ref={this.preview}
                    onPaginationChange={this.handlePaginationStateChange}
                  />
                )}
              </PreviewBox>
            </ContentInner>

            <Pagination isVisible={!ui?.isSnackBarOpen}>
              <PaginationPrev onClick={this.handlePreviousPageClick} isDisabled={!hasPreviousPage}>
                <Icon.Chevron />
              </PaginationPrev>
              <PaginationCounter>
                {currentPage} / {totalPagesNumber}
              </PaginationCounter>
              <PaginationNext onClick={this.handleNextPageClick} isDisabled={!hasNextPage}>
                <Icon.Chevron />
              </PaginationNext>
            </Pagination>
          </Content>
        </Main>
      </Container>
    )
  }
}

const mapStateToProps = (state: Store) => ({
  ui: state.ui,
  isPreviewGenerating: state.rendering.isPreviewGenerating,
  isReadyToCoReg: coRegSelectors.isResumeReady(state),
  isCoRegPopupOpen: coRegSelectors.isPopupOpen(state),
})

const mapDispatchToProps = {
  openMergeModal: uiActions.openMergeModal,
  toggleCoRegPopup: coRegActions.togglePopup,
  openCoReg: coRegActions.open,
  openPlansPageAbandonmentModal: uiActions.openPlansPageAbandonmentModal,
}

export default compose(
  withConfig,
  withMediaQueries,
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentPreviewModal)
