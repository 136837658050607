import styled from 'styled-components'
import Typography from 'builder/styles/typography'
import { Colors } from 'builder/styles/colors'

export const Wrapper = styled.div`
  ${Typography.Tiny};
  text-align: left;

  max-width: 436px;

  color: ${Colors.Neutral50};

  & a {
    color: ${Colors.Blue50};
  }

  & a:hover {
    color: ${Colors.Blue70};
  }
`
