import Logo from 'builder/components/Logo'
import { LOGO_ELEMENT_ID } from 'builder/components/Navigation/constants'
import { CloseIcon, Wrapper } from './styles'

interface Props {
  onCloseClick: () => void
  showCloseIcon?: boolean
  children?: React.ReactNode
}

export const NavbarWithoutMenu = ({ onCloseClick, showCloseIcon = true, children }: Props) => {
  return (
    <Wrapper>
      <Logo id={LOGO_ELEMENT_ID} variant="default" />
      {children}
      {showCloseIcon && <CloseIcon onClick={onCloseClick} />}
    </Wrapper>
  )
}
