import styled from 'styled-components'
import Colors from 'builder/styles/colors'
import Media from 'builder/styles/media'

export const Container = styled.div`
  background: ${Colors.White};
  border-radius: 20px;
  overflow: hidden;

  display: grid;
  grid-template-columns: repeat(4, 1fr);

  ${Media.Phone`
    grid-template-columns: repeat(2, 1fr); 
    background: unset;
    gap: 1px;  
  `}
`
